import React from "react";
import AppRoutes from "./routes";
import { AppDataProvider } from "./providers/AppDataProvider";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { UserDataProvider } from "./providers/UserDataProvider";

function App() {
  const location = useLocation();
  const TRACKING_ID = "G-E1031853M0"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
  return (
    <ProSidebarProvider>
      <UserDataProvider>
        <AppDataProvider>
          <AppRoutes />
        </AppDataProvider>
      </UserDataProvider>
    </ProSidebarProvider>
  );
}

export default App;
