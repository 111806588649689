import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Footer, NavBar } from "../../components";
import commonStyles from "../../assets/css/common.module.css";
import styles from "./styles.module.css";
import imviImg from "../../assets/images/Imvi-project-png.png";
import bytbooImg from "../../assets/images/Bytboo-project-page.svg";
import foundiImg from "../../assets/images/Foundi-project-page.svg";
import { ArrowLeftLongIcon, ArrowRightLongIcon } from "../../components/Svg";
import { useAppData } from "../../providers/AppDataProvider";
import classNames from "classnames";
import photoImg from "../../assets/images/photoImg.svg";
import mincImg from "../../assets/images/Minc-Logo.svg";
import hetchImg from "../../assets/images/Hetch-Logo.png";
import helsinBorgImg from "../../assets/images/Helsingborg-stad-logo.svg";
import vinnovaImg from "../../assets/images/Vinnova-Logo.svg";
import ios from "../../assets/images/iosImg.svg";
import plus from "../../assets/images/plusIcon.svg";
import minus from "../../assets/images/minusIcon.svg";
import appDev from "../../assets/images/appDevIcon.svg";
import webDev from "../../assets/images/webDevIcon.svg";
import uiDesign from "../../assets/images/uiDesignIcon.svg";
// import heroVideo from "../../assets/video/heroVideo.mp4";
import heroVideo from "../../assets/video/heroVideoLottie.json";
import { Helmet } from "react-helmet";
import logoNewBlackImg from "../../assets/images/viljeNewLogoBlack.svg";
import Lottie from "react-lottie";
import { useCookieConsent } from "use-cookie-consent";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import blog1Img from "../../assets/images/Conversating-robots.png";
import blog2Img from "../../assets/images/SEO-robot-on-a-bench.jpeg";
import blog3Img from "../../assets/images/SEO-Extension-for-ChatGPT.jpeg";
import blogImg4 from "../../assets/images/Two-hands-reaching out.jpg";
import blogImg5 from "../../assets/images/Abstract representation of UX design.png";

const HomePage = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { consent, acceptAllCookies, declineAllCookies, acceptCookies } =
    useCookieConsent();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const { strings } = useAppData();
  const [aDev, setADev] = useState(true);
  const [navBarColor, setNavBarColor] = useState(false);
  const [navWhiteCol, setNavWhiteCol] = useState(true);
  const [wDev, setWDev] = useState(false);
  const [uiDes, setUiDes] = useState(false);
  const [current, setCurrent] = useState(0);
  const [currentBlog, setCurrentBlog] = useState(0);
  const [currentProject, setCurrentProject] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentBlogIndex, setCurrentBlogIndex] = useState(1);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [touchProjectStart, setTouchProjectStart] = useState(null);
  const [touchProjectEnd, setTouchProjectEnd] = useState(null);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const blogData = [
    {
      id: 1,
      image: blog1Img,
      alt: "Two robots having a conversation",
      headerText: "Designens framtid i det robotiserade internetet",
      title: "Designens-framtid-i-det-robotiserade-internetet",
      desc: "Under den senaste veckan har stora aktörer inom AI-utvecklingsvärlden meddelat att de snart kommer att integrera AI i arbetsplatsverktyg som e-post, Excel-ark, dokument osv. Med tanke på den hastighet som AI-integration sker i olika verktyg är världen där vi ber AI att skriva ett e-postmeddelande och läsa upp inkorgen inte särskilt långt borta. I en sådan värld, där mänsklig interaktion med den grafiska gränssnittet är minimal, spelar design en viktig roll. Eftersom robotar inte bryr sig om estetik, tillgänglighet och upplevelse av en digital produkt så mycket som människor gör.",
    },
    {
      id: 2,
      image: blog2Img,
      alt: "Robot sitting on a bench with a computer",
      headerText: "Förbättra din webbplats SEO med ChatGPT",
      title: "Förbättra-din-webbplats-SEO-med-ChatGPT",
      desc: "Sökmotoroptimering (SEO) är en viktig del av webbdesign och webbutveckling i dagens digitala era. Med miljontals webbplatser som söker efter användarnas uppmärksamhet kan det vara svårt och tidskrävande att locka besökare till din webbplats utan att optimera innehållet på den. Skapande och underhåll av webbplatsinnehåll kan vara en arbetsam process som kräver omfattande forskning och analys, särskilt när det gäller sökordsforskning och innehållsscouting. Utmaningen kan vara ännu mer krävande för företag som har begränsade budgetar, såsom startups. Lyckligtvis finns det en lösning på detta dilemma, och det är HELT gratis!",
    },
    {
      id: 3,
      image: blog3Img,
      alt: "SEO Extension for ChatGPT",
      headerText: "AIPRM: The Revolutionary SEO Extension for ChatGPT",
      title: "AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT",
      desc: "As businesses move towards digital marketing, search engine optimization (SEO) becomes a critical element in ensuring online success. With the vast amount of content available online, it's essential to stand out from the crowd and optimise your content for search engines. This is where AIPRM comes in - the revolutionary SEO extension for ChatGPT that provides you with SEO prompts from other users.",
    },
    {
      id: 4,
      image: blogImg4,
      alt: "Image of two hands reaching out to each other",
      headerText:
        "Building Long-Lasting Relationships: The Key to Making Authentic Sales",
      title:
        "Building-Long-Lasting-Relationships:The-Key-to-Making-Authentic-Sales",
      desc: `As the CEO of Vilje Tech, I strongly believe in building long-lasting relationships with our clients. Sales are not just about closing a deal; they are about fostering connections, understanding our clients' needs, and creating value that lasts beyond a transaction. As the great Zig Ziglar once said, "If people like you, they'll listen to you, but if they trust you, they'll do business with you."`,
    },
    {
      id: 5,
      image: blogImg5,
      alt: "User Experince Blog image",
      headerText:
        "3 Simple Changes to Improve the User Experience on Your Website",
      title: "3-Simple-Changes-to-Improve-the-User-Experience-on-Your-Website",
      desc: "The user experience (UX) of your website can make or break your online success. A great UX can lead to increased engagement, higher conversion rates, and better brand recognition, while a poor UX can result in high bounce rates, low customer satisfaction, and reduced sales. Learn how to improve the user experience in three easy steps.",
    },
  ];
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele2 = document.getElementById("home2");

    let previousScrollPosition = window.pageYOffset;

    window.onscroll = function () {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele2.getBoundingClientRect().top < 250
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("home1");
    let ele2 = document.getElementById("home2");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < 40
      ) {
        setNavBarColor(true);
        setNavWhiteCol(true);
      } else if (
        oldScrollY < window.scrollY &&
        ele2.getBoundingClientRect().top < 250
      ) {
        setNavBarColor(true);
        setNavWhiteCol(false);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
      } else if (ele1.getBoundingClientRect().top > 30) {
        setNavWhiteCol(false);
      }
      oldScrollY = window.scrollY;
    };
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: heroVideo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const ProjectsData = [
    {
      id: 1,
      project: strings.HomePage.thirdView.imvi,
      image: imviImg,
      desc: strings.HomePage.thirdView.imviDesc,
      alt: "Image of Imvi VR glasses",
    },
    {
      id: 2,
      project: strings.HomePage.thirdView.foundi,
      image: foundiImg,
      desc: strings.HomePage.thirdView.foundiDesc,
      alt: "Phone for Foundi project",
    },
    {
      id: 3,
      project: strings.HomePage.thirdView.bytboo,
      image: bytbooImg,
      desc: strings.HomePage.thirdView.bytbooDesc,
      alt: "Building representing Bytboo project",
    },
  ];
  const Data = [
    {
      id: 1,
      desc: strings.HomePage.fifthView.cardDesc,
      name: strings.HomePage.fifthView.name,
      position: strings.HomePage.fifthView.jobPosition,
    },
    {
      id: 2,
      desc: strings.HomePage.fifthView.cardDesc1,
      name: strings.HomePage.fifthView.name1,
      position: strings.HomePage.fifthView.jobPosition1,
    },
    {
      id: 3,
      desc: strings.HomePage.fifthView.cardDesc2,
      name: strings.HomePage.fifthView.name2,
      position: strings.HomePage.fifthView.jobPosition2,
    },
  ];
  useEffect(() => {
    loopFunction();
  }, [currentProject]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  function repeatContent(el, till) {
    let html = el.innerHTML;
    let counter = 0;

    while (el.offsetWidth < till && counter < 100) {
      el.innerHTML += html;
      counter += 1;
    }
  }
  const loopFunction = () => {
    let outer =
      document.querySelector("#outer") && document.querySelector("#outer");
    let content =
      outer.querySelector("#content") && outer.querySelector("#content");

    repeatContent(content, outer.offsetWidth);

    let el = outer.querySelector("#loop") && outer.querySelector("#loop");
    el.innerHTML = el.innerHTML + el.innerHTML;
  };
  const nextSlide = () => {
    setCurrentProject(
      currentProject <= 2 ? currentProject + 1 : currentProject
    );
  };
  const prevSlide = () => {
    setCurrentProject(
      currentProject >= 2 ? currentProject - 1 : currentProject
    );
  };
  const nextTestimonialSlide = () => {
    let slideIndex = currentIndex;
    if (currentIndex <= 2) {
      slideIndex = currentIndex + 1;
      setCurrentIndex(currentIndex + 1);
    }
    const ele = document.getElementById(`Data${slideIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const prevTestimonialSlide = () => {
    let slideIndex = currentIndex;
    if (currentIndex > 0) {
      slideIndex = currentIndex - 1;
      setCurrentIndex(currentIndex - 1);
    }
    const ele = document.getElementById(`Data${slideIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const nextBlogSlide = () => {
    let slideBlogIndex = currentBlogIndex;
    if (currentBlogIndex <= 4) {
      slideBlogIndex = currentBlogIndex + 1;
      setCurrentBlogIndex(currentBlogIndex + 1);
    }
    const ele = document.getElementById(`blog${slideBlogIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const prevBlogSlide = () => {
    let slideBlogIndex = currentBlogIndex;
    if (currentBlogIndex > 0) {
      slideBlogIndex = currentBlogIndex - 1;
      setCurrentBlogIndex(currentBlogIndex - 1);
    }
    const ele = document.getElementById(`blog${slideBlogIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const minSwipeDistance = 50;
  const length = Data.length;
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setCurrent(current === length - 1 ? 0 : current + 1);
    } else if (isRightSwipe) {
      setCurrent(current === 0 ? length - 1 : current - 1);
    }
  };
  const onTouchBlogStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchBlogMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  const blogLength = blogData.length;
  const onTouchBlogEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setCurrentBlog(currentBlog === blogLength - 1 ? 0 : currentBlog + 1);
    } else if (isRightSwipe) {
      setCurrentBlog(currentBlog === 0 ? blogLength - 1 : currentBlog - 1);
    }
  };
  const onTouchProjectsStart = (e) => {
    setTouchProjectEnd(null);
    setTouchProjectStart(e.targetTouches[0].clientX);
  };

  const onTouchProjectsMove = (e) =>
    setTouchProjectEnd(e.targetTouches[0].clientX);

  const onTouchProjectsEnd = () => {
    if (!touchProjectStart || !touchProjectEnd) return;
    const distance = touchProjectStart - touchProjectEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setCurrentProject(
        currentProject <= 2 ? currentProject + 1 : currentProject
      );
    } else if (isRightSwipe) {
      setCurrentProject(
        currentProject >= 2 ? currentProject - 1 : currentProject
      );
    }
  };
  const serviceSection = () => {
    return (
      <div className={styles.servicesSectionStyle}>
        <div className={styles.servicesTopContainerStyle}>
          <h3 className={styles.ourServicetextStyle}>
            {strings.HomePage.fourthView.ourServices}
          </h3>
          <p className={styles.ourServiceDescTextStyle}>
            {strings.HomePage.fourthView.itemDesc}
          </p>
        </div>
        <div className={styles.itemContainerStyle}>
          <div
            className={
              aDev ? styles.itemWrapper2Style : styles.itemWrapperStyle
            }
            onClick={() => {
              setADev(!aDev);
              setWDev(false);
              setUiDes(false);
            }}
          >
            <div
              className={aDev ? styles.indexNumStyle : styles.indexNumStyle2}
            >
              <p className={aDev ? styles.numText2Style : styles.numTextStyle}>
                01
              </p>
            </div>
            <div
              className={
                aDev
                  ? styles.itemMiddleContentStyle2
                  : styles.itemMiddleContentStyle
              }
            >
              <div
                className={
                  aDev
                    ? styles.capsuleDevIcon2Style
                    : styles.capsuleDevIconStyle
                }
              >
                <img src={appDev} className={styles.imgStyle} />
              </div>

              <div className={styles.itenContentWrapperStyle}>
                <h2
                  className={
                    aDev ? styles.itemText2Style : styles.itemTextStyle
                  }
                >
                  {strings.HomePage.fourthView.appDevelopment}
                </h2>

                <p
                  className={
                    aDev ? styles.itemDescTextStyle : styles.itemDescText2Style
                  }
                >
                  {strings.HomePage.fourthView.appDevelopmentDesc}
                  <span
                    className={styles.itemDescTextLinkStyle}
                    onClick={() => navigate("/services")}
                  >
                    {strings.HomePage.fourthView.clickHere}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.capsuleImgWrapperStyle}>
              <div className={styles.imgClickStyle}>
                <img src={aDev ? minus : plus} className={styles.imgStyle} />
              </div>
            </div>
          </div>
          <div
            className={
              wDev ? styles.itemWrapper2Style : styles.itemWrapperStyle
            }
            onClick={() => {
              setADev(false);
              setWDev(!wDev);
              setUiDes(false);
            }}
          >
            <div
              className={wDev ? styles.indexNumStyle : styles.indexNumStyle2}
            >
              <p className={wDev ? styles.numText2Style : styles.numTextStyle}>
                02
              </p>
            </div>
            <div
              className={
                wDev
                  ? styles.itemMiddleContentStyle2
                  : styles.itemMiddleContentStyle
              }
            >
              <div
                className={
                  wDev
                    ? styles.capsuleDevIcon2Style
                    : styles.capsuleDevIconStyle
                }
              >
                <img src={webDev} className={styles.imgStyle} />
              </div>

              <div className={styles.itenContentWrapperStyle}>
                <h2
                  className={
                    wDev ? styles.itemText2Style : styles.itemTextStyle
                  }
                >
                  {strings.HomePage.fourthView.webDevelopment}
                </h2>

                <p
                  className={
                    wDev ? styles.itemDescTextStyle : styles.itemDescText2Style
                  }
                >
                  {strings.HomePage.fourthView.webDevelopmentDesc}
                  <span
                    className={styles.itemDescTextLinkStyle}
                    onClick={() => navigate("/services")}
                  >
                    {strings.HomePage.fourthView.clickHere}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.capsuleImgWrapperStyle}>
              <div className={styles.imgClickStyle}>
                <img src={wDev ? minus : plus} className={styles.imgStyle} />
              </div>
            </div>
          </div>
          <div
            className={
              uiDes ? styles.itemWrapper2Style : styles.itemWrapperStyle
            }
            onClick={() => {
              setADev(false);
              setWDev(false);
              setUiDes(!uiDes);
            }}
          >
            <div
              className={uiDes ? styles.indexNumStyle : styles.indexNumStyle2}
            >
              <p className={uiDes ? styles.numText2Style : styles.numTextStyle}>
                03
              </p>
            </div>
            <div
              className={
                uiDes
                  ? styles.itemMiddleContentStyle2
                  : styles.itemMiddleContentStyle
              }
            >
              <div
                className={
                  uiDes
                    ? styles.capsuleDevIcon2Style
                    : styles.capsuleDevIconStyle
                }
              >
                <img src={uiDesign} className={styles.imgStyle} />
              </div>

              <div className={styles.itenContentWrapperStyle}>
                <h2
                  className={
                    uiDes ? styles.itemText2Style : styles.itemTextStyle
                  }
                >
                  {strings.HomePage.fourthView.uxUidesign}
                </h2>

                <p
                  className={
                    uiDes ? styles.itemDescTextStyle : styles.itemDescText2Style
                  }
                >
                  {strings.HomePage.fourthView.uxUidesignDesc}
                  <span
                    className={styles.itemDescTextLinkStyle}
                    onClick={() => navigate("/services")}
                  >
                    {strings.HomePage.fourthView.clickHere}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.capsuleImgWrapperStyle}>
              <div className={styles.imgClickStyle}>
                <img src={uiDes ? minus : plus} className={styles.imgStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const partnersSection = () => {
    return (
      <div className={styles.partnerSectionMainContainerStyle}>
        <div className={styles.partnersContainerStyle}>
          <div className={styles.testimonialContainerStyle}>
            <div className={styles.testimonialDescWrapperStyle}>
              <div>
                <h2 className={styles.testimonialTextStyle}>
                  {strings.HomePage.fifthView.testiMonial}
                </h2>
                <p className={styles.testimonialDescTextStyle}>
                  {strings.HomePage.fifthView.testiMonialDesc}
                </p>
              </div>
              <div className={styles.paSliderWrapperStyle}>
                <ArrowLeftLongIcon
                  color={
                    currentIndex <= 1 ? "rgba(0, 0, 0, 0.2)" : "rgb(0, 0, 0)"
                  }
                  onClick={() => prevTestimonialSlide()}
                />
                <ArrowRightLongIcon
                  color={
                    currentIndex >= 3 ? "rgba(0, 0, 0, 0.2)" : "rgb(0, 0, 0)"
                  }
                  onClick={() => nextTestimonialSlide()}
                />
              </div>
            </div>
            <div className={styles.cardsFlowStyle}>
              {Data.map((item, index) => {
                return (
                  <div
                    key={"Data" + item.id}
                    id={"Data" + item.id}
                    className={styles.testiMonialCardStyle}
                  >
                    <p className={styles.cardDescTextStyle}>{item.desc}</p>
                    <div className={styles.cardClientDetailsStyle}>
                      {/* <div className={styles.clientImgWrapperStyle}>
                        <img src={photoImg} className={styles.imgStyle} />
                      </div> */}
                      <div>
                        <p className={styles.cardClientNametextStyle}>
                          {item.name}
                        </p>
                        <p className={styles.cardClientPositiontextStyle}>
                          {item.position}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.cardsFlowStyle2}>
              {Data.map((item, index) => {
                return (
                  <React.Fragment key={"plus" + index}>
                    {current === index && (
                      <div
                        className={styles.testiMonialCardStyle}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                      >
                        <p className={styles.cardDescTextStyle}>{item.desc}</p>
                        <div className={styles.cardClientDetailsStyle}>
                          {/* <div className={styles.clientImgWrapperStyle}>
                            <img src={photoImg} className={styles.imgStyle} />
                          </div> */}
                          <div>
                            <p className={styles.cardClientNametextStyle}>
                              {item.name}
                            </p>
                            <p className={styles.cardClientPositiontextStyle}>
                              {item.position}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.dotsContainerStyles}>
              {Data.map((item, index) => {
                return (
                  <div
                    key={"item" + index}
                    className={
                      current === index
                        ? styles.dotsSelectedStyle
                        : styles.dotsStyle
                    }
                    onClick={() => setCurrent(index)}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const trustedBrandsSection = () => {
    return (
      <div className={styles.trustedSectionMainContainerStyle}>
        <div className={styles.trustedContainerStyle}>
          <div className={styles.partnersWithContainerStyle}>
            <p className={styles.parnterDescTextStyle}>
              {strings.HomePage.fifthView.header}
            </p>

            <div className={styles.partnerImgContainerStyle}>
              <div className={styles.partnerImgWrapperStyle}>
                <div className={styles.partnerebayImgWrapperStyle}>
                  <img
                    src={mincImg}
                    alt="Logo of Minc"
                    className={styles.imgStyle2}
                  />
                </div>
              </div>
              <div className={styles.partnerImgWrapperStyle}>
                <div className={styles.partnerawsImgWrapperStyle}>
                  <img
                    src={vinnovaImg}
                    alt="Logo of Vinnova"
                    className={styles.imgStyle2}
                  />
                </div>
              </div>
              <div className={styles.partnerImgWrapperStyle}>
                <div className={styles.partnergoogleImgWrapperStyle}>
                  <img
                    src={helsinBorgImg}
                    alt="Logo of the city of Helsingborg"
                    className={styles.imgStyle2}
                  />
                </div>
              </div>
              <div className={styles.partnerImgWrapperStyle}>
                <div className={styles.partnertwitchImgWrapperStyle}>
                  <img
                    src={hetchImg}
                    alt="Logo of Hetch"
                    className={styles.imgStyle2}
                  />
                </div>
              </div>
              {/* <div className={styles.partnerImgWrapperStyle}>
          <div className={styles.partneriosImgWrapperStyle}>
            <img src={ios} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.partnerImgWrapperStyle}>
          <div className={styles.partneriosImgWrapperStyle}>
            <img src={ios} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.partnerImgWrapperStyle}>
          <div className={styles.partneriosImgWrapperStyle}>
            <img src={ios} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.partnerImgWrapperStyle}>
          <div className={styles.partneriosImgWrapperStyle}>
            <img src={ios} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.partnerImgWrapperStyle}>
          <div className={styles.partneriosImgWrapperStyle}>
            <img src={ios} className={styles.imgStyle} />
          </div>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderCookiePopUp = () => {
    return (
      <div className={styles.cookiePopUpStyle}>
        <div className={styles.cookiePopUpInsideStyle}>
          <p className={styles.cookieTextStyle}>
            By continuing to browse this site, you agree to the use of cookies
            to identify your session and to remember your login after you close
            the browser.&nbsp;
            <span
              className={styles.cookieGreenTextStyle}
              onClick={() => navigate("/privacypolicy")}
            >
              Learn more
            </span>
            &nbsp;or&nbsp;
            <span
              className={styles.cookieGreenTextStyle}
              onClick={() => {
                acceptAllCookies();
              }}
            >
              agree and close.
            </span>
          </p>
        </div>
      </div>
    );
  };
  const renderBlogPosts = () => {
    return (
      <div className={styles.blogSectionMainContainerStyle}>
        <div className={styles.partnersContainerStyle}>
          <div className={styles.testimonialContainerStyle}>
            <div className={styles.testimonialDescWrapperStyle}>
              <div>
                <h2 className={styles.testimonialTextStyle}>
                  {strings.HomePage.blog.title}
                </h2>
                <p className={styles.testimonialDescTextStyle}>
                  {strings.HomePage.blog.description}
                </p>
              </div>
              <div className={styles.paSliderWrapperStyle}>
                <ArrowLeftLongIcon
                  color={
                    currentBlogIndex <= 1
                      ? "rgba(0, 0, 0, 0.2)"
                      : "rgb(0, 0, 0)"
                  }
                  onClick={() => {
                    return currentBlogIndex <= 1 ? null : prevBlogSlide();
                  }}
                />
                <ArrowRightLongIcon
                  color={
                    currentBlogIndex >= 5
                      ? "rgba(0, 0, 0, 0.2)"
                      : "rgb(0, 0, 0)"
                  }
                  onClick={() => nextBlogSlide()}
                />
              </div>
            </div>
            <div className={styles.cardsFlowStyle}>
              {blogData.map((item, index) => {
                return (
                  <div
                    className={styles.blogListItemWrapperStyle}
                    key={"blog" + item.id}
                    id={"blog" + item.id}
                    onClick={() => navigate(`/blogposts/${item.title}`)}
                  >
                    <div className={styles.blogImgListWrapperStyle}>
                      <img
                        src={item.image}
                        alt={item.alt}
                        className={styles.imgStyle}
                      />
                    </div>
                    <div className={styles.blogListItemContentWrapperStyle}>
                      <p className={styles.blogListItemHeaderTextStyle}>
                        {item.headerText}
                      </p>
                      <p className={styles.blogListItemContentTextStyle}>
                        {item.desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.cardsFlowStyle2}>
              {blogData.map((item, index) => {
                return (
                  <React.Fragment key={"plus" + index}>
                    {currentBlog === index && (
                      <div
                        className={styles.blogListItemWrapperStyle}
                        key={"blog" + item.id}
                        onClick={() => navigate(`/blogposts/${item.title}`)}
                        onTouchStart={onTouchBlogStart}
                        onTouchMove={onTouchBlogMove}
                        onTouchEnd={onTouchBlogEnd}
                      >
                        <div className={styles.blogImgListWrapperStyle}>
                          <img
                            src={item.image}
                            alt={item.alt}
                            className={styles.imgStyle}
                          />
                        </div>
                        <div className={styles.blogListItemContentWrapperStyle}>
                          <p className={styles.blogListItemHeaderTextStyle}>
                            {item.headerText}
                          </p>
                          <p className={styles.blogListItemContentTextStyle}>
                            {item.desc}
                          </p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.dotsContainerStyles}>
              {blogData.map((item, index) => {
                return (
                  <div
                    key={"item" + index}
                    className={
                      currentBlog === index
                        ? styles.dotsSelectedStyle
                        : styles.dotsStyle
                    }
                    onClick={() => setCurrentBlog(index)}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      // <div className={styles.bloglistMainContainerStyle}>
      //   <div className={styles.blogHeaderContainerStyle}>
      //     <h2 className={styles.testimonialTextStyle}>
      //       {strings.HomePage.blog.title}
      //     </h2>
      //     <p className={styles.testimonialDescTextStyle}>
      //       {strings.HomePage.blog.description}
      //     </p>
      //   </div>
      //   <div className={styles.blogListContainerStyle}>
      //     {blogData.map((item, index) => {
      //       return (
      //         <div
      //           className={styles.blogListItemWrapperStyle}
      //           key={"blog" + item.id}
      //           onClick={() =>
      //             navigate("/blogpost", {
      //               state: {
      //                 data: item,
      //               },
      //             })
      //           }
      //         >
      //           <div className={styles.blogImgListWrapperStyle}>
      //             <img src={item.image} className={styles.imgStyle} />
      //           </div>
      //           <div className={styles.blogListItemContentWrapperStyle}>
      //             <p className={styles.blogListItemHeaderTextStyle}>
      //               {item.headerText}
      //             </p>
      //             <p className={styles.blogListItemContentTextStyle}>
      //               {item.desc}
      //             </p>
      //           </div>
      //         </div>
      //       );
      //     })}
      //   </div>
      // </div>
    );
  };
  return (
    <div className={styles.containerStyle}>
      <Helmet>
        <meta name="description" content={strings.HomePage.seo.description} />
        <meta name="keywords" content={strings.HomePage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/" />
        <title>{strings.HomePage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Corporation",
            name: "ViljeTech",
            description: strings.HomePage.firstView.headerDesc,
            keywords:
              "Web development, Mobile app development, User experience, User interface, Design.",
            logo: [
              "https://viljetech.com/static/media/viljeNewLogoBlack.8252b5b1b505bf0bf9517de813c92e0c.svg",
            ],
            url: "https://viljetech.com/",
            foundingDate: new Date("2023-01-04T09:25:01.340Z").toISOString(),
            founder: {
              "@type": "Person",
              name: "Bharath Panayala",
            },
          })}
        </script>
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        contactCol={navWhiteCol}
        navColor={navBarColor}
        color={false}
        backgroundColor={width > 980 ? null : styles.backGroundStyle}
      />
      <div className={styles.topSectionStyle}>
        <div className={styles.topSectionInnerStyle}>
          <div className={styles.videoContainerStyle}>
            <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
          </div>
          <div className={styles.titleWrapperStyle}>
            <h1 id="home2" className={styles.titleStyle}>
              {strings.HomePage.firstView.headerTitle}
            </h1>
          </div>
          <div className={styles.descriptionWrapperStyle}>
            <p className={styles.descriptionStyle}>
              {strings.HomePage.firstView.headerDesc}
            </p>
            <NavLink
              to={"/contact"}
              className={classNames(
                commonStyles.anchorStyle,
                styles.anchorStyle
              )}
            >
              {strings.HomePage.firstView.letsTalk}
            </NavLink>
            {/* <a
              href="#"
              className={classNames(
                commonStyles.anchorStyle,
                styles.anchorStyle
              )}
            >
              {strings.HomePage.firstView.letsTalk}
            </a> */}
          </div>
        </div>
      </div>
      <div id="home1" className={styles.aboutSectionStyle}>
        <div className={styles.aboutSectionInnerStyle}>
          <p className={styles.aboutTitleStyle}>
            {strings.HomePage.secondView.mainDescription}
          </p>
          <div className={styles.aboutSectionBottomStyle}>
            <div className={styles.aboutListStyle}>
              <div className={styles.aboutListItemStyle}>
                <p className={styles.aboutListItemNumberStyle}>01</p>
                <h3 className={styles.aboutListItemTitleStyle}>
                  {strings.HomePage.secondView.design}
                </h3>
              </div>
              <div className={styles.aboutListItemStyle}>
                <p className={styles.aboutListItemNumberStyle}>02</p>
                <h3 className={styles.aboutListItemTitleStyle}>
                  {strings.HomePage.secondView.develop}
                </h3>
              </div>
              <div className={styles.aboutListItemStyle}>
                <p className={styles.aboutListItemNumberStyle}>03</p>
                <h3 className={styles.aboutListItemTitleStyle}>
                  {strings.HomePage.secondView.deliver}
                </h3>
              </div>
            </div>
            <div className={styles.aboutRightStyle}>
              <p className={styles.aboutRightDescriptionStyle}>
                {strings.HomePage.secondView.leftDesc}
              </p>
              <NavLink
                to={"/about"}
                className={classNames(
                  commonStyles.anchorStyle,
                  styles.aboutRightAnchorStyle
                )}
              >
                {strings.HomePage.secondView.moreAbout}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.projectsSectionContainerStyle}>
        {ProjectsData.map((item, index) => {
          return (
            <div key={"projects" + index}>
              {currentProject === index + 1 && (
                <div
                  className={styles.psSectionStyle}
                  onTouchStart={onTouchProjectsStart}
                  onTouchMove={onTouchProjectsMove}
                  onTouchEnd={onTouchProjectsEnd}
                >
                  <div id="outer" className={styles.outer}>
                    <div>
                      <div id="loop" className={styles.loop}>
                        <div id="content" className={styles.psScrollTitleStyle}>
                          <span className={styles.psScrollTitleSpanStyle}>
                            {item.project}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.psContentWrapperStyle}>
                    <div className={styles.psImgWrapperStyle}>
                      <img
                        src={item.image}
                        className={styles.psImgStyle}
                        alt={item.alt}
                      />
                    </div>
                    <div className={styles.psBottomWrapperStyle}>
                      <div className={styles.psDescWrapperStyle}>
                        <div className={styles.psDescInnerWrapperStyle}>
                          <p className={styles.psDescStyle}>{item.desc}</p>
                          <div>
                            <div className={styles.psSliderWrapperStyle}>
                              <ArrowLeftLongIcon
                                color={
                                  currentProject <= 1
                                    ? "rgba(0, 0, 0, 0.2)"
                                    : "rgb(0, 0, 0)"
                                }
                                onClick={() => prevSlide()}
                              />
                              <ArrowRightLongIcon
                                color={
                                  currentProject >= 3
                                    ? "rgba(0, 0, 0, 0.2)"
                                    : "rgb(0, 0, 0)"
                                }
                                onClick={() => nextSlide()}
                              />
                            </div>
                            <div className={styles.dotsContainerStyles}>
                              {ProjectsData.map((item, index) => {
                                return (
                                  <div
                                    key={"projectsData" + index}
                                    onClick={() => setCurrentProject(index + 1)}
                                    className={
                                      currentProject === index + 1
                                        ? styles.dotsSelectedStyle
                                        : styles.boxSliderStyle
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.psRightStyle}>
                        <NavLink
                          to={"/projects"}
                          className={classNames(
                            commonStyles.anchorStyle,
                            styles.psAnchorStyle
                          )}
                        >
                          {strings.HomePage.thirdView.allProjects}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {serviceSection()}

      {partnersSection()}

      {trustedBrandsSection()}
      {renderBlogPosts()}
      <Footer />

      {!consent.firstParty && renderCookiePopUp()}
    </div>
  );
};

export default HomePage;
