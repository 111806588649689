import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classnames from "classnames";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import styles from "./styles.module.css";
import logoNewWhiteImg from "../../assets/images/viljeNewLogoWhite.svg";
import logoNewBlackImg from "../../assets/images/viljeNewLogoBlack.svg";
import globeImg from "../../assets/images/globe.svg";
import globeBlackImg from "../../assets/images/globeblack.svg";
import langDownArrImg from "../../assets/images/languageArrowDown.svg";
import langUpArrImg from "../../assets/images/langUpArrImg.svg";
import hamBurgerImg from "../../assets/images/hamBurger.svg";
import { useAppData } from "../../providers/AppDataProvider";
import MenuPopover from "../Popover";
import blueCheckIcon from "../../assets/images/blueCheckIcon.svg";
import lightCheckIcon from "../../assets/images/lightCheckIcon.svg";
import arrowBlackDown from "../../assets/images/arrowDownBlack.svg";
import hamBurgerBlackImg from "../../assets/images/hamburgerBlack.svg";

const NavBar = (props) => {
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { scrollX, scrollY } = useWindowScrollPositions();
  const navigate = useNavigate();
  const location = useLocation();
  const { strings, setLanguage, currentLanguage } = useAppData();
  const divRefProfile = React.useRef();
  const divRefProfile2 = React.useRef();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // useEffect(() => {
  //   let ele1 = document.getElementById("home");
  //   let ele2 = document.getElementById("home");
  //   // console.log(ele1.getBoundingClientRect().top);
  //   if (ele1.getBoundingClientRect().top < 0) {
  //     setNavColor(true);
  //   }

  //   // let ele2 = document.getElementById("demo2");
  //   // let ele3 = document.getElementById("demo3");

  //   // setDemo2H(ele2.getBoundingClientRect().top);
  //   // if (ele3.getBoundingClientRect().top < height / 2) {
  //   //   setDemo2H();
  //   // }
  //   // setDemo3H(ele3.getBoundingClientRect().top);
  // }, [scrollY]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const handleClick2 = (event) => {
    setAnchorEl(divRefProfile2.current);
  };
  const selectSweLanguage = () => {
    setLanguage("SE");
  };
  const selectEngLanguage = () => {
    setLanguage("EN");
  };
  const selectDutchLanguage = () => {
    setLanguage("GE");
  };
  return (
    <div className={styles.backdropStyle}>
      <div
        className={
          props.color
            ? modal
              ? styles.modalContainerStyle2
              : styles.modalOpenContainerStyle2
            : modal
            ? styles.modalContainerStyle
            : styles.modalOpenContainerStyle
        }
      >
        <div className={styles.modalCloseContainerStyle}>
          <a
            className={
              props.color
                ? styles.modalCloseTextStyle2
                : styles.modalCloseTextStyle
            }
            onClick={() => setModal(false)}
          >
            {strings.common.close}
          </a>
        </div>
        <div className={styles.navBarOptionContainerStyle}>
          <a
            onClick={() => {
              navigate("/");
              setModal(false);
            }}
            className={
              location.pathname === "/" || location.pathname === "/"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.Home}
          </a>
          <a
            onClick={() => {
              navigate("/about");
              setModal(false);
            }}
            className={
              location.pathname === "/about"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.about}
          </a>
          <a
            onClick={() => {
              navigate("/projects");
              setModal(false);
            }}
            className={
              location.pathname === "/projects"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.projects}
          </a>
          <a
            onClick={() => {
              navigate("/services");
              setModal(false);
            }}
            className={
              location.pathname === "/services"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.services}
          </a>
          <a
            onClick={() => {
              navigate("/contact");
              setModal(false);
            }}
            className={
              location.pathname === "/contact"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.contacts}
          </a>
          <a
            onClick={() => {
              navigate("/blogposts");
              setModal(false);
            }}
            className={
              location.pathname === "/blogposts"
                ? props.color
                  ? styles.navBarOptionTextSelectedStyle2
                  : styles.navBarOptionTextSelectedStyle
                : styles.navBarOptionTextStyle
            }
          >
            {strings.common.blogtitle}
          </a>
        </div>
      </div>
      {props.navBarView && (
        <div
          className={
            props.color
              ? modal
                ? classnames(styles.navCloseStyle2, props.backgroundColor)
                : classnames(
                    props.navColor
                      ? styles.navBarColorStyle2
                      : styles.navBarStyle2,
                    props.backgroundColor
                  )
              : modal
              ? classnames(styles.navCloseStyle, props.backgroundColor)
              : classnames(
                  props.navColor ? styles.navBarColorStyle : styles.navBarStyle,
                  props.backgroundColor
                )
          }
        >
          <div className={styles.navBarInnerStyle}>
            <div
              className={styles.leftWrapperStyle}
              onClick={() => navigate("/")}
            >
              <img
                src={
                  props.color
                    ? props.navAbout
                      ? logoNewBlackImg
                      : logoNewWhiteImg
                    : props.navColor
                    ? props.contactCol
                      ? logoNewBlackImg
                      : logoNewWhiteImg
                    : logoNewWhiteImg
                }
                className={styles.logoStyle}
              />
            </div>
            {!props.navColor && (
              <div
                ref={divRefProfile}
                className={styles.middleWrapperStyle}
                onClick={() => handleClick()}
              >
                <div className={styles.middleIconWrapperStyle}>
                  <img
                    src={props.color ? globeBlackImg : globeImg}
                    className={styles.iconStyle}
                  />
                </div>

                <p
                  className={
                    props.color
                      ? styles.languageEngTextColor2
                      : styles.languageEngTextColor
                  }
                >
                  {currentLanguage === "EN"
                    ? strings.common.english
                    : currentLanguage === "SE"
                    ? strings.common.sweden
                    : strings.common.dutch}
                </p>

                <div className={styles.arrIconWrapperStyle}>
                  {open ? (
                    <img
                      src={props.color ? arrowBlackDown : langUpArrImg}
                      className={
                        props.color ? styles.iconStyle2 : styles.iconStyle
                      }
                    />
                  ) : (
                    <img
                      src={props.color ? arrowBlackDown : langDownArrImg}
                      className={styles.iconStyle}
                    />
                  )}
                </div>
              </div>
            )}
            <div className={styles.rightMenuContainerStyle}>
              <div
                className={
                  props.navColor
                    ? styles.rightIconWrapperStyle2
                    : styles.rightIconWrapperStyle
                }
                ref={divRefProfile2}
                onClick={() => handleClick2()}
              >
                <img
                  src={
                    props.color
                      ? props.navAbout
                        ? globeBlackImg
                        : globeImg
                      : props.navColor
                      ? props.contactCol
                        ? globeBlackImg
                        : globeImg
                      : globeImg
                  }
                  className={styles.iconStyle}
                />
              </div>

              <div
                className={
                  props.color
                    ? styles.rightWrapper2Style
                    : styles.rightWrapperStyle
                }
                onClick={() => setModal(true)}
              >
                <img
                  src={
                    props.color
                      ? props.navAbout
                        ? hamBurgerBlackImg
                        : hamBurgerImg
                      : props.navColor
                      ? props.contactCol
                        ? hamBurgerBlackImg
                        : hamBurgerImg
                      : hamBurgerImg
                  }
                  className={styles.rightIconStyle}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <MenuPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className={styles.popOverStyle}>
          <div
            className={styles.langTextWrapperStyle}
            onClick={() => selectEngLanguage()}
          >
            <p
              className={
                currentLanguage === "EN"
                  ? styles.langTextStyle2
                  : styles.langTextStyle
              }
            >
              {strings.common.english}
            </p>
            <div className={styles.checkIconWrapperStyle}>
              <img
                src={currentLanguage === "EN" ? blueCheckIcon : lightCheckIcon}
                className={styles.iconStyle}
              />
            </div>
          </div>
          <div
            className={styles.langTextWrapperStyle}
            onClick={() => selectSweLanguage()}
          >
            <p
              className={
                currentLanguage === "SE"
                  ? styles.langTextStyle2
                  : styles.langTextStyle
              }
            >
              {strings.common.sweden}
            </p>
            <div className={styles.checkIconWrapperStyle}>
              <img
                src={currentLanguage === "SE" ? blueCheckIcon : lightCheckIcon}
                className={styles.iconStyle}
              />
            </div>
          </div>
          {/* <div
            className={styles.langTextWrapperStyle}
            onClick={() => selectDutchLanguage()}
          >
            <p
              className={
                currentLanguage === "GE"
                  ? styles.langTextStyle2
                  : styles.langTextStyle
              }
            >
              {strings.common.dutch}
            </p>
            <div className={styles.checkIconWrapperStyle}>
              <img
                src={currentLanguage === "GE" ? blueCheckIcon : lightCheckIcon}
                className={styles.iconStyle}
              />
            </div>
          </div> */}
        </div>
      </MenuPopover>
    </div>
  );
};

export default NavBar;
