import React, { useState, useEffect, useRef } from "react";
import { NavBar } from "../../components/index";
import styles from "./styles.module.css";
import contactImg from "../../assets/images/contactImg.svg";
import contactImg1 from "../../assets/images/Ida-Alfonsi.jpg";
import contactImg2 from "../../assets/images/Bharath-Panyala.jpg";
import mailImg from "../../assets/images/Mail.svg";
import callImg from "../../assets/images/call.svg";
import { FaLinkedinIn } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { useAppData } from "../../providers/AppDataProvider";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ContactPage = () => {
  const { strings } = useAppData();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const form = useRef();
  const contactData = [
    {
      id: 1,
      image: contactImg2,
      name: strings.ContactPage.name1,
      position: strings.ContactPage.jobPosition1,
      email: strings.ContactPage.email1,
      phone: strings.ContactPage.phone1,
      alt: "Photograph of Bharath Panyala",
    },
    {
      id: 2,
      image: contactImg1,
      name: strings.ContactPage.name,
      position: strings.ContactPage.jobPosition,
      email: strings.ContactPage.email,
      phone: strings.ContactPage.phone,
      alt: "Photograph of Ida Alfonsi",
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele1 = document.getElementById("contact");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < -140
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("contact");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < -140
      ) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4rzxitg",
        "template_qnzyogg",
        form.current,
        "BCL0VqeQ06EXqocoa"
      )
      .then(
        (result) => {
          e.target.reset();
        },
        (error) => {
          console.log("error", error);
        }
      );
  };
  const renderContactImageView = () => {
    return (
      <div>
        <h1 className={styles.contactPageHeaderStyle}>
          {strings.ContactPage.title}
        </h1>

        <p className={styles.contactPageDescStyle}>
          {strings.ContactPage.desc}
        </p>
        <div className={styles.contactImgOutsideContainer}>
          {contactData.map((item, index) => {
            return (
              <div key={index} className={styles.contactImgInsideContainer}>
                <div className={styles.contactImgOfMemberStyle}>
                  <img
                    src={item.image}
                    alt={item.alt}
                    className={styles.imgContactStyle}
                  />
                </div>
                <p className={styles.contactNameStyle}>{item.name}</p>
                <p className={styles.contactPositionStyle}>{item.position}</p>
                <div className={styles.mailAndCallContainerStyle}>
                  <div className={styles.mailAndCallInsideContainerStyle}>
                    <div className={styles.imgContactContainerStyle}>
                      <img src={mailImg} className={styles.imgContactStyle} />
                    </div>
                    <p className={styles.contactDetailsText}>{item.email}</p>
                  </div>
                  {item.phone && (
                    <div className={styles.mailAndCallInsideContainerStyle}>
                      <div className={styles.imgContactContainerStyle}>
                        <img src={callImg} className={styles.imgContactStyle} />
                      </div>

                      <p className={styles.contactDetailsText}>{item.phone}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderDividerView = () => {
    return (
      <div className={styles.dividerOuterWrapper}>
        <div className={styles.dividerWrapperStyle}>
          <div className={styles.dividerLineStyle}></div>
          <p className={styles.dividerLineTextStyle}>
            {strings.ContactPage.dividerText}
          </p>
          <div className={styles.dividerLineStyle}></div>
        </div>
      </div>
    );
  };
  const renderContactInputView = () => {
    return (
      <div className={styles.inputTextContainerStyle}>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type={"text"}
            name="name"
            placeholder={strings.ContactPage.yourName}
            className={styles.inputTextViewStyle}
          />
          <input
            type={"email"}
            name="email"
            placeholder={strings.ContactPage.yourEmail}
            className={styles.inputTextViewStyle}
          />
          <textarea
            name="message"
            placeholder={strings.ContactPage.tellus}
            className={styles.inputTextAreaViewStyle}
          />
          <button type="submit" className={styles.submitButtonStyle}>
            {strings.ContactPage.sendBtn}
          </button>
        </form>
      </div>
    );
  };

  const rendderFooterContainer = () => {
    return (
      <div className={styles.footerContainerStyle}>
        <div className={styles.addressContainerStyle}>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.address}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                window.open("https://goo.gl/maps/p97E4hM9Yicx4WNMA", "__blank")
              }
            >
              {strings.FooterComponent.openInMaps}
            </a>
          </p>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.email}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                (window.location.href = `mailto:${strings.FooterComponent.emailAddress}`)
              }
            >
              {strings.FooterComponent.emailAddress}
            </a>
          </p>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.call}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                (window.location.href = `tel:${strings.FooterComponent.phoneNo}`)
              }
            >
              {strings.FooterComponent.phoneNo}
            </a>
          </p>
        </div>
        <div className={styles.socialIconContainerStyle}>
          <div className={styles.iconWrapperStyle}>
            <FaLinkedinIn
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/vilje-tech/",
                  "__blank"
                )
              }
              color={"#ffffff"}
              className={styles.iconsStyle}
            />
          </div>
          <div className={styles.iconWrapperStyle}>
            <FiInstagram
              onClick={() =>
                window.open("https://www.instagram.com/viljetech/", "__blank")
              }
              color={"#ffffff"}
              className={styles.iconsStyle}
            />
          </div>
          {/* <div className={styles.iconWrapperStyle}>
            <BsTwitter color={"#ffffff"} className={styles.iconsStyle} />
          </div>
          <div className={styles.iconWrapperStyle}>
            <FaFacebookF color={"#ffffff"} className={styles.iconsStyle} />
          </div> */}
        </div>
        <p className={styles.footerCopyrightTextStyle}>
          {strings.ContactPage.copyright}
        </p>
      </div>
    );
  };

  const renderContactView = () => {
    return (
      <div className={styles.contactContainerStyle}>
        {renderContactImageView()}
        {renderDividerView()}
        {renderContactInputView()}
      </div>
    );
  };

  return (
    <div id="contact">
      <Helmet>
        <meta
          name="description"
          content={strings.ContactPage.seo.description}
        />
        <meta name="keywords" content={strings.ContactPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/contact" />
        <title>{strings.ContactPage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <div className={styles.mainContactContainer}>
        <NavBar
          navBarView={navBarDisplay}
          navColor={navBarColor}
          backgroundColor={width > 980 ? null : styles.backGroundStyle}
        />
        <div className={styles.contactMainInsideContainer}>
          {renderContactView()}
        </div>
      </div>
      {rendderFooterContainer()}
    </div>
  );
};

export default ContactPage;
