import React from "react";
import Popover from "@mui/material/Popover";

const MenuPopover = ({ children, open, onClose, id, anchorEl }) => {
  return (
    <Popover
      id={id}
      open={Boolean(open)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 50,
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      elevation={0}
    >
      {children}
    </Popover>
  );
};

export default MenuPopover;
