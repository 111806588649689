import React from "react";
import styles from "./styles.module.css";

export const ArrowLeftLongIcon = (props) => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={styles.svgArrowStyle}
    >
      <path
        d="M3 12L35 12"
        stroke={props.color || "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06689 3.96738L1.00023 11.9994L9.06689 20.0327"
        stroke={props.color || "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowRightLongIcon = (props) => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={styles.svgArrowStyle}
    >
      <path
        d="M32.9995 12L0.999512 12"
        stroke={props.color || "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9326 3.96738L34.9993 11.9994L26.9326 20.0327"
        stroke={props.color || "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AppDevIcon = (props) => {
  return (
    <svg
      width="42"
      height="36"
      viewBox="0 0 42 36"
      xmlns="http://www.w3.org/2000/svg"
      className={props.appDevStyle}
    >
      <path d="M21 0L41.7846 36H0.215391L21 0Z" />
    </svg>
  );
};

export const WebDevIcon = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className={props.webDevStyle}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 0L48 24L24 48L0 24L24 0Z" />
    </svg>
  );
};

export const UiUxIcon = (props) => {
  return (
    <svg
      width="46"
      height="44"
      viewBox="0 0 46 44"
      className={props.uiUxDesignStyle}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23 0L45.8254 16.5836L37.1068 43.4164H8.89315L0.174644 16.5836L23 0Z" />
    </svg>
  );
};
export const FooterSvg = (props) => {
  return (
    <svg
      width="240"
      height="241"
      viewBox="0 0 240 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="GIT" className={styles.getInTouchImgStyle}>
        <path
          id="Star 1"
          d="M107.035 8.82268C114.935 3.75169 125.065 3.75169 132.965 8.82268L146.964 17.8089C150.437 20.0384 154.426 21.3346 158.546 21.5723L175.153 22.5305C184.525 23.0712 192.721 29.026 196.131 37.7718L202.174 53.27C203.674 57.1151 206.139 60.5086 209.333 63.1227L222.205 73.6594C229.469 79.6053 232.6 89.2403 230.218 98.3203L225.997 114.411C224.95 118.403 224.95 122.597 225.997 126.589L230.218 142.68C232.6 151.76 229.469 161.395 222.205 167.341L209.333 177.877C206.139 180.491 203.674 183.885 202.174 187.73L196.131 203.228C192.721 211.974 184.525 217.929 175.153 218.469L158.546 219.428C154.426 219.665 150.437 220.962 146.964 223.191L132.965 232.177C125.065 237.248 114.935 237.248 107.035 232.177L93.0363 223.191C89.5633 220.962 85.574 219.665 81.4538 219.428L64.8466 218.469C55.4751 217.929 47.279 211.974 43.8688 203.228L37.8256 187.73C36.3263 183.885 33.8608 180.491 30.6672 177.877L17.795 167.341C10.5311 161.395 7.40045 151.76 9.78216 142.68L14.0027 126.589C15.0499 122.597 15.0499 118.403 14.0027 114.411L9.78215 98.3203C7.40044 89.2403 10.5311 79.6053 17.795 73.6594L30.6672 63.1227C33.8608 60.5086 36.3263 57.1151 37.8256 53.27L43.8688 37.7718C47.279 29.026 55.4751 23.0712 64.8466 22.5305L81.4538 21.5723C85.574 21.3346 89.5633 20.0384 93.0363 17.8089L107.035 8.82268Z"
          fill="#1A33FA"
        />
        <g id="Get in touch">
          <path
            id="."
            d="M108.869 184.327L108.476 186.428L106.391 186.031L106.785 183.93L108.869 184.327Z"
            fill="white"
          />
          <path
            id="G"
            d="M81.1444 171.735C82.0049 172.372 82.6787 173.106 83.1657 173.936C83.6527 174.766 83.9447 175.655 84.0417 176.602C84.1344 177.555 84.0253 178.539 83.7145 179.554C83.3935 180.571 82.8611 181.589 82.1173 182.608C81.1341 183.956 80.045 184.945 78.8501 185.576C77.6551 186.207 76.43 186.468 75.1747 186.359C73.9151 186.256 72.701 185.772 71.5323 184.906C70.1253 183.864 69.2331 182.693 68.8557 181.394C68.474 180.1 68.5371 178.762 69.0451 177.378L70.8522 178.216C70.5538 179.177 70.5373 180.108 70.8028 181.009C71.0682 181.909 71.6486 182.691 72.5439 183.354C73.4757 184.054 74.4087 184.42 75.3429 184.453C76.267 184.488 77.166 184.238 78.04 183.703C78.914 183.168 79.7236 182.396 80.469 181.387C81.2101 180.384 81.7015 179.385 81.9433 178.391C82.1851 177.397 82.1531 176.453 81.8473 175.558C81.5315 174.666 80.9076 173.87 79.9758 173.17C79.172 172.566 78.3784 172.217 77.5952 172.124C76.8121 172.031 76.0513 172.172 75.3129 172.545C74.5745 172.919 73.8677 173.501 73.1924 174.291L75.8611 176.268L74.9185 177.56L70.6102 174.368C70.6783 174.238 70.7722 174.091 70.8919 173.927C71.0015 173.765 71.0777 173.654 71.1204 173.595C71.9626 172.441 72.9322 171.567 74.0293 170.972C75.1164 170.378 76.2687 170.131 77.4864 170.23C78.6983 170.324 79.9177 170.826 81.1444 171.735Z"
            fill="white"
          />
          <path
            id="E"
            d="M73.4114 165.332L61.5264 175.493L55.1527 167.926L56.5475 166.733L61.6745 172.821L65.3968 169.638L61.1104 164.549L62.5052 163.356L66.7917 168.445L70.7699 165.044L65.6429 158.957L67.0378 157.764L73.4114 165.332Z"
            fill="white"
          />
          <path
            id="T"
            d="M63.1178 150.751L50.975 157.269L53.474 161.995L51.8594 162.862L45.9556 151.695L47.5702 150.829L50.0691 155.555L62.2119 149.038L63.1178 150.751Z"
            fill="white"
          />
          <path
            id="I"
            d="M56.9532 133.387L41.6562 136.405L41.286 134.5L56.583 131.482L56.9532 133.387Z"
            fill="white"
          />
          <path
            id="N"
            d="M55.7144 125.862L40.1279 126.029L40.1072 124.066L52.1978 115.745L40.0208 115.875L40.0001 113.912L55.5866 113.745L55.6073 115.708L43.5059 124.029L55.6937 123.899L55.7144 125.862Z"
            fill="white"
          />
          <path
            id="T_2"
            d="M59.4643 98.4822L46.5039 93.8307L44.7204 98.8754L42.9971 98.2569L47.2107 86.3388L48.934 86.9573L47.1505 92.0019L60.1109 96.6534L59.4643 98.4822Z"
            fill="white"
          />
          <path
            id="O"
            d="M66.9894 84.2624C66.1452 85.574 65.141 86.4994 63.9768 87.0387C62.8126 87.5779 61.5638 87.7542 60.2304 87.5676C58.9008 87.3748 57.564 86.8393 56.2198 85.9611C54.8757 85.0828 53.8451 84.0715 53.1281 82.9271C52.4151 81.7766 52.0654 80.5559 52.0792 79.265C52.093 77.974 52.522 76.6728 53.3662 75.3612C54.2143 74.0435 55.2205 73.115 56.3847 72.5757C57.5489 72.0365 58.7958 71.8632 60.1253 72.056C61.4548 72.2487 62.7917 72.7842 64.1358 73.6624C65.48 74.5407 66.5106 75.552 67.2275 76.6964C67.9445 77.8409 68.2961 79.0585 68.2823 80.3494C68.2685 81.6403 67.8376 82.9446 66.9894 84.2624ZM65.4455 83.2536C66.0837 82.2753 66.3919 81.3154 66.37 80.3739C66.3461 79.4223 66.0415 78.5257 65.4565 77.6841C64.8753 76.8364 64.0641 76.0724 63.0226 75.3919C61.9752 74.7075 60.9518 74.2729 59.9527 74.088C58.9575 73.8971 58.0268 73.9866 57.1607 74.3567C56.2986 74.7207 55.5484 75.3917 54.9102 76.37C54.2719 77.3482 53.9637 78.3081 53.9856 79.2496C54.0114 80.1851 54.3148 81.0767 54.896 81.9243C55.4771 82.772 56.2895 83.5411 57.333 84.2316C58.3705 84.9181 59.3888 85.3538 60.388 85.5387C61.3871 85.7236 62.3208 85.636 63.189 85.276C64.0551 84.9059 64.8072 84.2318 65.4455 83.2536Z"
            fill="white"
          />
          <path
            id="U"
            d="M77.4301 72.2527C76.543 73.0746 75.5909 73.6158 74.5739 73.8764C73.5569 74.137 72.5516 74.1151 71.5582 73.8106C70.5651 73.4959 69.6583 72.889 68.8377 71.9898L62.1411 64.6516L63.5681 63.3149L70.1621 70.5406C70.6603 71.0865 71.1854 71.4792 71.7372 71.7187C72.2895 71.9479 72.8391 72.056 73.3862 72.0431C73.9332 72.0302 74.4484 71.9234 74.9317 71.7225C75.4155 71.5114 75.838 71.2386 76.1992 70.9039C76.5658 70.5644 76.8713 70.1579 77.1157 69.6845C77.3602 69.2111 77.5115 68.7055 77.5696 68.1677C77.6282 67.6195 77.5622 67.0584 77.3716 66.4843C77.181 65.9102 76.839 65.3529 76.3457 64.8123L69.7517 57.5866L71.186 56.2578L77.8899 63.604C78.7056 64.4978 79.2249 65.4589 79.4476 66.4872C79.6757 67.5106 79.6182 68.5219 79.2753 69.5211C78.9323 70.5203 78.3173 71.4309 77.4301 72.2527Z"
            fill="white"
          />
          <path
            id="C"
            d="M90.9328 62.951C89.5648 63.6887 88.242 64.0104 86.9641 63.916C85.6862 63.8217 84.5082 63.3684 83.4301 62.556C82.3583 61.7403 81.4445 60.6208 80.6885 59.1977C79.9325 57.7745 79.5149 56.3874 79.4356 55.0364C79.3627 53.6819 79.6417 52.4429 80.2725 51.3192C80.9034 50.1955 81.9028 49.2648 83.2707 48.5271C84.8613 47.6693 86.385 47.3712 87.8418 47.6329C89.305 47.8912 90.5967 48.6049 91.7169 49.7739L90.2348 51.167C89.4065 50.2859 88.4746 49.7411 87.439 49.5326C86.4033 49.3241 85.3034 49.5339 84.139 50.1618C83.1147 50.7142 82.3864 51.408 81.9543 52.2431C81.5221 53.0782 81.3573 54.0083 81.4598 55.0334C81.5589 56.052 81.8999 57.1176 82.4826 58.2301C83.062 59.3361 83.7465 60.2164 84.5362 60.8709C85.3225 61.519 86.1852 61.895 87.1243 61.9988C88.06 62.0962 89.04 61.8687 90.0644 61.3163C91.2287 60.6883 92.012 59.8824 92.4142 58.8985C92.8131 57.9082 92.88 56.8247 92.6151 55.648L94.5877 55.178C94.9283 56.7592 94.8012 58.238 94.2064 59.6144C93.6146 60.981 92.5233 62.0932 90.9328 62.951Z"
            fill="white"
          />
          <path
            id="H"
            d="M99.8251 58.6892L96.0332 43.4554L97.9021 42.9832L99.5742 49.7008L107.533 47.6899L105.861 40.9722L107.73 40.5L111.521 55.7338L109.653 56.206L107.978 49.4778L100.019 51.4887L101.694 58.217L99.8251 58.6892Z"
            fill="white"
          />
          <path
            id="._2"
            d="M131.742 56.6723L132.153 54.5752L134.235 54.9901L133.823 57.0871L131.742 56.6723Z"
            fill="white"
          />
          <path
            id="G_2"
            d="M159.358 69.5048C158.503 68.8598 157.836 68.1202 157.356 67.2859C156.876 66.4516 156.592 65.5604 156.503 64.6123C156.418 63.6584 156.536 62.6752 156.856 61.6628C157.185 60.6489 157.726 59.6356 158.479 58.6228C159.474 57.2841 160.571 56.3045 161.771 55.6839C162.972 55.0634 164.199 54.8129 165.453 54.9326C166.712 55.0464 167.922 55.5412 169.083 56.4171C170.481 57.4717 171.363 58.6503 171.729 59.953C172.1 61.2498 172.025 62.5878 171.505 63.9668L169.706 63.1133C170.012 62.1547 170.037 61.224 169.779 60.3211C169.521 59.4183 168.948 58.6313 168.058 57.9602C167.132 57.2528 166.203 56.8784 165.269 56.837C164.345 56.7942 163.444 57.0364 162.565 57.5636C161.687 58.0907 160.871 58.8556 160.117 59.8582C159.367 60.855 158.867 61.8494 158.617 62.8414C158.366 63.8334 158.39 64.7779 158.688 65.6749C158.996 66.5705 159.613 67.3719 160.539 68.0793C161.338 68.6909 162.128 69.0465 162.911 69.1462C163.693 69.2458 164.455 69.1121 165.196 68.745C165.938 68.3779 166.65 67.8022 167.332 67.0178L164.68 65.0176L165.634 63.7341L169.914 66.9632C169.845 67.0927 169.75 67.2389 169.629 67.4019C169.518 67.5634 169.441 67.6733 169.398 67.7315C168.546 68.8781 167.569 69.7442 166.466 70.3298C165.374 70.9138 164.22 71.1511 163.003 71.0417C161.792 70.9365 160.577 70.4242 159.358 69.5048Z"
            fill="white"
          />
          <path
            id="E_2"
            d="M167.035 75.9741L179.007 65.9164L185.315 73.539L183.91 74.7194L178.836 68.5878L175.086 71.7378L179.329 76.8642L177.924 78.0446L173.681 72.9182L169.674 76.2847L174.748 82.4163L173.343 83.5967L167.035 75.9741Z"
            fill="white"
          />
          <path
            id="T_3"
            d="M177.202 90.6427L189.401 84.2316L186.942 79.4837L188.564 78.6312L194.372 89.8484L192.75 90.7009L190.292 85.9529L178.094 92.364L177.202 90.6427Z"
            fill="white"
          />
          <path
            id="I_2"
            d="M183.218 108.06L198.54 105.176L198.894 107.084L183.572 109.968L183.218 108.06Z"
            fill="white"
          />
          <path
            id="N_2"
            d="M184.392 115.598L199.979 115.567L199.983 117.53L187.822 125.745L199.999 125.721L200.003 127.684L184.416 127.715L184.412 125.752L196.584 117.536L184.396 117.561L184.392 115.598Z"
            fill="white"
          />
          <path
            id="T_4"
            d="M180.408 142.942L193.328 147.706L195.155 142.677L196.873 143.311L192.557 155.192L190.839 154.558L192.666 149.529L179.746 144.765L180.408 142.942Z"
            fill="white"
          />
          <path
            id="O_2"
            d="M172.76 157.098C173.616 155.794 174.628 154.878 175.797 154.348C176.966 153.819 178.216 153.654 179.548 153.852C180.875 154.057 182.208 154.604 183.544 155.494C184.881 156.384 185.903 157.404 186.61 158.554C187.313 159.711 187.652 160.935 187.627 162.226C187.602 163.516 187.162 164.814 186.307 166.118C185.447 167.428 184.433 168.348 183.264 168.877C182.096 169.406 180.847 169.568 179.519 169.364C178.192 169.16 176.859 168.613 175.523 167.723C174.186 166.833 173.164 165.813 172.457 164.662C171.75 163.511 171.409 162.291 171.434 161C171.459 159.709 171.901 158.409 172.76 157.098ZM174.296 158.121C173.649 159.093 173.333 160.05 173.346 160.992C173.362 161.944 173.659 162.843 174.237 163.69C174.811 164.542 175.615 165.314 176.651 166.003C177.692 166.697 178.712 167.14 179.71 167.334C180.703 167.533 181.634 167.452 182.504 167.089C183.369 166.733 184.125 166.068 184.771 165.096C185.418 164.123 185.735 163.166 185.721 162.224C185.703 161.289 185.407 160.394 184.833 159.542C184.26 158.689 183.454 157.913 182.416 157.213C181.385 156.518 180.37 156.073 179.373 155.88C178.375 155.686 177.441 155.765 176.569 156.118C175.7 156.48 174.942 157.148 174.296 158.121Z"
            fill="white"
          />
          <path
            id="U_2"
            d="M162.217 169.014C163.111 168.2 164.068 167.667 165.087 167.415C166.106 167.164 167.111 167.194 168.102 167.508C169.092 167.831 169.994 168.446 170.807 169.352L177.44 176.748L176.002 178.073L169.47 170.79C168.976 170.239 168.455 169.842 167.905 169.598C167.355 169.364 166.806 169.251 166.259 169.259C165.712 169.267 165.196 169.37 164.711 169.566C164.225 169.773 163.8 170.042 163.436 170.374C163.067 170.71 162.758 171.114 162.509 171.585C162.261 172.056 162.105 172.561 162.042 173.098C161.979 173.645 162.04 174.207 162.226 174.783C162.412 175.359 162.749 175.919 163.237 176.464L169.769 183.747L168.323 185.063L161.683 177.659C160.875 176.758 160.364 175.792 160.15 174.762C159.931 173.737 159.997 172.726 160.348 171.73C160.7 170.733 161.323 169.828 162.217 169.014Z"
            fill="white"
          />
          <path
            id="C_2"
            d="M148.635 178.198C150.009 177.472 151.335 177.162 152.612 177.268C153.889 177.373 155.063 177.837 156.134 178.659C157.199 179.484 158.103 180.611 158.847 182.041C159.591 183.47 159.997 184.861 160.064 186.213C160.126 187.568 159.836 188.804 159.195 189.922C158.555 191.041 157.548 191.962 156.173 192.688C154.576 193.532 153.049 193.817 151.595 193.542C150.134 193.271 148.848 192.546 147.738 191.368L149.232 189.988C150.053 190.876 150.98 191.429 152.014 191.646C153.048 191.864 154.149 191.664 155.319 191.046C156.348 190.503 157.082 189.815 157.522 188.984C157.961 188.153 158.134 187.224 158.04 186.198C157.95 185.179 157.618 184.11 157.045 182.993C156.475 181.882 155.798 180.995 155.014 180.334C154.233 179.679 153.374 179.296 152.435 179.184C151.501 179.078 150.519 179.297 149.49 179.84C148.32 180.458 147.53 181.257 147.119 182.237C146.712 183.224 146.635 184.307 146.89 185.486L144.914 185.939C144.587 184.355 144.726 182.877 145.333 181.506C145.937 180.145 147.037 179.042 148.635 178.198Z"
            fill="white"
          />
          <path
            id="H_2"
            d="M139.707 182.38L143.368 197.647L141.495 198.103L139.881 191.371L131.905 193.312L133.52 200.044L131.647 200.5L127.986 185.234L129.859 184.778L131.476 191.52L139.451 189.579L137.834 182.836L139.707 182.38Z"
            fill="white"
          />
        </g>
      </g>
      <g id="abc">
        <g id="Iconly/Light/Arrow - Right_2">
          <g id="Arrow - Right">
            <path
              id="Stroke 1"
              d="M134.351 105.369L106.067 133.654"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Stroke 3"
              d="M111.584 105.417L134.351 105.368L134.304 128.137"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
