import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Footer, NavBar } from "../../components";
import architectImg from "../../assets/images/architectPageImg.svg";
import photo from "../../assets/images/photoPicAbout.svg";
import webDevBlueImg from "../../assets/images/webDevBlueImg.svg";
import webDevBlackImg from "../../assets/images/webDevBlackImg.svg";
import quoteImg from "../../assets/images/doubleQuote.svg";
import quoteBlackImg from "../../assets/images/quoteBlackImg.svg";
import photoImg from "../../assets/images/photoImg.svg";
import { useAppData } from "../../providers/AppDataProvider";
import { Helmet } from "react-helmet";
import imviImg from "../../assets/images/Imvi-project-png.png";
import reactNativeImg from "../../assets/images/reactnativeLogo.svg";
import firebaseImg from "../../assets/images/firebaseLogo.svg";
import figmaImg from "../../assets/images/figmaLogo.svg";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import commonStyles from "../../assets/css/common.module.css";

const ImviPage = () => {
  const { strings } = useAppData();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele1 = document.getElementById("imvi");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < -150
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("imvi");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < -150
      ) {
        setNavBarColor(true);
        setNavAboutcol(true);
      } else if (oldScrollY < window.scrollY) {
        // setNavBarColor(true);
      } else if (window.pageYOffset <= 200) {
        setNavBarColor(false);
        setNavAboutcol(true);
      }
      oldScrollY = window.scrollY;
    };
  }
  const renderHeaderTopContainer = () => {
    return (
      <div className={styles.headerTopContainerStyles}>
        <NavLink
          to={"/projects"}
          className={classNames(
            commonStyles.anchorStyle,
            styles.backBtnTextStyle
          )}
        >
          {strings.common.backtoprojects}
        </NavLink>
        <div>
          <h1 className={styles.headerTextStyle}>
            {strings.imviPage.heroSection.heroHeader}
          </h1>
          <p className={styles.overViewDescTextStyle}>
            {strings.imviPage.heroSection.desc}
          </p>
        </div>
        <div className={styles.imgWrapperStyles}>
          <img
            src={imviImg}
            alt="Image of Imvi VR glasses"
            className={styles.imgStyles}
          />
        </div>
        <div className={styles.clientsContainerStyle}>
          <div className={styles.overViewContainerStyle}>
            <h3 className={styles.overViewTextStyle}>
              {strings.imviPage.heroSection.overView}
            </h3>
            <p className={styles.overViewDescTextStyle}>
              {strings.imviPage.heroSection.overViewDesc}
            </p>
          </div>
          <div className={styles.clientsOutsideContainerStyle}>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.imviPage.heroSection.client}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.imviPage.heroSection.clientName}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.imviPage.heroSection.services}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.imviPage.heroSection.servicesDesc}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.imviPage.heroSection.links}
              </h3>
              <div className={styles.linkContainerStyle}>
                <p className={styles.clientBottomTextStyle}>
                  {strings.imviPage.heroSection.linkText1}
                  <a
                    className={styles.linkStyle}
                    href="https://play.google.com/store/apps/details?id=com.viljatech.imviread&pli=1"
                    target={"_blank"}
                  >
                    {strings.imviPage.heroSection.link1}
                  </a>
                </p>
                <p className={styles.clientBottomTextStyle}>
                  {strings.imviPage.heroSection.linktext2}
                  <a
                    className={styles.linkStyle}
                    href="https://apps.apple.com/se/app/imvi-read/id1595883903"
                    target={"_blank"}
                  >
                    {strings.imviPage.heroSection.link2}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderPrblmSolutionContainer = () => {
    return (
      <div className={styles.prblmSolutionMainContainerStyle}>
        <div className={styles.prblmSolinsideContainerStyle}>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.imviPage.prblmSection.headerText1}
            </h2>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.imviPage.prblmSection.headerText1Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.imviPage.prblmSection.headerText2}
            </h2>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.imviPage.prblmSection.headerText2Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.imviPage.prblmSection.headerText3}
            </h2>
            <div className={styles.unorderedListContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.imviPage.prblmSection.headerText3Desc.mainText1}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point1[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point1[2]}
                </li>
                {/* <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point1[3]}
                </li> */}
              </ul>
            </div>
            <div className={styles.doneContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.imviPage.prblmSection.headerText3Desc.mainText2}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point2[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point2[2]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point2[3]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.imviPage.prblmSection.headerText3Desc.point2[4]}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className={styles.divderStyles}></div>;
  };
  const renderUnderStandingUserNeedsContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <p className={styles.blueTextStyle}>
              {strings.imviPage.prblmSection.headerText3Desc.mainText1}
            </p>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.underStandingSection.uiDesc}
            </p>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.underStandingSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.underStandingSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.underStandingSection.headerText}
                </h2>
              </div>
            </div>
            <div className={styles.pointsContainerStyle}>
              <ul className={styles.listContainerStyle2}>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc1}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc2}
                </li>
                {/* <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc3}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc4}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc5}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.underStandingSection.desc6}
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderWireFramesContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.wireFramesSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.wireFramesSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.wireFramesSection.headerText}
                </h2>
              </div>
            </div>
            <div className={styles.pointsContainerStyle}>
              <ul className={styles.listContainerStyle2}>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.wireFramesSection.desc1}
                </li>
                {/* <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.wireFramesSection.desc2}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.wireFramesSection.desc3}
                </li>
                <li className={styles.understandDescTextStyle}>
                  {strings.imviPage.wireFramesSection.desc4}
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className={styles.imgContainerStyle}>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  // const renderProtoTypingContainer = () => {
  //   return (
  //     <div className={styles.understandingMainContainerStyle}>
  //       <div className={styles.understandingInsideContainerStyle}>
  //         <div className={styles.headerOutsideWrapperStyle}>
  //           <div className={styles.headerWrapperStyle}>
  //             <p className={styles.uiAndUxSmallTextStyle}>
  //               {strings.imviPage.protoTypingSection.smallText}
  //             </p>
  //             <div className={styles.understandingNeedsHeaderContainerStyle}>
  //               <p className={styles.numberingStyle}>
  //                 {strings.imviPage.protoTypingSection.one}
  //               </p>
  //               <h2 className={styles.underStandingHeaderStyle}>
  //                 {strings.imviPage.protoTypingSection.headerText}
  //               </h2>
  //             </div>
  //           </div>
  //           <ul className={styles.listContainerStyle2}>
  //             <li className={styles.understandDescTextStyle}>
  //               {strings.imviPage.protoTypingSection.desc1}
  //             </li>
  //             <li className={styles.understandDescTextStyle}>
  //               {strings.imviPage.protoTypingSection.desc2}
  //             </li>
  //             <li className={styles.understandDescTextStyle}>
  //               {strings.imviPage.protoTypingSection.desc3}
  //             </li>
  //             <li className={styles.understandDescTextStyle}>
  //               {strings.imviPage.protoTypingSection.desc4}
  //             </li>
  //             <li className={styles.understandDescTextStyle}>
  //               {strings.imviPage.protoTypingSection.desc5}
  //             </li>
  //           </ul>
  //         </div>
  //         {/* <div className={styles.imgContainerStyle}>
  //           <div className={styles.photWrapperStyles}>
  //             <img src={photo} className={styles.imgStyles2} />
  //           </div>
  //           <div className={styles.photWrapperStyles}>
  //             <img src={photo} className={styles.imgStyles2} />
  //           </div>
  //         </div> */}
  //       </div>
  //     </div>
  //   );
  // };
  const renderAppDevelopMentFrontEndContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <p className={styles.blueTextStyle}>
              {strings.imviPage.prblmSection.headerText3Desc.mainText2}
            </p>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection.appDesc}
            </p>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.appDevSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.appDevSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.appDevSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection.desc1}
            </p>
            {/* <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection.desc2}
            </p> */}
          </div>

          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentBackendContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.appDevSection2.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.appDevSection2.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.appDevSection2.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection2.desc1}
            </p>
            {/* <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection2.desc2}
            </p>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection2.desc3}
            </p> */}
          </div>

          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentTestingAndDeploymentContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.appDevSection3.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.appDevSection3.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.appDevSection3.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection3.desc1}
            </p>
            {/* <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection3.desc2}
            </p> */}
          </div>

          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentMaintanenceContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.imviPage.appDevSection4.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.imviPage.appDevSection4.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.imviPage.appDevSection4.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection4.desc1}
            </p>
            {/* <p className={styles.understandDescTextStyle}>
              {strings.imviPage.appDevSection4.desc2}
            </p> */}
          </div>

          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
          <div className={styles.technologyContainerStyle}>
            <p className={styles.technologiesTextStyle}>
              {strings.imviPage.technologiesSection.technologiesHeaderText}
            </p>
            <div className={styles.technologiesContainerStyle}>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={reactNativeImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.imviPage.technologiesSection.technologies1}
                </p>
              </div>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={firebaseImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.imviPage.technologiesSection.technologies2}
                </p>
              </div>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={figmaImg} className={styles.imgStyles} />
                </div>
                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.imviPage.technologiesSection.technologies3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderResultsContainer = () => {
    return (
      <div className={styles.resultsContainerStyles}>
        <div className={styles.resultsInsideContainerStyle}>
          <div className={styles.resultsHeaderContainerStyle}>
            <h2 className={styles.headerTextStyle}>
              {strings.imviPage.resultsSection.headerText}
            </h2>
            <p className={styles.descriptionTextStyle}>
              {strings.imviPage.resultsSection.desc}
            </p>
          </div>
          <div className={styles.quoteImgDescContainerStyle}>
            <div className={styles.quoteImgContainerStyle}>
              <img src={quoteBlackImg} className={styles.quoteImgStyles} />
            </div>
            <div className={styles.resultBoxDescContainerStyle}>
              <p className={styles.resultsDescTextStyle}>
                {strings.imviPage.resultsSection.cardDesc}
              </p>
              <div className={styles.clientResultContainerStyle}>
                {/* <div className={styles.resultImgWrapperStyle}>
                  <img src={photoImg} className={styles.imgStyles} />
                </div> */}
                <div>
                  <p className={styles.nameTextStyle}>
                    {strings.imviPage.resultsSection.name}
                  </p>
                  <p className={styles.companyTextStyle}>
                    {strings.imviPage.resultsSection.jobposition}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="imvi" className={styles.mainContainerStyle}>
      <Helmet>
        <meta name="description" content={strings.imviPage.seo.description} />
        <meta name="keywords" content={strings.imviPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/projects/imvi" />
        <title>{strings.imviPage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={true}
        backgroundColor={
          navAboutCol ? styles.backGroundStyle2 : styles.backGroundStyle
        }
      />
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderHeaderTopContainer()}
        </div>
      </div>
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderPrblmSolutionContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderUnderStandingUserNeedsContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderWireFramesContainer()}
        </div>
      </div>
      {/* {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderProtoTypingContainer()}
        </div>
      </div> */}
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentFrontEndContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentBackendContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentTestingAndDeploymentContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentMaintanenceContainer()}
        </div>
      </div>
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderResultsContainer()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ImviPage;
