import React, { useState, useEffect } from "react";
import { Footer, NavBar } from "../../components";
import commonStyles from "../../assets/css/common.module.css";
import styles from "./styles.module.css";
import quoteImg from "../../assets/images/doubleQuote.svg";
import { useAppData } from "../../providers/AppDataProvider";
import photoImg from "../../assets/images/photoImg.svg";
import classNames from "classnames";
import { ArrowLeftLongIcon, ArrowRightLongIcon } from "../../components/Svg";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ServicesPage = () => {
  const { strings } = useAppData();
  const navigate = useNavigate();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const clientData = [
    {
      id: 1,
      content: strings.HomePage.fifthView.cardDesc,
      name: strings.HomePage.fifthView.name,
      position: strings.HomePage.fifthView.jobPosition,
    },
    {
      id: 2,
      content: strings.HomePage.fifthView.cardDesc1,
      name: strings.HomePage.fifthView.name1,
      position: strings.HomePage.fifthView.jobPosition1,
    },
    {
      id: 3,
      content: strings.HomePage.fifthView.cardDesc2,
      name: strings.HomePage.fifthView.name2,
      position: strings.HomePage.fifthView.jobPosition2,
    },
  ];
  const [current, setCurrent] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele4 = document.getElementById("services4");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele4.getBoundingClientRect().top < 50
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("services");
    let ele2 = document.getElementById("services2");
    let ele3 = document.getElementById("services3");
    let ele4 = document.getElementById("services4");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < 40
      ) {
        setNavBarColor(true);
        setNavAboutcol(false);

        if (ele2.getBoundingClientRect().top < 40) {
          setNavAboutcol(true);
        }
      } else if (
        oldScrollY < window.scrollY &&
        ele4.getBoundingClientRect().top < 50
      ) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
        setNavAboutcol(true);
      } else if (ele3.getBoundingClientRect().bottom > 0) {
        setNavAboutcol(true);
      } else if (ele1.getBoundingClientRect().bottom > 30) {
        setNavAboutcol(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  const minSwipeDistance = 50;
  const length = clientData.length;
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setCurrent(current === length - 1 ? 0 : current + 1);
    } else if (isRightSwipe) {
      setCurrent(current === 0 ? length - 1 : current - 1);
    }
  };
  const nextTestimonialSlide = () => {
    let slideIndex = currentIndex;
    if (currentIndex <= 1) {
      slideIndex = currentIndex + 1;
      setCurrentIndex(currentIndex + 1);
    }
    const ele = document.getElementById(`client${slideIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const prevTestimonialSlide = () => {
    let slideIndex = currentIndex;
    if (currentIndex > 1) {
      slideIndex = currentIndex - 1;
      setCurrentIndex(currentIndex - 1);
    }
    const ele = document.getElementById(`client${slideIndex}`);
    ele.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  const renderServicesTopContainer = () => {
    return (
      <div id="services3" className={styles.servicesTopContainer}>
        <div className={styles.descriptionWrapperStyle}>
          <h1 className={styles.headerTextStyle}>
            {strings.ServicesPage.firstView.title}
          </h1>
          <p id="services4" className={styles.descriptionTextStyle}>
            {strings.ServicesPage.firstView.desc}
          </p>
        </div>
        <div className={styles.servicesSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNumberStyle}>
              {strings.ServicesPage.firstView.one}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.ServicesPage.firstView.appDev}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.ServicesPage.firstView.appDevDesc}
            </p>
          </div>
        </div>
        <div className={styles.servicesSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNumberStyle}>
              {strings.ServicesPage.firstView.two}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.ServicesPage.firstView.webDev}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.ServicesPage.firstView.webDevDesc}
            </p>
          </div>
        </div>
        <div className={styles.servicesSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNumberStyle}>
              {strings.ServicesPage.firstView.three}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.ServicesPage.firstView.uiUx}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.ServicesPage.firstView.uiUxDesc}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderServicesOurWorkContainer = () => {
    return (
      <div className={styles.servicesOurWorkinsideContainerStyle}>
        <div className={styles.quoteImgContainerStyle}>
          <img src={quoteImg} className={styles.quoteImgStyle} />
        </div>

        <div className={styles.ourWorkDescContainerStyle}>
          <p className={styles.servicesOurWorkDescTextStyle}>
            {strings.ServicesPage.secondView.desc}
          </p>
          <a
            className={classNames(
              commonStyles.anchorStyle,
              styles.seeOurTextStyle
            )}
            onClick={() => navigate("/projects")}
          >
            {strings.ServicesPage.secondView.seeOurWork}
          </a>
        </div>
      </div>
    );
  };
  const renderWhyChooseUsContainer = () => {
    return (
      <div id="services2" className={styles.whyChooseContainerStyle}>
        <h2 className={styles.whyChooseUsTextStyle}>
          {strings.ServicesPage.thirdView.whyChoose}
        </h2>
        <div className={styles.whyChooseUsDescContainerStyle}>
          <div className={styles.whychooseUsFirstDescStyle}>
            <h3 className={styles.whyChooseUsHeaderTextStyle}>
              {strings.ServicesPage.thirdView.Transparent}
            </h3>
            <p className={styles.whyChooseUsDescTextStyle}>
              {strings.ServicesPage.thirdView.TransparentDesc}
            </p>
          </div>
          <div className={styles.whychooseUsFirstDescStyle}>
            <h3 className={styles.whyChooseUsHeaderTextStyle}>
              {strings.ServicesPage.thirdView.Reliable}
            </h3>
            <p className={styles.whyChooseUsDescTextStyle}>
              {strings.ServicesPage.thirdView.ReliableDesc}
            </p>
          </div>

          <div className={styles.whychooseUsFirstDescStyle}>
            <h3 className={styles.whyChooseUsHeaderTextStyle}>
              {strings.ServicesPage.thirdView.Explore}
            </h3>
            <p className={styles.whyChooseUsDescTextStyle}>
              {strings.ServicesPage.thirdView.ExploreDesc}
            </p>
          </div>
          <div className={styles.whychooseUsFirstDescStyle}>
            <h3 className={styles.whyChooseUsHeaderTextStyle}>
              {strings.ServicesPage.thirdView.creative}
            </h3>
            <p className={styles.whyChooseUsDescTextStyle}>
              {strings.ServicesPage.thirdView.creativeDesc}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderServicesClientContainer = () => {
    return (
      <div className={styles.servicesClientinsideContainerStyle}>
        <div className={styles.clientTopSectionInsideContainerStyle}>
          <h2 className={styles.clientContainerHeaderStyle}>
            {strings.ServicesPage.fourthView.clientLoveUs}
          </h2>
          <p className={styles.clientContainerDescStyle}>
            {strings.ServicesPage.fourthView.clientDesc}
          </p>
        </div>

        <div className={styles.clientsDetailsGridStyle}>
          {clientData.map((item, index) => {
            return (
              <div
                key={"client" + item.id}
                id={"client" + item.id}
                className={styles.clientsDetailsCardStyle}
              >
                <p className={styles.clientsDetailsCardContentStyle}>
                  {item.content}
                </p>
                <div className={styles.clientContainerStyle}>
                  {/* <div className={styles.photoImgWrapperStyle}>
                    <img src={photoImg} className={styles.imgStyle} />
                  </div> */}
                  <div>
                    <p className={styles.clientsDetailsCardNameStyle}>
                      {item.name}
                    </p>
                    <p className={styles.clientsDetailsCardCompanyStyle}>
                      {item.position}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.psSliderWrapperStyle}>
          <ArrowLeftLongIcon
            color={currentIndex <= 1 ? "rgba(0, 0, 0, 0.2)" : "rgb(0, 0, 0)"}
            onClick={() => prevTestimonialSlide()}
          />
          <ArrowRightLongIcon
            color={currentIndex >= 2 ? "rgba(0, 0, 0, 0.2)" : "rgb(0, 0, 0)"}
            onClick={() => nextTestimonialSlide()}
          />
        </div>
        <div className={styles.clientsDetailsGridStyle2}>
          {clientData.map((item, index) => {
            return (
              <React.Fragment key={"clientData" + index}>
                {current === index && (
                  <div
                    className={styles.clientsDetailsCardStyle}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                  >
                    <p className={styles.clientsDetailsCardContentStyle}>
                      {item.content}
                    </p>
                    <div className={styles.clientContainerStyle}>
                      <div className={styles.photoImgWrapperStyle}>
                        <img src={photoImg} className={styles.imgStyle} />
                      </div>
                      <div>
                        <p className={styles.clientsDetailsCardNameStyle}>
                          {item.name}
                        </p>
                        <p className={styles.clientsDetailsCardCompanyStyle}>
                          {item.position}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.dotsContainerStyles}>
          {clientData.map((item, index) => {
            return (
              <div
                key={"item" + index}
                className={
                  current === index
                    ? styles.dotsSelectedStyle
                    : styles.dotsStyle
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <meta
          name="description"
          content={strings.ServicesPage.seo.description}
        />
        <meta name="keywords" content={strings.ServicesPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/services" />
        <title>{strings.ServicesPage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={true}
        backgroundColor={width > 980 ? null : styles.backGroundStyle}
      />
      <div className={styles.servicesContainerStyle}>
        {renderServicesTopContainer()}
      </div>
      <div id="services" className={styles.servicesOurWorkContainerStyle}>
        <div className={styles.servicesOurWorkOutsideContainerStyle}>
          {renderServicesOurWorkContainer()}
        </div>
      </div>
      <div className={styles.servicesContainerStyle}>
        {renderWhyChooseUsContainer()}
      </div>
      <div className={styles.servicesClientContainerStyle}>
        <div className={styles.servicesClientOutsideContainerStyle}>
          {renderServicesClientContainer()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
