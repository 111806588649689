const BASE_URL = "http://192.168.29.212:3001/api/v1";
const endPoints = {
  login: `${BASE_URL}/user/login`,
  createUser: `${BASE_URL}/user/create`,
  getUserList: `${BASE_URL}/user/all`,
  deleteUser: `${BASE_URL}/user/delete/id`,
  updateUser: `${BASE_URL}/user/update`,
  createPost: `${BASE_URL}/post/create`,
  getAllPosts: `${BASE_URL}/post/all`,
  updatePost: `${BASE_URL}/post/update`,
};
export { endPoints };
