import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { Footer, NavBar } from "../../components";
import architectImg from "../../assets/images/architectPageImg.svg";
import photo from "../../assets/images/photoPicAbout.svg";
import webDevBlueImg from "../../assets/images/webDevBlueImg.svg";
import webDevBlackImg from "../../assets/images/webDevBlackImg.svg";
import quoteImg from "../../assets/images/doubleQuote.svg";
import quoteBlackImg from "../../assets/images/quoteBlackImg.svg";
import photoImg from "../../assets/images/photoImg.svg";
import { useAppData } from "../../providers/AppDataProvider";
import { Helmet } from "react-helmet";

const PrizbeePage = () => {
  const { strings } = useAppData();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  const renderHeaderTopContainer = () => {
    return (
      <div className={styles.headerTopContainerStyles}>
        <h1 className={styles.headerTextStyle}>
          {strings.prizbeePage.heroSection.heroHeader}
        </h1>
        <div className={styles.imgWrapperStyles}>
          <img src={architectImg} className={styles.imgStyles} />
        </div>
        <div className={styles.clientsContainerStyle}>
          <div className={styles.overViewContainerStyle}>
            <h3 className={styles.overViewTextStyle}>
              {strings.prizbeePage.heroSection.overView}
            </h3>
            <p className={styles.overViewDescTextStyle}>
              {strings.prizbeePage.heroSection.overViewDesc}
            </p>
          </div>
          <div className={styles.clientsOutsideContainerStyle}>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.prizbeePage.heroSection.client}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.prizbeePage.heroSection.clientName}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.prizbeePage.heroSection.services}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.prizbeePage.heroSection.servicesDesc}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.prizbeePage.heroSection.links}
              </h3>
              <div className={styles.linkContainerStyle}>
                <p className={styles.clientBottomTextStyle}>
                  {strings.prizbeePage.heroSection.linkText1}
                  <a className={styles.linkStyle}>
                    {strings.prizbeePage.heroSection.link1}
                  </a>
                </p>
                <p className={styles.clientBottomTextStyle}>
                  {strings.prizbeePage.heroSection.linktext2}
                  <a className={styles.linkStyle}>
                    {strings.prizbeePage.heroSection.link1}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderPrblmSolutionContainer = () => {
    return (
      <div className={styles.prblmSolutionMainContainerStyle}>
        <div className={styles.prblmSolinsideContainerStyle}>
          <div className={styles.itemInsideWrapperStyle}>
            <h3 className={styles.prblmHeaderTextStyle}>
              {strings.prizbeePage.prblmSection.headerText1}
            </h3>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.prizbeePage.prblmSection.headerText1Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h3 className={styles.prblmHeaderTextStyle}>
              {strings.prizbeePage.prblmSection.headerText2}
            </h3>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.prizbeePage.prblmSection.headerText2Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h3 className={styles.prblmHeaderTextStyle}>
              {strings.prizbeePage.prblmSection.headerText3}
            </h3>
            <div className={styles.unorderedListContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.prizbeePage.prblmSection.headerText3Desc.mainText1}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point1[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point1[2]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point1[3]}
                </li>
              </ul>
            </div>
            <div className={styles.doneContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.prizbeePage.prblmSection.headerText3Desc.mainText2}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point2[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point2[2]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.prizbeePage.prblmSection.headerText3Desc.point2[3]}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className={styles.divderStyles}></div>;
  };
  const renderUnderStandingUserNeedsContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.prizbeePage.underStandingSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.prizbeePage.underStandingSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.prizbeePage.underStandingSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.prizbeePage.underStandingSection.desc}
            </p>
          </div>
          <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div>
        </div>
      </div>
    );
  };
  const renderWireFramesContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.prizbeePage.wireFramesSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.prizbeePage.wireFramesSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.prizbeePage.wireFramesSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.prizbeePage.wireFramesSection.desc}
            </p>
          </div>
          <div className={styles.imgContainerStyle}>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderProtoTypingContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.prizbeePage.protoTypingSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.prizbeePage.protoTypingSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.prizbeePage.protoTypingSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.prizbeePage.protoTypingSection.desc}
            </p>
          </div>
          <div className={styles.imgContainerStyle}>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAppDevelopMentContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.prizbeePage.appDevSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.prizbeePage.appDevSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.prizbeePage.appDevSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.prizbeePage.appDevSection.desc}
            </p>
          </div>
          <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div>
          <div className={styles.technologyContainerStyle}>
            <p className={styles.technologiesTextStyle}>
              {strings.prizbeePage.technologiesSection.technologiesHeaderText}
            </p>
            <div className={styles.technologiesContainerStyle}>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={webDevBlackImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.prizbeePage.technologiesSection.technologies1}
                </p>
              </div>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={webDevBlackImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.prizbeePage.technologiesSection.technologies2}
                </p>
              </div>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={webDevBlackImg} className={styles.imgStyles} />
                </div>
                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.prizbeePage.technologiesSection.technologies3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderResultsContainer = () => {
    return (
      <div className={styles.resultsContainerStyles}>
        <div className={styles.resultsInsideContainerStyle}>
          <div className={styles.resultsHeaderContainerStyle}>
            <h2 className={styles.headerTextStyle}>
              {strings.prizbeePage.resultsSection.headerText}
            </h2>
            <p className={styles.descriptionTextStyle}>
              {strings.prizbeePage.resultsSection.desc}
            </p>
          </div>
          <div className={styles.quoteImgDescContainerStyle}>
            <div className={styles.quoteImgContainerStyle}>
              <img src={quoteImg} className={styles.quoteImgStyles} />
            </div>
            <div className={styles.resultBoxDescContainerStyle}>
              <p className={styles.resultsDescTextStyle}>
                {strings.prizbeePage.resultsSection.cardDesc}
              </p>
              <div className={styles.clientResultContainerStyle}>
                <div className={styles.resultImgWrapperStyle}>
                  <img src={photoImg} className={styles.imgStyles} />
                </div>
                <div>
                  <p className={styles.nameTextStyle}>
                    {strings.prizbeePage.resultsSection.name}
                  </p>
                  <p className={styles.companyTextStyle}>
                    {strings.prizbeePage.resultsSection.jobposition}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.mainContainerStyle}>
      <Helmet>
        <meta
          name="description"
          content={strings.prizbeePage.seo.description}
        />
        <meta name="keywords" content={strings.prizbeePage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/projects/prizbee" />
        <title>{strings.prizbeePage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar navBarView={true} backgroundColor={styles.backGroundStyle} />
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderHeaderTopContainer()}
        </div>
      </div>
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderPrblmSolutionContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderUnderStandingUserNeedsContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderWireFramesContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderProtoTypingContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentContainer()}
        </div>
      </div>
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderResultsContainer()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrizbeePage;
