import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import NavBar from "../../components/NavBar";
import { useAppData } from "../../providers/AppDataProvider";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { FaLinkedinIn } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const PrivacyPage = () => {
  const { strings } = useAppData();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele = document.getElementById("about4");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele.getBoundingClientRect().top < 50
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele = document.getElementById("about4");
    let ele1 = document.getElementById("about");
    let ele2 = document.getElementById("about2");
    let ele3 = document.getElementById("about3");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < 40
      ) {
        setNavBarColor(true);
        setNavAboutcol(false);

        if (ele2.getBoundingClientRect().top < 40) {
          setNavAboutcol(true);
        }
      } else if (
        oldScrollY < window.scrollY &&
        ele.getBoundingClientRect().top < 50
      ) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
        setNavAboutcol(true);
      } else if (ele3.getBoundingClientRect().bottom > 0) {
        setNavAboutcol(true);
      } else if (ele1.getBoundingClientRect().bottom > 30) {
        setNavAboutcol(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  const rendderFooterContainer = () => {
    return (
      <div className={styles.footerContainerStyle}>
        <div className={styles.addressContainerStyle}>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.address}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                window.open("https://goo.gl/maps/p97E4hM9Yicx4WNMA", "__blank")
              }
            >
              {strings.FooterComponent.openInMaps}
            </a>
          </p>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.email}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                (window.location.href = `mailto:${strings.FooterComponent.emailAddress}`)
              }
            >
              {strings.FooterComponent.emailAddress}
            </a>
          </p>
          <p className={styles.addressTextStyle}>
            {strings.FooterComponent.call}
            <a
              className={styles.addressTextLinkStyle}
              onClick={() =>
                (window.location.href = `tel:${strings.FooterComponent.phoneNo}`)
              }
            >
              {strings.FooterComponent.phoneNo}
            </a>
          </p>
        </div>
        <div className={styles.socialIconContainerStyle}>
          <div className={styles.iconWrapperStyle}>
            <FaLinkedinIn
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/vilje-tech/",
                  "__blank"
                )
              }
              color={"#ffffff"}
              className={styles.iconsStyle}
            />
          </div>
          <div className={styles.iconWrapperStyle}>
            <FiInstagram
              onClick={() =>
                window.open("https://www.instagram.com/viljetech/", "__blank")
              }
              color={"#ffffff"}
              className={styles.iconsStyle}
            />
          </div>
          {/* <div className={styles.iconWrapperStyle}>
            <BsTwitter color={"#ffffff"} className={styles.iconsStyle} />
          </div>
          <div className={styles.iconWrapperStyle}>
            <FaFacebookF color={"#ffffff"} className={styles.iconsStyle} />
          </div> */}
        </div>
        <p className={styles.footerCopyrightTextStyle}>
          {strings.FooterComponent.copyright}
        </p>
      </div>
    );
  };
  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <link rel="canonical" href="https://viljetech.com/privacypolicy" />
        <title>ViljeTech | Privacy Policy</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={false}
        backgroundColor={styles.backGroundStyle}
      />
      <div className={styles.privacyPageContainerStyle}>
        <div className={styles.privacyHeaderContainerStyle}>
          <h1 className={styles.headerTextStyle}>
            {strings.privacyPage.headerText}
          </h1>
          <p className={styles.headerContentTextStyle}>
            {strings.privacyPage.headerContent}
          </p>
        </div>
        <div className={styles.privacyContentContainerStyle}>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content1}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content2}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content3}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content4}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content5}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content6}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content7}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content8}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content9}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content10}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content11}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content12}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content13}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content14}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content15}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content16}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content17}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content18}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content19}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content20}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content21}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content22}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content23}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content24}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content25}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content26}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content27}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content28}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content29}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content30}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content31}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content32}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content33}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content34}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content35}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content36}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content37}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content38}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content39}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content40}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content41}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content42}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content43}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content44}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content45}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content46}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content47}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content48}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content49}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content50}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content51}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content52}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content53}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content54}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content55}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content56}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content57}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content58}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content59}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content60}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content61}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content62}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content63}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content64}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content65}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content66}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content67}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content68}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content69}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content70}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content71}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content72}
          </p>
          <p className={styles.contentTextStyle}>
            {strings.privacyPage.content73}
          </p>
        </div>
      </div>
      {rendderFooterContainer()}
    </div>
  );
};

export default PrivacyPage;
