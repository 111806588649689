import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import blog1Img from "../../../assets/images/Conversating-robots.png";
import blog2Img from "../../../assets/images/SEO-robot-on-a-bench.jpeg";
import blog3Img from "../../../assets/images/SEO-Extension-for-ChatGPT.jpeg";
import blogImg4 from "../../../assets/images/Two-hands-reaching out.jpg";
import blogImg5 from "../../../assets/images/Abstract representation of UX design.png";
import news1Img from "../../../assets/images/viljeNews1Img.jpg";
import blogAuthorImg from "../../../assets/images/blogAuthorImg.svg";
import { useNavigate } from "react-router-dom";
import { useAppData } from "../../../providers/AppDataProvider";
import { ArrowLeftLongIcon, ArrowRightLongIcon } from "../../../components/Svg";
import { useWindowScrollPositions } from "../../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";

const BlogList = () => {
  const { strings } = useAppData();
  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);

  const blogData = [
    {
      id: 1,
      image: blog1Img,
      alt: "Two robots having a conversation",
      headerText: "Designens framtid i det robotiserade internetet",
      title: "Designens-framtid-i-det-robotiserade-internetet",
      desc: "Under den senaste veckan har stora aktörer inom AI-utvecklingsvärlden meddelat att de snart kommer att integrera AI i arbetsplatsverktyg som e-post, Excel-ark, dokument osv. Med tanke på den hastighet som AI-integration sker i olika verktyg är världen där vi ber AI att skriva ett e-postmeddelande och läsa upp inkorgen inte särskilt långt borta. I en sådan värld, där mänsklig interaktion med den grafiska gränssnittet är minimal, spelar design en viktig roll. Eftersom robotar inte bryr sig om estetik, tillgänglighet och upplevelse av en digital produkt så mycket som människor gör.",
    },
    {
      id: 2,
      image: blog2Img,
      alt: "Robot sitting on a bench with a computer",
      headerText: "Förbättra din webbplats SEO med ChatGPT",
      title: "Förbättra-din-webbplats-SEO-med-ChatGPT",
      desc: "Sökmotoroptimering (SEO) är en viktig del av webbdesign och webbutveckling i dagens digitala era. Med miljontals webbplatser som söker efter användarnas uppmärksamhet kan det vara svårt och tidskrävande att locka besökare till din webbplats utan att optimera innehållet på den. Skapande och underhåll av webbplatsinnehåll kan vara en arbetsam process som kräver omfattande forskning och analys, särskilt när det gäller sökordsforskning och innehållsscouting. Utmaningen kan vara ännu mer krävande för företag som har begränsade budgetar, såsom startups. Lyckligtvis finns det en lösning på detta dilemma, och det är HELT gratis!",
    },
    {
      id: 3,
      image: blog3Img,
      alt: "SEO Extension for ChatGPT",
      headerText: "AIPRM: The Revolutionary SEO Extension for ChatGPT",
      title: "AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT",
      desc: "As businesses move towards digital marketing, search engine optimization (SEO) becomes a critical element in ensuring online success. With the vast amount of content available online, it's essential to stand out from the crowd and optimise your content for search engines. This is where AIPRM comes in - the revolutionary SEO extension for ChatGPT that provides you with SEO prompts from other users.",
    },
    {
      id: 4,
      image: blogImg4,
      alt: "Image of two hands reaching out to each other",
      headerText:
        "Building Long-Lasting Relationships: The Key to Making Authentic Sales",
      title:
        "Building-Long-Lasting-Relationships:The-Key-to-Making-Authentic-Sales",
      desc: `As the CEO of Vilje Tech, I strongly believe in building long-lasting relationships with our clients. Sales are not just about closing a deal; they are about fostering connections, understanding our clients' needs, and creating value that lasts beyond a transaction. As the great Zig Ziglar once said, "If people like you, they'll listen to you, but if they trust you, they'll do business with you."`,
    },
    {
      id: 5,
      image: blogImg5,
      alt: "User Experince Blog image",
      headerText:
        "3 Simple Changes to Improve the User Experience on Your Website",
      title: "3-Simple-Changes-to-Improve-the-User-Experience-on-Your-Website",
      desc: "The user experience (UX) of your website can make or break your online success. A great UX can lead to increased engagement, higher conversion rates, and better brand recognition, while a poor UX can result in high bounce rates, low customer satisfaction, and reduced sales. Learn how to improve the user experience in three easy steps.",
    },
  ];
  const News = [
    {
      id: 1,
      image: news1Img,
      alt: "Two robots having a conversation",
      headerText: "Join our Innovation Competition - 50 hours to greatness",
      title: "Join-our-Innovation-Competition-50-hours-to-greatness",
      desc: "Vilje Tech is announcing our latest competition where you have a chance to bring your vision to life. We firmly believe in the power of innovation to drive change and improvement, and we want to provide an opportunity for start-ups to take their ideas to the next level.",
    },
  ];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogData.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele1 = document.getElementById("bloglist");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < 0
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("bloglist");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (oldScrollY < window.scrollY && ele1.getBoundingClientRect().top < 0) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 10) {
        setNavBarColor(false);
      }
      oldScrollY = window.scrollY;
    };
  }

  const nextBlogs = () => {
    if (indexOfLastItem >= blogData.length) {
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevBlogs = () => {
    if (currentPage === 1) {
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div>
      <Helmet>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://viljetech.com/blogposts" />
        <title>ViljeTech | Blogposts</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={true}
        backgroundColor={width > 980 ? null : styles.backGroundStyle}
      />
      <div className={styles.bloglistMainContainerStyle}>
        <h1 id="bloglist" className={styles.blogListHeaderTextStyle}>
          {strings.common.blotitle}
        </h1>
        <div className={styles.dividerLineStyle}></div>
        <div className={styles.blogsListAndPagenationStyle}>
          <div className={styles.blogPostsSectionContainerStyle}>
            <div className={styles.blogpostHeaderContainerStyle}>
              <h2 className={styles.blogpostHeaderTextStyle}>
                {strings.common.blogposts}
              </h2>
            </div>
            <div className={styles.blogListContainerStyle}>
              {currentItems.map((item, index) => {
                return (
                  <div
                    className={styles.blogListItemWrapperStyle}
                    key={"blog" + item.id}
                    onClick={() => navigate(`/blogposts/${item.title}`)}
                  >
                    <div className={styles.blogImgListWrapperStyle}>
                      <img
                        src={item.image}
                        alt={item.alt}
                        className={styles.imgStyle}
                      />
                    </div>
                    <div className={styles.blogListItemContentWrapperStyle}>
                      <p className={styles.blogListItemHeaderTextStyle}>
                        {item.headerText}
                      </p>
                      <p className={styles.blogListItemContentTextStyle}>
                        {item.desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.paSliderWrapperStyle}>
            <ArrowLeftLongIcon
              color={currentPage === 1 ? "rgba(0, 0, 0, 0.2)" : "rgb(0, 0, 0)"}
              onClick={() => prevBlogs()}
            />
            <ArrowRightLongIcon
              color={
                indexOfLastItem >= blogData.length
                  ? "rgba(0, 0, 0, 0.2)"
                  : "rgb(0, 0, 0)"
              }
              onClick={() => nextBlogs()}
            />
          </div>
        </div>
        <div className={styles.dividerLineStyle}></div>
        <div>
          <div className={styles.blogPostsSectionContainerStyle}>
            <div className={styles.blogpostHeaderContainerStyle}>
              <h2 className={styles.blogpostHeaderTextStyle}>
                {strings.common.news}
              </h2>
            </div>
            <div className={styles.newsListContainerStyle}>
              {News.map((item, index) => {
                return (
                  <div
                    className={styles.blogListItemWrapperStyle}
                    key={"news" + item.id}
                    onClick={() => navigate(`/newsposts/${item.title}`)}
                  >
                    <div className={styles.blogImgListWrapperStyle}>
                      <img
                        src={item.image}
                        alt={item.imagealt}
                        className={styles.imgStyle}
                      />
                    </div>
                    <div className={styles.blogListItemContentWrapperStyle}>
                      <p className={styles.blogListItemHeaderTextStyle}>
                        {item.headerText}
                      </p>
                      <p className={styles.blogListItemContentTextStyle}>
                        {item.desc}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogList;
