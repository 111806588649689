import React, { useEffect, useState } from "react";
import { NavBar, Footer } from "../../components/index";
import commonStyles from "../../assets/css/common.module.css";
import styles from "./styles.module.css";
import quoteImg from "../../assets/images/doubleQuote.svg";
import photo from "../../assets/images/photoPicAbout.svg";
import { useAppData } from "../../providers/AppDataProvider";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AboutPage = () => {
  const { strings } = useAppData();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const Values = [
    {
      id: 1,
      header: strings.AboutPage.valuesView.weaim,
      desc: strings.AboutPage.valuesView.weaimDesc,
    },
    {
      id: 2,
      header: strings.AboutPage.valuesView.wesupport,
      desc: strings.AboutPage.valuesView.wesupportDesc,
    },
    {
      id: 3,
      header: strings.AboutPage.valuesView.wenever,
      desc: strings.AboutPage.valuesView.weneverDesc,
    },
    {
      id: 4,
      header: strings.AboutPage.valuesView.weleave,
      desc: strings.AboutPage.valuesView.weleaveDesc,
    },
  ];
  const Team = [
    {
      id: 1,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
    {
      id: 2,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
    {
      id: 3,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
    {
      id: 4,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
    {
      id: 5,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
    {
      id: 6,
      image: photo,
      name: strings.AboutPage.bottomView.name,
      position: strings.AboutPage.bottomView.jobPosition,
    },
  ];
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele = document.getElementById("about4");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele.getBoundingClientRect().top < 50
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele = document.getElementById("about4");
    let ele1 = document.getElementById("about");
    let ele2 = document.getElementById("about2");
    let ele3 = document.getElementById("about3");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < 40
      ) {
        setNavBarColor(true);
        setNavAboutcol(false);

        if (ele2.getBoundingClientRect().top < 40) {
          setNavAboutcol(true);
        }
      } else if (
        oldScrollY < window.scrollY &&
        ele.getBoundingClientRect().top < 50
      ) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
        setNavAboutcol(true);
      } else if (ele3.getBoundingClientRect().bottom > 0) {
        setNavAboutcol(true);
      } else if (ele1.getBoundingClientRect().bottom > 30) {
        setNavAboutcol(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const renderAboutTopContainer = () => {
    return (
      <div id="about3" className={styles.aboutTopContainer}>
        <div className={styles.headerWrapperStyle}>
          <div className={styles.headerScrollStyle}>
            <h1 className={styles.headerWeTextStyle}>
              <span className={styles.headerWeTextStyle2}>
                {strings.AboutPage.topView.we}
              </span>
              <span className={styles.headerSpanStyle}>
                <p className={styles.bgImgStyle}>
                  {strings.AboutPage.topView.design}
                </p>
                <p className={styles.bgImgStyle}>
                  {strings.AboutPage.topView.develop}
                </p>
                <p className={styles.bgImgStyle}>
                  {strings.AboutPage.topView.deliver}
                </p>
              </span>
            </h1>
          </div>
        </div>
        <div className={styles.descriptionWrapperStyle}>
          <p id="about4" className={styles.descriptionTextStyle}>
            {strings.AboutPage.topView.desc}
          </p>
        </div>
        <div className={styles.aboutSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNoTextStyle}>
              {strings.AboutPage.topView.one}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.AboutPage.topView.design}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.AboutPage.topView.rightDesc1}
            </p>
          </div>
        </div>
        <div className={styles.aboutSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNoTextStyle}>
              {strings.AboutPage.topView.two}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.AboutPage.topView.develop}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.AboutPage.topView.rightDesc2}
            </p>
          </div>
        </div>
        <div className={styles.aboutSectionContainerStyle}>
          <div className={styles.leftSectionStyle}>
            <p className={styles.leftSectionNoTextStyle}>
              {strings.AboutPage.topView.three}
            </p>
          </div>
          <div className={styles.leftSectionStyle}>
            <h2 className={styles.leftSectionHeaderTextStyle}>
              {strings.AboutPage.topView.deliver}
            </h2>
            <p className={styles.leftSectionTextStyle}>
              {strings.AboutPage.topView.rightDesc3}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderAboutMiddleContainer = () => {
    return (
      <div id="about" className={styles.aboutMiddleinsideContainerStyle}>
        <div className={styles.quoteImgContainerStyle}>
          <img src={quoteImg} className={styles.quoteImgStyle} />
        </div>

        <div>
          <p className={styles.aboutMiddleDescTextStyle}>
            {strings.AboutPage.middleView.desc}
          </p>
          <a
            className={classNames(
              commonStyles.anchorStyle,
              styles.seeOurTextStyle
            )}
            onClick={() => navigate("/projects")}
          >
            {strings.AboutPage.middleView.ourWork}
          </a>
        </div>
      </div>
    );
  };
  const renderValuesContainer = () => {
    return (
      <div id="about2" className={styles.aboutValuesContainerStyle}>
        <h3 className={styles.valuesTopTextStyle}>
          {strings.AboutPage.valuesView.valueText}
        </h3>
        <div className={styles.valuesGridContainerStyle}>
          {Values.map((item, index) => {
            return (
              <div key={index}>
                <h2 className={styles.itemHeaderTextStyle}>{item.header}</h2>
                <p className={styles.itemDescTextStyle}>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderAboutBottomContainer = () => {
    return (
      <div className={styles.aboutBottomoutsideContainerStyle}>
        <div className={styles.aboutBottomContainerStyle}>
          <div className={styles.meetOurTextContainerStyle}>
            <h2 className={styles.meetOurTeamTextStyle}>
              {strings.AboutPage.bottomView.meetOuraTeam}
            </h2>
            <p className={styles.meetOurTeamDesStyle}>
              {strings.AboutPage.bottomView.teamDesc}
            </p>
          </div>
          <div className={styles.teamDetailsContainerStyle}>
            {Team.map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.teamPhotoContainer}>
                    <img src={item.image} className={styles.teamPhotoStyle} />
                  </div>

                  <p className={styles.nameTextStyle}>{item.name}</p>
                  <p className={styles.positionTextStyle}>{item.position}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="aboutPage" className={styles.mainContainer}>
      <Helmet>
        <meta name="description" content={strings.AboutPage.seo.description} />
        <meta name="keywords" content={strings.AboutPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/about" />
        <title> {strings.AboutPage.seo.title} </title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={true}
        backgroundColor={width > 980 ? null : styles.backGroundStyle}
      />
      <div className={styles.aboutContainerStyle}>
        {renderAboutTopContainer()}
      </div>
      <div className={styles.aboutMiddleContainerStyle}>
        <div className={styles.aboutMiddleOutsideContainerStyle}>
          {renderAboutMiddleContainer()}
        </div>
      </div>
      {renderValuesContainer()}

      {/* {renderAboutBottomContainer()} */}

      <Footer />
    </div>
  );
};

export default AboutPage;
