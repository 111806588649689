import React from "react";
import styles from "./styles.module.css";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.errorPageMainContainerStyle}>
      <NavBar navBarView={true} color={true} />
      <div className={styles.errorPageInsideContainerStyle}>
        <div className={styles.errorPageContainerStyle}>
          <p className={styles.errorHeaderTextStyle}>404 error</p>
          <p className={styles.errorContentTextStyle}>
            The URL you are trying to reach could not be found on the server.
            Let us find a better place for you to go.
            <span
              className={styles.backToHomeTextStyle}
              onClick={() => navigate("/")}
            >
              Back to home
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
