import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import commonStyles from "../../../assets/css/common.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppData } from "../../../providers/AppDataProvider";
import { useWindowScrollPositions } from "../../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import newsImg1 from "../../../assets/images/viljeNews1Img.jpg";

const NewsPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { strings } = useAppData();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const { scrollX, scrollY } = useWindowScrollPositions();
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const [navWhiteCol, setNavWhiteCol] = useState(true);
  const [blog, setBlog] = useState();
  const NewsData = [
    {
      id: 1,
      image: newsImg1,
      blogalt: "",
      imagealt: "",
      headerText: "Join our Innovation Competition - 50 hours to greatness ",
      title: "Join-our-Innovation-Competition-50-hours-to-greatness",
      desc: "Vilje Tech is announcing our latest competition where you have a chance to bring your vision to life. We firmly believe in the power of innovation to drive change and improvement, and we want to provide an opportunity for start-ups to take their ideas to the next level.",
      seoDesc:
        "Participate in Vilje Tech competition for the chance of winning 50 development or design hours and turn your idea into reality.",
      Keywords:
        "Vilje Tech, competition, vision, innovation, start-ups, development, design",
      canonical: "",
      blogContent: [
        {
          id: 1,
          content:
            "Here's how it works: Enter our competition on LinkedIn or Instagram, where you'll find all the information you need on how to participate and the rules for applying. The winner will receive 50 development or design hours, allowing you to turn your idea into a reality.",
        },
        {
          id: 2,
          content:
            "Whether you're looking to build a minimum viable product (MVP), enhance your brand portfolio, or create wireframes for an application, our team at Vilje Tech is up for a challenge. We understand the challenges faced by early-stage start-ups and want to be your partner in success.",
        },
        {
          id: 3,
          content:
            "By participating in our competition, you not only get a chance to win valuable development or design hours but also gain exposure to a wider audience. Our platform will provide a stage for you to showcase your innovative idea, potentially attracting investors, mentors, and collaborators who can further propel your entrepreneurial journey.",
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blog]);
  useEffect(() => {
    var foundValue = NewsData.filter(
      (obj) => obj.title === decodeURI(location.pathname.split("/").pop())
    );
    setBlog(foundValue);
  }, [location.pathname]);

  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);

  function mobileScrollDetect() {
    let ele1 = document.getElementById("blogpost");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < 50
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("blogpost");
    let ele2 = document.getElementById("blogpost2");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele2.getBoundingClientRect().top < 40
      ) {
        setNavBarColor(true);
        setNavWhiteCol(true);
      } else if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < 50
      ) {
        setNavBarColor(true);
        setNavWhiteCol(false);
      } else if (window.pageYOffset <= 100) {
        setNavBarColor(false);
      } else if (ele2.getBoundingClientRect().top > 30) {
        setNavWhiteCol(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  return (
    <>
      {blog && (
        <div>
          <Helmet>
            <meta name="description" content={blog[0].seoDesc} />
            <meta name="keywords" content={blog[0].Keywords} />
            <link rel="canonical" href={blog[0].canonical} />
            <title>ViljeTech | {blog[0].headerText}</title>
            {/* <!-- Start of HubSpot Embed Code --> */}
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js-eu1.hs-scripts.com/27027103.js"
            ></script>
            {/* <!-- End of HubSpot Embed Code --> */}
          </Helmet>
          <NavBar
            navBarView={navBarDisplay}
            navAbout={navAboutCol}
            navColor={navBarColor}
            contactCol={navWhiteCol}
            color={false}
            backgroundColor={
              width > 980
                ? navAboutCol
                  ? styles.backGroundStyle2
                  : styles.backGroundStyle
                : styles.backGroundStyle
            }
          />
          <div className={styles.blogpostOuterContainerStyle}>
            <div className={styles.blogPostMainContainerStyle}>
              <NavLink
                to={"/blogposts"}
                className={classNames(
                  commonStyles.anchorStyle,
                  styles.backBtnTextStyle
                )}
              >
                Back to News
              </NavLink>
              <h1 id="blogpost" className={styles.blogpostHeaderTextStyle}>
                {blog[0].headerText}
              </h1>
              <div className={styles.blogImgWrapperStyle}>
                <img
                  src={blog[0].image}
                  alt={blog[0].blogalt}
                  className={styles.imgStyle}
                />
              </div>
              <div className={styles.blogContentTextWrapperStyle}>
                <p className={styles.blogContentTextStyle}>{blog[0].desc}</p>
                {blog[0].desc1 && (
                  <p className={styles.blogContentTextStyle}>{blog[0].desc1}</p>
                )}
                {blog[0].desc2 && (
                  <p className={styles.blogContentTextStyle}>{blog[0].desc2}</p>
                )}
              </div>
            </div>
          </div>
          <div id="blogpost2" className={styles.blogpostContentWrapperStyle}>
            <div className={styles.blogPostContentInnerWrapperStyle}>
              {blog[0].blogContent.map((item, index) => {
                return (
                  <div
                    key={"blog" + index}
                    className={styles.blogContentItemWrapperStyle}
                  >
                    <h2 className={styles.blogPostContentHeaderTextStyle}>
                      {item.header}
                    </h2>

                    {item.subContent &&
                      item.subContent.map((item, index) => (
                        <div
                          key={index}
                          className={styles.subContentContainerStyles}
                        >
                          <h4
                            className={styles.blogPostSubContentHeaderTextStyle}
                          >
                            {item.header}
                          </h4>
                          <p
                            className={styles.blogPostContentDescTextStyle}
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                          >
                            {/* {item.desc} */}
                          </p>
                          {item.subDesc &&
                            item.subDesc.map((item, index) => (
                              <div key={index}>
                                <h4
                                  className={
                                    styles.blogPostSubSubContentHeaderTextStyle
                                  }
                                >
                                  {item.header}
                                </h4>
                                <p
                                  className={
                                    styles.blogPostContentDescTextStyle
                                  }
                                >
                                  {item.desc}
                                </p>
                              </div>
                            ))}
                        </div>
                      ))}
                    <div className={styles.blogContentTextWrapperStyle}>
                      <p className={styles.blogPostContentDescTextStyle}>
                        {item.content}
                      </p>
                      {item.content1 && (
                        <p className={styles.blogPostContentDescTextStyle}>
                          {item.content1}
                        </p>
                      )}
                      {item.link && (
                        <p className={styles.blogPostContentDescTextStyle}>
                          {item.link.text}&nbsp;
                          <span
                            className={styles.linkStyle}
                            onClick={() =>
                              navigate(
                                `/blogposts/${"AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT"}`
                              )
                            }
                          >
                            {item.link.link}
                          </span>
                        </p>
                      )}
                      {item.list && (
                        <ul className={styles.listWrapperStyle}>
                          {item.list.map((list, index) => {
                            return <li>{list.li}</li>;
                          })}
                        </ul>
                      )}
                      {item.content2 && (
                        <p className={styles.blogPostContentDescTextStyle}>
                          {item.content2}
                        </p>
                      )}
                      {item.content3 && (
                        <p className={styles.blogPostContentDescTextStyle}>
                          {item.content3}
                        </p>
                      )}
                      {item.youtubelink && (
                        <p className={styles.blogPostContentDescTextStyle}>
                          <span className={styles.linkStyle}>
                            {item.youtubelink}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default NewsPost;
