import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { styled } from "@mui/system";

import { useNavigate } from "react-router-dom";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";
import { getAllPostsApiCall } from "../../../networking/post";

const Root = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

export default function Blog() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState();
  useEffect(() => {
    blogPostsListAction();
  }, []);
  const blogPostsListAction = async () => {
    const response = await getAllPostsApiCall();
    console.log(response.data.data);
    setList(response.data.data);
  };
  const deleteUserAction = async (id) => {
    // const response = await deleteUserApiCall(id);
    // console.log(response, "ppp");
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Root sx={{ maxWidth: "100%", width: 1660, padding: 3 }}>
      {list && (
        <table aria-label="custom pagination table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Created At</th>
              <th>Delete</th>
              <th>Update</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {(rowsPerPage > 0
              ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : list
            ).map((row, index) => (
              <tr key={index}>
                <td>{row.title}</td>
                <td align="right">
                  {new Date(row.created_at).toLocaleDateString()}
                </td>
                <td align="right">
                  <button onClick={() => deleteUserAction(row._id)}>
                    Delete
                  </button>
                </td>
                <td align="right">
                  <button
                    onClick={() =>
                      navigate("editblog", {
                        state: {
                          data: row,
                          page: "edit",
                        },
                      })
                    }
                  >
                    Update
                  </button>
                </td>
                <td align="right">
                  <button
                    onClick={() =>
                      navigate("/blogpost", {
                        state: {
                          data: row,
                          page: "view",
                        },
                      })
                    }
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}

            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={3} />
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={5}
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        </table>
      )}
    </Root>
  );
}
