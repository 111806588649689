import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { Footer, NavBar } from "../../components";
import bytbooImg from "../../assets/images/Bytboo-project-page.svg";
import quoteBlackImg from "../../assets/images/quoteBlackImg.svg";
import { useAppData } from "../../providers/AppDataProvider";
import { Helmet } from "react-helmet";
import reactImg from "../../assets/images/reactjsLogo.svg";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import commonStyles from "../../assets/css/common.module.css";

const BytbooPage = () => {
  const { strings } = useAppData();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const { scrollX, scrollY } = useWindowScrollPositions();
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele1 = document.getElementById("bytboo");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < -150
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("bytboo");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (
        oldScrollY < window.scrollY &&
        ele1.getBoundingClientRect().top < -150
      ) {
        setNavBarColor(true);
        setNavAboutcol(true);
      } else if (oldScrollY < window.scrollY) {
        // setNavBarColor(true);
      } else if (window.pageYOffset <= 200) {
        setNavBarColor(false);
        setNavAboutcol(true);
      }
      oldScrollY = window.scrollY;
    };
  }
  const renderHeaderTopContainer = () => {
    return (
      <div className={styles.headerTopContainerStyles}>
        <NavLink
          to={"/projects"}
          className={classNames(
            commonStyles.anchorStyle,
            styles.backBtnTextStyle
          )}
        >
          {strings.common.backtoprojects}
        </NavLink>
        <div>
          <h1 className={styles.headerTextStyle}>
            {strings.bytbooPage.heroSection.heroHeader}
          </h1>
          <p className={styles.overViewDescTextStyle}>
            {strings.bytbooPage.heroSection.desc}
          </p>
        </div>
        <div className={styles.imgWrapperStyles}>
          <img
            src={bytbooImg}
            alt="Building representing Bytboo project"
            className={styles.imgStyles}
          />
        </div>
        <div className={styles.clientsContainerStyle}>
          <div className={styles.overViewContainerStyle}>
            <h3 className={styles.overViewTextStyle}>
              {strings.bytbooPage.heroSection.overView}
            </h3>
            <p className={styles.overViewDescTextStyle}>
              {strings.bytbooPage.heroSection.overViewDesc}
            </p>
          </div>
          <div className={styles.clientsOutsideContainerStyle}>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.bytbooPage.heroSection.client}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.bytbooPage.heroSection.clientName}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.bytbooPage.heroSection.services}
              </h3>
              <p className={styles.clientBottomTextStyle}>
                {strings.bytbooPage.heroSection.servicesDesc}
              </p>
            </div>
            <div className={styles.clientsInsideContainerStyle}>
              <h3 className={styles.clientHeaderStyles}>
                {strings.bytbooPage.heroSection.links}
              </h3>
              <div className={styles.linkContainerStyle}>
                <p className={styles.clientBottomTextStyle}>
                  {strings.bytbooPage.heroSection.linkText1}
                  <a
                    className={styles.linkStyle}
                    href="https://bytboo.com "
                    target={"_blank"}
                  >
                    {strings.bytbooPage.heroSection.link1}
                  </a>
                </p>
                {/* <p className={styles.clientBottomTextStyle}>
                  {strings.bytbooPage.heroSection.linktext2}
                  <a className={styles.linkStyle}>
                    {strings.bytbooPage.heroSection.link2}
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderPrblmSolutionContainer = () => {
    return (
      <div className={styles.prblmSolutionMainContainerStyle}>
        <div className={styles.prblmSolinsideContainerStyle}>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.bytbooPage.prblmSection.headerText1}
            </h2>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.bytbooPage.prblmSection.headerText1Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.bytbooPage.prblmSection.headerText2}
            </h2>
            <p className={styles.prblmSoluDescTextStyle}>
              {strings.bytbooPage.prblmSection.headerText2Desc}
            </p>
          </div>
          <div className={styles.itemInsideWrapperStyle}>
            <h2 className={styles.prblmHeaderTextStyle}>
              {strings.bytbooPage.prblmSection.headerText3}
            </h2>
            {/* <div className={styles.unorderedListContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.bytbooPage.prblmSection.headerText3Desc.mainText1}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point1[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point1[2]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point1[3]}
                </li>
              </ul>
            </div> */}
            <div className={styles.doneContainerStyle}>
              <p className={styles.blueTextStyle}>
                {strings.bytbooPage.prblmSection.headerText3Desc.mainText2}
              </p>
              <ul className={styles.listContainerStyle}>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point2[1]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point2[2]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point2[3]}
                </li>
                <li className={styles.listTextStyle}>
                  {strings.bytbooPage.prblmSection.headerText3Desc.point2[4]}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className={styles.divderStyles}></div>;
  };
  const renderUnderStandingUserNeedsContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <p className={styles.blueTextStyle}>
              {strings.bytbooPage.prblmSection.headerText3Desc.mainText1}
            </p>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.underStandingSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.underStandingSection.one}
                </p>
                <h3 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.underStandingSection.headerText}
                </h3>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.underStandingSection.desc}
            </p>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderWireFramesContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.wireFramesSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.wireFramesSection.one}
                </p>
                <h3 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.wireFramesSection.headerText}
                </h3>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.wireFramesSection.desc}
            </p>
          </div>
          {/* <div className={styles.imgContainerStyle}>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  const renderProtoTypingContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.protoTypingSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.protoTypingSection.one}
                </p>
                <h3 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.protoTypingSection.headerText}
                </h3>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.protoTypingSection.desc}
            </p>
          </div>
          {/* <div className={styles.imgContainerStyle}>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
            <div className={styles.photWrapperStyles}>
              <img src={photo} className={styles.imgStyles2} />
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentFrontendContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <p className={styles.blueTextStyle}>
              {strings.bytbooPage.prblmSection.headerText3Desc.mainText2}
            </p>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.appDevSection.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.appDevSection.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.appDevSection.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.appDevSection.desc}
            </p>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentArchitectureContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.appDevSection2.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.appDevSection2.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.appDevSection2.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.appDevSection2.desc}
            </p>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentTestingContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.appDevSection3.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.appDevSection3.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.appDevSection3.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.appDevSection3.desc}
            </p>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
        </div>
      </div>
    );
  };
  const renderAppDevelopMentDeliveryContainer = () => {
    return (
      <div className={styles.understandingMainContainerStyle}>
        <div className={styles.understandingInsideContainerStyle}>
          <div className={styles.headerOutsideWrapperStyle}>
            <div className={styles.headerWrapperStyle}>
              <p className={styles.uiAndUxSmallTextStyle}>
                {strings.bytbooPage.appDevSection4.smallText}
              </p>
              <div className={styles.understandingNeedsHeaderContainerStyle}>
                <p className={styles.numberingStyle}>
                  {strings.bytbooPage.appDevSection4.one}
                </p>
                <h2 className={styles.underStandingHeaderStyle}>
                  {strings.bytbooPage.appDevSection4.headerText}
                </h2>
              </div>
            </div>
            <p className={styles.understandDescTextStyle}>
              {strings.bytbooPage.appDevSection4.desc}
            </p>
          </div>
          {/* <div className={styles.photWrapperStyles}>
            <img src={photo} className={styles.imgStyles2} />
          </div> */}
          <div className={styles.technologyContainerStyle}>
            <p className={styles.technologiesTextStyle}>
              {strings.bytbooPage.technologiesSection.technologiesHeaderText}
            </p>
            <div className={styles.technologiesContainerStyle}>
              <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={reactImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.bytbooPage.technologiesSection.technologies1}
                </p>
              </div>
              {/* <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={figmaImg} className={styles.imgStyles} />
                </div>

                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.bytbooPage.technologiesSection.technologies2}
                </p>
              </div> */}
              {/* <div className={styles.technologiesImgAndTextContainerStyle}>
                <div className={styles.technologiesImgStyle}>
                  <img src={webDevBlackImg} className={styles.imgStyles} />
                </div>
                <p className={styles.technologiesNameTextBlackStyle}>
                  {strings.bytbooPage.technologiesSection.technologies3}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderResultsContainer = () => {
    return (
      <div className={styles.resultsContainerStyles}>
        <div className={styles.resultsInsideContainerStyle}>
          <div className={styles.resultsHeaderContainerStyle}>
            <h2 className={styles.headerTextStyle}>
              {strings.bytbooPage.resultsSection.headerText}
            </h2>
            <p className={styles.descriptionTextStyle}>
              {strings.bytbooPage.resultsSection.desc}
            </p>
          </div>
          <div className={styles.quoteImgDescContainerStyle}>
            <div className={styles.quoteImgContainerStyle}>
              <img src={quoteBlackImg} className={styles.quoteImgStyles} />
            </div>
            <div className={styles.resultBoxDescContainerStyle}>
              <p className={styles.resultsDescTextStyle}>
                {strings.bytbooPage.resultsSection.cardDesc}
              </p>
              <div className={styles.clientResultContainerStyle}>
                {/* <div className={styles.resultImgWrapperStyle}>
                  <img src={photoImg} className={styles.imgStyles} />
                </div> */}
                <div>
                  <p className={styles.nameTextStyle}>
                    {strings.bytbooPage.resultsSection.name}
                  </p>
                  <p className={styles.companyTextStyle}>
                    {strings.bytbooPage.resultsSection.jobposition}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div id="bytboo" className={styles.mainContainerStyle}>
      <Helmet>
        <meta name="description" content={strings.bytbooPage.seo.description} />
        <meta name="keywords" content={strings.bytbooPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/projects/bytboo" />
        <title>{strings.bytbooPage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <NavBar
        navBarView={navBarDisplay}
        navAbout={navAboutCol}
        navColor={navBarColor}
        color={true}
        backgroundColor={
          navAboutCol ? styles.backGroundStyle2 : styles.backGroundStyle
        }
      />
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderHeaderTopContainer()}
        </div>
      </div>
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderPrblmSolutionContainer()}
        </div>
      </div>
      {/* {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderUnderStandingUserNeedsContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderWireFramesContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderProtoTypingContainer()}
        </div>
      </div> */}
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentFrontendContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentArchitectureContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentTestingContainer()}
        </div>
      </div>
      {renderDivider()}
      <div className={styles.prblmAndSolutionContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderAppDevelopMentDeliveryContainer()}
        </div>
      </div>
      <div className={styles.topContainerStyle}>
        <div className={styles.insideContainerStyle}>
          {renderResultsContainer()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BytbooPage;
