import React from "react";
import styles from "./styles.module.css";
import { useAppData } from "../../providers/AppDataProvider";
import { FaLinkedinIn } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const { strings } = useAppData();
  const navigate = useNavigate();

  return (
    <div
      className={
        props.whiteColor
          ? styles.footerMainWhiteContainer
          : styles.footerMainContainer
      }
    >
      <div className={styles.footerInsideContainerStyle}>
        <h2 className={props.whiteColor ? styles.text2Style : styles.textStyle}>
          {strings.FooterComponent.title}&nbsp;
          <span
            className={
              props.whiteColor
                ? styles.contactUsBlueTextStyle
                : styles.contactUsTextStyle
            }
            onClick={() => navigate("/contact")}
          >
            {strings.FooterComponent.contactUs}
          </span>
        </h2>
        <p
          className={
            props.whiteColor
              ? styles.adressHead2TextStyle
              : styles.adressHeadTextStyle
          }
        >
          {strings.FooterComponent.address}&nbsp;
          <span
            className={
              props.whiteColor
                ? styles.adressText2Style
                : styles.adressTextStyle
            }
            onClick={() =>
              window.open("https://goo.gl/maps/G8EaYbM2EW7fTzrM7", "__blank")
            }
          >
            {strings.FooterComponent.openInMaps}
          </span>
        </p>
        <p
          className={
            props.whiteColor
              ? styles.adressHead2TextStyle
              : styles.adressHeadTextStyle
          }
        >
          {strings.FooterComponent.email}&nbsp;
          <span
            className={
              props.whiteColor
                ? styles.adressText2Style
                : styles.adressTextStyle
            }
            onClick={() =>
              (window.location.href = `mailto:${strings.FooterComponent.emailAddress}`)
            }
          >
            {strings.FooterComponent.emailAddress}
          </span>
        </p>
        <p
          className={
            props.whiteColor
              ? styles.adressHead2TextStyle
              : styles.adressHeadTextStyle
          }
        >
          {strings.FooterComponent.call}&nbsp;
          <span
            className={
              props.whiteColor
                ? styles.adressText2Style
                : styles.adressTextStyle
            }
            onClick={() =>
              (window.location.href = `tel:${strings.FooterComponent.phoneNo}`)
            }
          >
            {strings.FooterComponent.phoneNo}
          </span>
        </p>
        <div className={styles.socialIconContainerStyle}>
          <div
            className={
              props.whiteColor
                ? styles.iconWrapper2Style
                : styles.iconWrapperStyle
            }
          >
            <FaLinkedinIn
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/vilje-tech/",
                  "__blank"
                )
              }
              color={props.whiteColor ? "#FFFFFF" : "#000000"}
              className={styles.iconsStyle}
            />
          </div>
          <div
            className={
              props.whiteColor
                ? styles.iconWrapper2Style
                : styles.iconWrapperStyle
            }
          >
            <FiInstagram
              onClick={() =>
                window.open("https://www.instagram.com/viljetech/", "__blank")
              }
              color={props.whiteColor ? "#FFFFFF" : "#000000"}
              className={styles.iconsStyle}
            />
          </div>
          {/* <div
            className={
              props.whiteColor
                ? styles.iconWrapper2Style
                : styles.iconWrapperStyle
            }
          >
            <BsTwitter
              color={props.whiteColor ? "#FFFFFF" : "#000000"}
              className={styles.iconsStyle}
            />
          </div> */}
          {/* <div
            className={
              props.whiteColor
                ? styles.iconWrapper2Style
                : styles.iconWrapperStyle
            }
          >
            <FaFacebookF
              //  onClick={() =>
              //   window.open(
              //     "https://www.linkedin.com/company/vilje-tech/",
              //     "__blank"
              //   )
              // }
              color={props.whiteColor ? "#FFFFFF" : "#000000"}
              className={styles.iconsStyle}
            />
          </div> */}
        </div>
        <p
          className={
            props.whiteColor
              ? styles.footerCopyrightText2Style
              : styles.footerCopyrightTextStyle
          }
        >
          {strings.FooterComponent.copyright}
        </p>
      </div>
    </div>
  );
};

export default Footer;
