const common = {
  Home: "HEM",
  about: "OM OSS",
  projects: "PROJEKT",
  services: "TJÄNSTER",
  contacts: "KONTAKT",
  close: "Stäng",
  english: "English",
  sweden: "Swedish",
  dutch: "German",
  backtoprojects: "See alla projekt",
  backtoblogs: "Till alla artiklar",
  blotitle: "Artiklar och nyheter",
  blogtitle: "Artiklar & nyheter",
  blogposts: "Artiklar",
  news: "Nyheter",
};
const HomePage = {
  seo: {
    title: " Vilje Tech |  Vi skapar digitala lösningar i världsklass! ",
    description:
      "Häng med i utvecklingen med Vilje Techs tekniklösningar. Våra experter erbjuder webbutveckling, apputveckling, UX/UI-design och mer för att frodas i dagens digitala värld. Kontakta oss för att lära dig mer!",
    keywords:
      "Webbutveckling, Apputveckling, Användarupplevelse, Användargränssnitt, Design.",
  },
  firstView: {
    headerTitle: "Vi skapar digitala lösningar i världsklass!",
    headerDesc:
      "Vilje Tech förnyar det befintliga, skalar det nya och utvecklar digitala lösningar som kommer att forma framtiden.",
    letsTalk: "LET’S TALK",
  },
  secondView: {
    mainDescription:
      "Vi som både är ingenjörer och kreatörer förstår de utmaningar som följer med att skapa digitala produkter som tillfredsställer både företags- och användarbehov.",
    one: "01",
    two: "02",
    three: "03",
    design: "Skapa",
    develop: "Utveckla",
    deliver: "Leverera",
    leftDesc:
      "Vi är en utvecklingsbyrå baserad i Helsingborg, Skåne, som skapar mobilapplikationer, webbplatser och skräddarsydda digitala lösningar som är utformade för att skala upp ditt företag.",
    moreAbout: "MER OM OSS",
  },
  thirdView: {
    imvi: "imvi",
    imviDesc:
      "Imvi strävar eget att förbättra skärpan på din syn och ge användaren en meningsfull användarupplevelse.",
    foundi: "Foundi",
    foundiDesc:
      'Foundi skapar den nya standarden för lösningar inom "lost and found".',
    bytboo: "Bytboo",
    bytbooDesc:
      "Bytboo hjälper dig att hitta den mest lämpliga fastighetsmäklaren för ditt område.",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. ",
    allProjects: "ALLA PROJEKT",
  },
  fourthView: {
    appDevelopment: "Apputveckling",
    webDevelopment: "Webbutveckling",
    uxUidesign: "UX & UI design",
    ourServices: "Våra tjänster",
    appDevelopmentDesc:
      "Våra apputvecklingstjänster är perfekta för modiga och kreativa företag som vill göra ett starkt intryck på den digitala arenan.",
    webDevelopmentDesc:
      "Med skräddarsydda webbutvecklingstjänster som omfattar allt från e-handelsplattformar till interaktiva webbapplikationer, hjälper vi ditt företag att blomstra online.",
    uxUidesignDesc:
      "Våra UI/UX-design-tjänster fokuserar på användbarhet, tillgänglighet och visuell attraktion för att skapa intuitiva och engagerande gränssnitt som ger användarna en positiv upplevelse och ökar engagemanget.",
    itemDesc:
      "Vi tillhandahåller banbrytande digitala lösningar som är anpassade för att möta ditt företags unika behov och hjälper dig att blomstra i dagens konkurrensutsatta landskap.",
    clickHere: " Klicka här för att lära dig mer",
  },
  fifthView: {
    testiMonial: "Kundcase",
    testiMonialDesc:
      "Ta reda på vad våra kunder tycker om våra tjänster. Upptäck hur vi har hjälpt företag som ditt att nå framgång genom våra digitala lösningar.",
    cardDesc:
      "This company has truly outdone themselves with the kayaking app they built for us in Sweden. It's the best app of its kind on the market, and it has revolutionized the way our customers experience kayaking. Thank you for your exceptional work!",
    name: "Emanuel",
    jobPosition: "CTO, Gokaya, Sweden",

    cardDesc1:
      "Vilje Tech built our complex eye coordination training app and streamlined development efficiently and qualitatively from MVP to a complete mobile application. Our unique solution had several specially built functions which Vilje Tech developed. They had clear and transparent communication and were highly involved throughout the project; we will continue working together. We highly recommend Vilje Tech.",
    name1: "Michael Malmqvist",
    jobPosition1: "CIO, IMVI Labs, Sweden",
    cardDesc2:
      "Vilja Tech was in charge of building Bytboo's frontend when we rebuild the product from scratch. I am very happy with their work. Continuous updates along the way, pragmatic thinking presenting solutions to any obstacles we faced. The end result was spotless. I can definitely recommend Vilja Tech to any company in the same situation as Bytboo.",
    name2: "Herman Treschow,",
    jobPosition2: "CEO Bytboo AB",

    header: "I samarbete med och stöd av",
  },
  blog: {
    description:
      "Upptäck de senaste nyheterna och insikterna från vårt expertteam inom utveckling och design. Få nya idéer och inspiration genom vårt innehåll och vår passion för design och utveckling.",
    title: "Artiklar",
  },
};
const FooterComponent = {
  title: "Låt oss skapa något nytt tillsammans!",
  contactUs: " Kontakta oss på:",
  copyright: "Copyright © Vilje Tech Labs - Alla rättigheter förbehållna.",
  address: "Adress: ",
  openInMaps: "Redaregatan (Öppna i kartor)",
  email: "E-post:",
  emailAddress: "hej@viljetech.com",
  call: "Telefon:",
  phoneNo: "+46(0)724430170",
};
const AboutPage = {
  seo: {
    title: "Vilje Tech |  About us",
    description:
      "Upptäck värderingarna hos Vilje Tech - från att främja kontinuerlig förbättring till att fira mångfald. Lär dig om vår tillvägagångssätt och vårt engagemang för excellens.",
    keywords: "Värderingar, Team, Vision, Design, Utveckling",
  },
  topView: {
    we: "Vi",
    design: "Skapar",
    develop: "Utvecklar",
    deliver: "Levererar",
    one: "/01",
    two: "/02",
    three: "/03",
    desc: "Vilje står för övertygelsen att där det finns en vilja, finns det en väg. Vi lever efter detta motto och strävar alltid efter att erbjuda våra kunder den bästa servicen genom regelbunden och transparent kommunikation samt en färdig produkt som överträffar alla förväntningar.",
    rightDesc1:
      "Vi skräddarsyr en projektstruktur som är unik för varje idé. Genom grundlig forskning för att förstå marknaden och användarna, bygger vi en robust och strategiskt motiverad grund för att skapa en digital lösning som möter dina behov på bästa sätt.",
    rightDesc2:
      "Vårt team av högt kvalificerade utvecklare har expertisen att skapa lösningar som sträcker sig från enkla webbplatser till avancerade blockchain-teknologier. Genom att följa vår anpassade utvecklingsprocess kan vi garantera en anpassningsbar och felfri produkt.",
    rightDesc3:
      "Vi levererar projekt som är polerade till perfektion. Men vårt engagemang slutar inte där. Vi erbjuder även underhåll och uppdatering av din webbplats eller applikation för att se till att de fortsätter att fungera smidigt och hålls uppdaterade enligt de senaste standarderna.",
  },
  middleView: {
    desc: "Vår vision är att förändra världen genom att utveckla idéer för framtiden. Vi vill skapa lösningar som utmanar status quo och som inspirerar till en mer hållbar framtid. Genom att utveckla innovativa verktyg och teknologier vill vi göra det möjligt för oss att tänka och agera för en bättre värld.",
    ourWork: "SEE OUR WORK",
  },
  valuesView: {
    valueText: "Ett starkt team behöver starka värderingar",
    weaim: "Vi är progressiva.",
    weaimDesc:
      "Vi tar med oss en unik synvinkel till varje projekt. Vi utmanar ständigt gränserna för vad som är möjligt och vårt fokus på framsteg och ständig förbättring gör det möjligt för oss att leverera exceptionella resultat till våra kunder.",
    wesupport: "Vi tror på synergi.",
    wesupportDesc:
      "Vi förstår kraften i samarbete och vår övertygelse om synergi driver oss att samla olika perspektiv och expertis för att skapa en dynamisk och magisk upplevelse för våra kunder.",
    wenever: "Vi hyllar mångfald.",
    weneverDesc:
      "I vårt arbete välkomnar vi mångfald i idéer, människor, kunskap och kulturer. Vår inkluderande inställning driver oss att söka nya perspektiv och skapa innovativa lösningar för våra kunder. Genom att omfamna mångfalden kan vi tillhandahålla ett bredare spektrum av expertis och leverera exceptionella resultat.",
    weleave: "Vi handlar med integritet.",
    weleaveDesc:
      "På vårt företag är integritet en drivkraft för varje handling vi vidtar och formar kvaliteten på allt vi skapar. Vårt engagemang för sanning och respekt säkerställer att vi alltid levererar exceptionella resultat till våra kunder.",
  },
  bottomView: {
    meetOuraTeam: "Meet our team",
    teamDesc:
      "Talent doesn't have borders. We travel long and far to find the best of the best. But we don’t have to go far to see that our team provides our clients with superb service, solutions and standards.",
    name: "David Olivas",
    jobPosition: "Software developer",
  },
};
const ProjectsPage = {
  seo: {
    title: "ViljeTech | Tidigare Projekt ",
    description:
      "Upptäck vår portfölj av framgångsrika webbutvecklings-, apputvecklings- och designprojekt. Våra kunders positiva feedback och våra prestationer talar för sig själva. ",
    keywords: "Projekt, Klienttestimonier, Portfölj, Kundcase",
  },
  one: "/01",
  two: "/02",
  three: "/03",
  four: "/04",
  desc: "Här presenterar vi en översikt av några av våra tidigare projekt",
  allProjects: "ALLA PROJEKT ",
  appDev: "APP-UTVECKLING",
  webDev: "WEBBUTVECKLING",
  uiUx: "UX & UI DESIGN",
  imvi: "imvi",
  Foundi: "Foundi",
  Bytboo: "Bytboo",
  Prizbee: "Prizbee",
};
const ServicesPage = {
  seo: {
    title: "Vilje Tech |  Våra Tjänster",
    description:
      "Transformera ditt företag med Viljes expertis inom webbutveckling, apputveckling och nyskapande lösningar. Öka din framgång med våra tjänster. Lär dig mer här.",
    keywords:
      "Tjänster, Webbutveckling, Apputveckling, Användarupplevelse, Användargränssnitt, Design",
  },
  firstView: {
    title: "Tjänster",
    one: "/01",
    two: "/02",
    three: "/03",
    desc: "Vi är ett fullservice-utvecklingsföretag baserat i Skandinavien som skapar webbplatser och mobilapplikationer som inte bara ser fantastiska ut, utan också fungerar exceptionellt väl. Med vår holistiska utvecklingstjänst har vi hjälpt både startups och etablerade företag att ta klivet in i en ny era av digitalisering.",
    appDev: "App-utveckling",
    appDevDesc:
      "Vi utvecklar och förnyar mobila applikationer för att inspirera användare, öka anpassningsbarheten och maximera intäkterna. Hos Vilje Tech samarbetar vi med ditt företag för att designa, utveckla och leverera mobilapplikationer som fokuserar på användarupplevelsen. Genom att använda en iterativ och grundlig tillvägagångssätt, inklusive strategi, forskning, UI/UX-design samt front- och back-end-utveckling, skapar vi lösningar som är byggda för att förändra världen.",
    webDev: "Webbutveckling",
    webDevDesc:
      "En bra webbplats etablerar ditt företag, kommunicerar din varumärkesidentitet och skapar kundrelationer. Hos Vilje Tech utvecklar vi fantastiska webbplatser som uppfyller dessa behov och mycket mer. Genom att förstå dina kunders behov och önskemål kan vi leverera webbplatser som stärker ditt varumärke, fokuserar på användarupplevelsen och fungerar felfritt.",
    uiUx: "UX & UI design",
    uiUxDesc:
      "Användarupplevelse (UX) och användargränssnitt (UI) design är avgörande faktorer för alla digitala produkter. Vår designprocess innefattar att förstå användarbehoven genom forskning, att skapa wireframes, högfidelity prototyper och att iterera baserat på feedback från användare och företag. Vi strävar efter att skapa helhetsupplevelser som tillfredsställer företagsbehoven och löser problem för slutanvändaren.",
  },
  secondView: {
    desc: "Vi skapar intelligenta digitala lösningar som ger startups och etablerade företag möjligheten att följa sina mål och uppnå framgång i den digitala eran.",
    seeOurWork: "Våra tidigare projekt ",
  },
  thirdView: {
    whyChoose: "Varför välja oss -",
    Transparent: "Transparent ",
    TransparentDesc:
      "Under hela projektet är vår främsta prioritet att ha tydlig och transparent kommunikation mellan oss och våra kunder.",
    Reliable: "Pålitliga",
    ReliableDesc:
      "Pålitlighet är kärnan i vår metod. Vi förstår att varje produkt är unik och skräddarsyr vår projektresa för att passa dina specifika behov och mål.",
    Explore: "Utforska",
    ExploreDesc:
      "Vi utforskar varje nytt projekt genom att genomföra kund-, varumärkes- och produktforskning för att kunna erbjuda våra kunder beprövade lösningar som är anpassade till deras unika marknad.",
    creative: "Kreativa",
    creativeDesc:
      "Vi är naturliga kreatörer som vill inspirera våra kunder att gå bortom det vanliga när det gäller design och innovation.",
  },
  fourthView: {
    clientLoveUs: "Kunder rekommenderar oss",
    clientDesc:
      "Ta inte bara vårt ord för det, läs vad våra kunder har att säga här.",
    cardDesc1:
      "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. ”",
    name: "David Olivas",
    jobPosition: "Software consultant, Company, Sweden",
  },
};
const ContactPage = {
  seo: {
    title: "ViljeTech | Kontakta oss",
    description:
      "Realisera din vision - Kontakta oss idag för att få liv i ditt projekt. Vi erbjuder skräddarsydda mjukvarulösningar för dina affärsbehov",
    keywords: "Kontakta oss",
  },
  title: "Kontakt",
  desc: "Har du en idé till ett projekt? Kontakta oss för att göra det till verklighet!",
  address: "Adress:",
  openInMaps: "Redaregatan (Öppna i kartor)",
  name: "Ida Alfonsi",
  jobPosition: "Communications Manager, Vilje Tech",
  name1: "Bharath Panyala",
  jobPosition1: "CEO, Vilje Tech",
  email: "Ida@viljetech.com",
  phone: "---",
  email1: "Bharath@viljetech.com",
  phone1: "072 44 30 170",
  dividerText: "eller skicka ett meddelande",
  yourName: "Namn",
  yourEmail: "E-post",
  tellus: "Berätta lite om ditt projekt ",
  sendBtn: "Skicka meddelande",
};
const imviPage = {
  seo: {
    title: "Vilje Tech | Imvi",
    description:
      "Upptäck hur ViljeTech hjälpte Imvi Labs att förverkliga deras vision om en banbrytande app. Lär dig om vår helhetslösning och experttjänster.",
    keywords:
      "Visionsträning, Ögonkoordination, Läsning, Patenterad lösning, Apputveckling, UX/UI-design, Front-end-utveckling",
  },
  heroSection: {
    heroHeader: "imvi Labs",
    desc: "Förbättra synen med hjälp av moderna tekniker",
    client: "Kund",
    clientName: "imvi Labs ",
    services: "Tjänster",
    servicesDesc: "UX &UI design, App-utveckling, och Webb-utveckling",
    links: "Länkar",
    linkText1: "Playstore - ",
    link1: "Link to Android app",
    link2: "Link to IOS app",
    linktext2: "Appstore - ",
    overView: "Överblick",
    overViewDesc:
      "Imvi kontaktade vårt team för att skapa en behandlingslösning för dyslexi som skulle vara tillgänglig för individer med dyslexi i alla åldrar och med olika förmågor. För att bygga denna lösning valde vi att använda React Native som front-end ramverk och Firebase som bakgrundsplattform.",
  },
  prblmSection: {
    headerText1: "Bakgrund",
    headerText2: "Lösning",
    headerText3: "Vad vi har gjort:",
    headerText1Desc:
      "Många är omedvetna om hur dålig ögonkoordination kan påverka energin, huvudvärken, koncentrationen, dubbelseendet och läshastigheten. Imvi Labs har därför utvecklat en patenterad lösning för att hjälpa människor förbättra sin syn och läsförmåga. Tillsammans med Vilje Tech har vi skapat en enkel men kraftfull app som gör det möjligt för användare att enkelt träna ögonkoordinationen.",
    headerText2Desc:
      "Imvi Labs sökte efter en app som kunde öka användarmotivationen för att fortsätta träna. Som en fullserviceleverantör inom webbutveckling erbjuder Vilje Tech en sömlös lösning för att utveckla både frontend och backend, samt designa en attraktiv UX och UI. Det är därför som vi var det perfekta valet för Imvi Labs.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App-utveckling",
      point1: {
        1: "Förståelse av användarbehov",
        2: "Wireframes",
        3: "High fidelity & Prototyper",
      },
      point2: {
        1: "Front-end",
        2: "Back-end",
        3: "Testning & lansering",
        4: "Underhåll",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    uiDesc:
      "Imvis lösning har designats med en användarvänlig gränssnitt som är optimerad för personer med dyslexi. Vi förstår att människor med dyslexi kan ha svårigheter med läsning och bearbetning av text, och vår lösning tar hänsyn till dessa utmaningar genom att erbjuda en lättanvänd och intuitiv gränssnitt.",
    one: "01",
    headerText: "Förståelse av användarbehov",
    desc1:
      "Vi förstår hur viktigt det är att ta hänsyn till användarbehoven när vi utvecklar webbapplikationer. Vårt team utförde en omfattande forskning för att förstå behoven hos personer med dyslexi innan vi påbörjade designprocessen. Genom att skapa användarpersonor och en användarresa fick vi en djupare förståelse för deras preferenser och utmaningar.",
    desc2:
      "Vi använder oss av användarcentrerade metoder som användarpersonor och användarresor för att säkerställa att vår lösning är optimerad för att möta användarnas behov och därmed förbättra deras användarupplevelse.",
    // desc3: "Entertainment integrations",
    // desc4: "Eye coordination calibrations ",
    // desc5: "Nudging",
    // desc6: "Child and parent connection ",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc1:
      "Under UX undersökningen skapade vi wireframes som skisser och digitala designkoncept för att utforska designidéer för imvis  dyslexibehandlingslösning. Dessa wireframes inkluderade olika designkoncept för huvudfunktionerna och möjliggjorde snabb iteration baserat på användarfeedback. Högfidelity-koncept skapades sedan för den slutliga designen och implementerades med hjälp av React Native. Sammanfattningsvis var wireframing en avgörande process för att utforska och förbättra designen av  lösningen.",
    desc2:
      "The wireframes for the dyslexia treatment solution included different design concepts for the main features of the solution, such as the dyslexia assessment tool, reading and writing exercises, and progress tracking. The wireframes allowed the team to quickly iterate and refine the design concepts based on user feedback.",
    desc3:
      "Once the wireframes were finalized, the team created high-fidelity mockups of the user interface, which included the visual design details and branding elements. The high-fidelity mockups were used to create the final design of the solution, which was implemented using React Native as the front-end framework. ",
    desc4:
      "Overall, the wireframing process was a crucial step in the UX design process for the dyslexia treatment solution. It allowed the team to quickly explore different design concepts, iterate based on user feedback, and refine the final design of the solution.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc1:
      "After the wireframes were finalized and tested with dyslexic individuals, the team moved on to creating high-fidelity screens for the solution. High-fidelity screens are detailed digital designs that include the visual design elements such as colors, typography, icons, and images. ",
    desc2:
      "The high-fidelity screens for the dyslexia treatment solution were created using design software such as Sketch or Figma. The screens included the final layout, typography, color scheme, and branding elements for the solution.",
    desc3:
      "The high-fidelity screens were used to create a prototype of the solution that closely resembled the final product. This prototype was tested with dyslexic individuals to ensure that the solution was user-friendly and effective in treating dyslexia.",
    desc4:
      "The high-fidelity screens were also used as a reference for the development team, who used React Native as the front-end framework to create the final product. The screens provided a clear visual representation of the design elements and functionality of the solution, making it easier for the development team to build the solution accurately.",
    desc5:
      "Overall, the high-fidelity screens were an important step in the UX design process for the dyslexia treatment solution. They provided a clear representation of the final product, helped to ensure the solution was user-friendly and effective, and made the development process smoother and more accurate.",
  },
  appDevSection: {
    smallText: "(App-utveckling)",
    one: "01",
    appDesc:
      "Vi använde React Native för front-end och Firebase för back-end utvecklingen av lösningen. Front-end optimerades för dyslektiska användare, medan back-end förse användare med realtidsuppdateringar och säkerhetsåtgärder.",
    headerText: "Front-end",
    desc1:
      "Vi designade användargränssnittet för att vara enkelt och intuitivt, med tydliga instruktioner och återkoppling. Teamet implementerade också responsiv design, vilket säkerställde att lösningen kunde användas på olika enheter och skärmstorlekar.",
    desc2:
      "The user interface was designed to be simple and intuitive, with clear instructions and feedback provided at each step. The team also implemented responsive design, ensuring that the solution could be used on a variety of devices and screen sizes.",
  },
  appDevSection2: {
    smallText: "(App-utveckling)",
    one: "02",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Back-end",
    desc1:
      "Firebase användes för att lagra användardata och ge realtidsuppdateringar till front-end. Vi implementerade också molnfunktioner och åtkomstkontroll för säkerhetsåtgärder.",
    desc2:
      "The back-end was responsible for implementing security and access control, ensuring that only authorized users could access and modify data. The team also implemented cloud functions, allowing them to run server-side code in response to certain events, such as user sign-ups or data updates.",
    desc3:
      "The database was a crucial component of the dyslexia treatment solution for IMVI, as it stored all user data and provided real-time updates to the front-end. The team used Firebase Realtime Database, a NoSQL database that allows for the synchronization of data between the front-end and back-end in real-time",
  },
  appDevSection3: {
    smallText: "(App-utveckling)",
    one: "03",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Testning och lansering",
    desc1:
      "Lösningen genomgick omfattande testning före lansering, inklusive användbarhetstestning med personer med dyslexi. Appen gjordes tillgänglig globalt, och vi fortsatte att övervaka och göra förbättringar baserat på användarfeedback.",
    desc2:
      "Once testing was complete, the solution was deployed to the app stores, where it was made available to dyslexic individuals worldwide. The team continued to monitor the solution and make updates and improvements as needed, based on user feedback.",
  },
  appDevSection4: {
    smallText: "(App-utveckling)",
    one: "04",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Underhåll",
    desc1:
      "Vi använde moderna och standardiserade teknologier för lösningen för att säkerställa att den var lätt underhållbar. Vi genomförde också regelbundna underhållskontroller och uppdateringar för att åtgärda eventuella potentiella problem eller buggar.",
    desc2:
      "The team ensured that the solution was easily maintainable by using modern and standardized technologies, documenting the code, and creating user guides for any future developers or users. They also implemented a system for monitoring and addressing any potential issues or bugs that may arise in the future. Regular maintenance checks and updates were conducted to ensure that the solution remained functional and effective for dyslexic users.",
  },
  technologiesSection: {
    technologiesHeaderText: "Teknologier som användes under utvecklingen -",
    technologies1: "React Native",
    technologies2: "Firebase",
    technologies3: " Figma ",
  },
  resultsSection: {
    headerText: "Resultatet",
    desc: "Tack vare vårt samarbete med imvi Labs har vi skapat en app som har förbättrat syn- och läsförmågan hos många människor. Genom omfattande forskning och skisser för att förstå behoven hos personer med dyslexi, kunde vi utveckla en användarvänlig och intuitiv app som är anpassad för personer med dyslexi. Vi testade appen grundligt och gjorde den tillgänglig för användare över hela världen. Resultatet blev en app som är både rolig och lärorik, och som hjälper människor att se och läsa bättre. Vårt samarbete med imvi Labs fortsätter när vi arbetar med att vidareutveckla appen och skapa en så bra användarupplevelse som möjligt.",
    cardDesc:
      " Vilje Tech byggde vår komplexa ögonkoordinationsträningsapp och effektiviserade utvecklingen på ett kvalitativt sätt från MVP till en komplett mobilapplikation. Vår unika lösning hade flera speciellt utvecklade funktioner som Vilje Tech utvecklade. De hade tydlig och transparent kommunikation och var mycket involverade i hela projektet; vi kommer att fortsätta arbeta tillsammans. Vi rekommenderar starkt Vilje Tech.",
    name: "Michael Malmqvist",
    jobposition: "CIO, IMVI Labs, Sverige",
  },
};
const foundiPage = {
  seo: {
    title: "Vilje Tech | Foundi",
    description:
      "Upptäck hur ViljeTech hjälpte Foundi att skapa en unik lösning på det vanliga problemet med förlorade föremål. Läs mer om projektet här.",
    keywords:
      "Förlorade föremål, QR-koder, Varumärkesidentitet, App-utveckling, Webbdesign, Användarupplevelse",
  },
  heroSection: {
    heroHeader: "Foundi",
    desc: "Creating the new standard for lost and found solutions.",
    client: "Kund",
    clientName: "Teamet bakom Foundi",
    services: "Tjänster",
    servicesDesc: "UX &UI design, App-utveckling, och Webb-utveckling",
    links: "Länkar",
    linkText1: "Hemsida - ",
    link1: "website Link",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Överblick",
    overViewDesc:
      "Översikt Foundi är en QR-kodbaserad 'lost and found' lösning som prioriterar användarens integritet samtidigt som den erbjuder en användarvänlig upplevelse. Projektet omfattar forskning, idégenerering, design, utveckling och underhåll för att skapa en pålitlig och effektiv lösning för förlorade och återfunna föremål.",
  },
  prblmSection: {
    headerText1: "Bakgrund",
    headerText2: "Lösning",
    headerText3: "Vad vi har gjort:",
    headerText1Desc:
      "Har du nyligen tappat bort något? Det är olyckligt men vanligt att förlora saker. Foundis studier visar att 96% av den svenska befolkningen tappar bort minst en värdefull sak om året. Det är stressigt och irriterande, men det finns en enkel lösning. Tillsammans med Foundi-teamet utforskade  vi den mest unika och effektiva lösningen. Resultatet blev en QR-kodbaserad lösning som kopplar användaren till deras ägodelar.",
    headerText2Desc:
      "Foundi behövde ett team för att hjälpa dem att förverkliga sin vision från början till slut. Deras idé inspirerade oss på Vilje Tech. Projektet började med varumärkesidentitet och skapandet av appens och webbplatsens arkitektur och wireframes. För att maximera räckvidden och optimera användarupplevelsen arbetade våra copywriters och designers tillsammans för att göra Foundi till en unik upplevelse. Vårt samarbete pågår fortfarande, och vi kommer att följa Foundi vid lanseringen och ser fram emot att se dem växa.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App-utveckling ",
      point1: {
        1: "Förståelse av användarbehov",
        2: "Wireframes",
        3: "High fidelity & Prototyper",
      },
      point2: {
        1: "Front-end",
        2: "Back-end",
        3: "Utveckling",
        4: "Underhåll",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    uiuxDesc:
      "Designprocessen för Foundi involverade att förstå användarbehoven, skapa wireframes med både låg och hög detaljnivå samt interaktiva prototyper för att förbättra den slutgiltiga designen. Resultatet är en användarvänlig och tillgänglig 'lost and found'-lösning som håller användarna och deras föremål säkra och skyddade.",
    one: "01",
    headerText: "Att förstå användarbehoven",
    desc1:
      "Designprocessen för Foundi började med att förstå behoven hos målgruppen. Vårt team genomförde forskning och analys för att samla in insikter om hur användare vanligtvis hanterar förlorade och återfunna situationer och hur de skulle föredra att bli kontaktade om de hittar en förlorad sak.",
    desc2: "Branding that existes and captures attention from the user ",
    desc3:
      "For a new brand on the market it can be a challange to capture attention and stand out from other brands. With Foundi the client wanted a unique and colourful approach that would be eye catching along with a minimal and user friendly approach. ",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc1:
      "När vi hade en gedigen förståelse för användarnas behov, skapade vi Wireframes med både låg och hög detaljnivå. Dessa Wireframes hjälpte oss att visualisera hur användarna skulle ta sig igenom tjänsten och säkerställa att lösningen verkligen uppfyllde användarpersonans behov. På så vis kunde vi i ett tidigt skede identifiera eventuella problem och göra nödvändiga förbättringar innan vi gick vidare till mer omfattande utveckling.",
    desc2: "High security to keep both users inetegrity intact",
    desc3: "Creating an insentive to give back ",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyper",
    desc1:
      "För att utforska olika designalternativ skapade vi detaljerade Wireframes i Figma. Genom att använda denna teknik kunde vi få en detaljerad bild av hur lösningen skulle se ut och fungera. Därefter skapade vi interaktiva prototyper för att simulera användarupplevelsen och samla in feedback. Dessa prototyper gjorde det möjligt för oss att testa lösningen på ett tidigt stadium och göra nödvändiga förbättringar innan vi gick vidare till mer omfattande utveckling.",
    desc2:
      "Finally, we created interactive prototypes that allowed us to simulate the user experience and gather feedback from users through user testing. These prototypes helped us to refine the design and ensure that the solution was user-friendly and accessible. ",
    desc3: "Registration process that is easy and smart - just like Foundi  ",
  },
  appDevSection: {
    smallText: "(App-utveckling)",
    appDevDesc:
      "Foundi is a lost and found solution that was developed with a focus on user privacy and experience. The app was built with React Native for the frontend and Node.js and MongoDB for the backend. The development process involved creating new features and improving existing ones to ensure that Foundi was a reliable and efficient solution for users. The frontend and backend teams worked closely together to ensure seamless integration, and the testing team made sure that the app met all requirements.",
    one: "01",
    headerText: "Front-end",
    desc: "Frontend-delen av Foundi utvecklades med hjälp av React Native och innehåller komponenter som ger en smidig användarupplevelse och responsivitet på alla enheter. Teamet arbetade nära backend-utvecklarna för att säkerställa en sömlös integration mellan frontend och backend. På så vis kunde vi skapa en enhetlig och robust plattform som mötte användarnas behov och fungerade smidigt på alla enheter och plattformar.",
  },
  appDevSection2: {
    smallText: "(App-utveckling)",
    appDevDesc: "",
    one: "02",
    headerText: "Back-end",
    desc: "Foundis backend-delen är byggd med Node.js och MongoDB och inkluderar API:er för användarregistrering, QR-kodsgenerering och säker lagring av användarinformation. Bakom backend-utvecklingen står ett team som samarbetade nära med frontend-teamet för att skapa en sömlös funktionalitet för appen. På så sätt kunde vi skapa en robust och säker plattform som mötte användarnas behov och erbjöd en smidig användarupplevelse.",
  },
  appDevSection3: {
    smallText: "(App-utveckling)",
    appDevDesc: "",
    one: "03",
    headerText: "Utveckling",
    desc: "Foundis utvecklingsprocess omfattade att sätta upp en utvecklingsmiljö och projektstruktur, samt att skapa nya funktioner och förbättra befintliga. Utvecklingsteamet arbetade på att implementera nya funktioner såsom QR-kodsgenerering, användarregistrering och rapportering av borttappade föremål. De samarbetade nära med testningsteamet för att säkerställa att appen uppfyllde kraven och fungerade som avsett. Resultatet blev en robust och användarvänlig plattform som hjälper användarna att spåra och återfå borttappade föremål på ett smidigt sätt.",
  },
  appDevSection4: {
    smallText: "(App-utveckling)",
    appDevDesc: "",
    one: "04",
    headerText: "Underhåll",
    desc: "Underhåll är avgörande inom apputveckling för att säkerställa att appen alltid är uppdaterad med de senaste säkerhetsprotokollen och buggfixarna. Foundis underhållsteam samarbetade nära med utvecklingsteamet för att hantera problem och förbättra appen med nya funktioner och förbättringar. På så sätt kunde Foundi fortsätta att erbjuda en högkvalitativ och konkurrenskraftig app som uppfyllde användarnas krav.",
  },
  technologiesSection: {
    technologiesHeaderText: "Teknologier som användes under utvecklingen -",
    technologies1: "React native,",
    technologies2: " MongoDB",
    technologies3: "NodeJS",
    technologies4: "AWS ",
  },
  resultsSection: {
    headerText: "Pågående",
    desc: "Vi har haft en pågående samarbete med Foundi och ser fram emot deras lansering. Vi är glada över möjligheten att växa tillsammans med dem längs vägen och hjälpa till att göra deras lansering så smidig och framgångsrik som möjligt. Med vår erfarenhet och kompetens inom området är vi övertygade om att vi kan bidra till Foundis framgång på marknaden och fortsätta att bygga på vår relation på ett positivt sätt.",
    cardDesc:
      "Vi är super tacksamma för Vilje Techs engagemang i vårt projekt. De gav oss konstant tips och råd om hur vi kunde förbättra och finslipa vår idè. Resultatet blev så mycket bättre än vi kunde tänka oss, nu skapar vi den nya standarden för 'lost and found' lösningar!",
    name: "Foundi Team",
    jobposition: "Helsingborg, Sweden",
  },
};
const bytbooPage = {
  seo: {
    title: "Vilje Tech | Bytboo",
    description:
      "Utforska försäljningsstatistik och få insikter på bostadsmarknaden. Bytboo hjälper dig att jämföra mäklare. Kontakta Vilje Tech för att förverkliga din vision.",
    keywords:
      "Fastigheter, Transparens, Mäklare, Försäljningsstatistik, Ekonomiska insikter, Webbdesign, Dynamiska bilder, Datahantering, Användarupplevelse.",
  },
  heroSection: {
    heroHeader: "Bytboo",
    desc: "Hjälper dig att hitta rätt fastighetsmäklare.",
    client: "Kund",
    clientName: "Teamet bakom Byboo",
    services: "Tjänster",
    servicesDesc: "Webb-utveckling",
    links: "Länkar",
    linkText1: "Hemsida - ",
    link1: "website Link",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Överblick",
    overViewDesc:
      "Bytboo är ett fastighetsvärderingsprojekt i Sverige som gör det enkelt och pålitligt för användare att uppskatta värdet på sin egendom. Projektet involverade flera utvecklingsprocesser, inklusive frontend-utveckling, arkitektur, testning och leverans, för att säkerställa att användarna får den bästa möjliga upplevelsen. Bytboo har arbetat hårt för att utveckla en enkel och effektiv värderingsprocess som är tillgänglig för alla och som ger rättvisa och noggranna värderingar. Vi är stolta över att vara en del av detta projekt och ser fram emot att fortsätta arbeta tillsammans för att förbättra och utöka tjänsterna ytterligare.",
  },
  prblmSection: {
    headerText1: "Bakgrund",
    headerText2: "Lösning",
    headerText3: "Vad vi har gjort:",
    headerText1Desc:
      "Bytboo grundades med insikten att fastighetsbranschen lider av bristande transparens. Efter en omfattande undersökning blev det tydligt att valet av fastighetsmäklare kan påverka priset vid försäljning av en bostad. Detta innebär att det är viktigt att göra ett informerat val av mäklare för att få det bästa möjliga resultatet. Genom att använda Bytboo kan du öka din transparens i fastighetsaffärer och ta kontroll över dina försäljningsbeslut.",
    headerText2Desc:
      "Lösning Lösningen var att skapa en tjänst där användare kan jämföra fastighetsmäklare, utforska försäljningsstatistik och få ekonomiska insikter inom bostadsmarknaden. Bytboo kontaktade Vilje Tech för att utveckla sin vision. De ville ha en design med komplexa animationer med klickhändelser, datahantering och dynamiska bilder med olika färger. Utmaningar som vi löste. Resultatet blev en webbplats som ger en sömlös upplevelse för användaren samtidigt som den uppfyller höga krav på funktion och kvalitet.",
    headerText3Desc: {
      mainText1: "/01 - Webbutveckling",
      mainText2: "/01 - Webbutveckling",
      point1: {
        1: "Understanding user needs",
        2: "Wireframes",
        3: "High fidelity & Prototyping",
      },
      point2: {
        1: "Frontend-utveckling",
        2: "Arkitektur",
        3: "Testning",
        4: "Lansering",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    one: "01",
    headerText: "Understanding user needs",
    desc: "Understanding user needs",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  appDevSection: {
    smallText: "(Webbutveckling)",
    appDevDesc:
      "The development process for Bytboo involved modern frontend technologies, microservices architecture, testing, and deployment. The frontend development focused on creating a user-friendly interface using React and TypeScript. The microservices architecture ensured scalability and maintainability of the system. The testing process ensured the security, reliability, and efficiency of the app, while the deployment process ensured the app was available to users. Overall, the development process for Bytboo was comprehensive and ensured a reliable and efficient real estate estimation app for users in Sweden.",
    one: "01",
    headerText: "Front-end utveckling",
    desc: "Teamet fokuserade på att skapa en användarvänlig gränssnitt för att mata in fastighetsinformation och få en korrekt uppskattning. Moderna frontend-teknologier som React och TypeScript användes för att skapa en enkel och intuitiv design som fungerade responsivt på både desktop och mobil. Teamet implementerade också funktioner som autosuggestion och realtidsuppdatering.",
  },
  appDevSection2: {
    smallText: "(Webbutveckling)",
    one: "02",
    headerText: "Arkitektur",
    desc: "Bytboos arkitektur var designad för skalbarhet och underhållbarhet, med hjälp av mikrotjänster för att skapa ett modulärt system. En RESTful API-arkitektur tillät sömlös kommunikation mellan frontend och backend.",
  },
  appDevSection3: {
    smallText: "(Webbutveckling)",
    one: "03",
    headerText: "Testning",
    desc: "För att säkerställa appens kvalitet användes olika testmetodologier som enhetstestning, integrationstestning, slut-till-slut-testning och säkerhetstestning. Appen implementerades snabbt och effektivt genom att använda tekniker som kontinuerlig integration och kontinuerlig leverans. Ett övervakningssystem möjliggjorde snabb åtgärd av problem.",
  },
  appDevSection4: {
    smallText: "(Webbutveckling)",
    one: "04",
    headerText: "Lansering",
    desc: "Leverans Leveransprocessen för Bytboo innebar att appen deployades till en produktionsmiljö och säkerställde att den var tillgänglig för användarna. Teamet använde olika tekniker för att deploya, som kontinuerlig integration och kontinuerlig leverans, för att säkerställa att appen snabbt och effektivt deployades. De implementerade också ett övervakningssystem som möjliggjorde övervakning av appens prestanda och snabb lösning av eventuella problem som uppstod. Leveransprocessen var viktig eftersom den säkerställde att appen var tillgänglig för användare och uppfyllde deras behov.Slutsatsen är att utvecklingsprocessen för Bytboo innefattade olika processer, inklusive frontend-utveckling, arkitektur, testning och leverans. Teamet använde moderna teknologier och metoder för att skapa en tillförlitlig och effektiv fastighetsvärderingsapp som uppfyllde användarnas behov i Sverige. Projektet levererades framgångsrikt och har blivit en betrodd lösning för fastighetsvärdering i landet.",
  },
  technologiesSection: {
    technologiesHeaderText: "Teknologier som användes under utvecklingen -",
    technologies1: "React Js",
    technologies2: "Figma",
    technologies3: "Lorem ipsum",
  },
  resultsSection: {
    headerText: "Resultat",
    desc: "Vi levererade en ren och engagerande slutprodukt som perfekt paketerade Bytboos vision. Vi är stolta över att vara en del av en mer transparent fastighetsmarknad.",
    cardDesc:
      "Vilja Tech var ansvarig för att bygga Bytboo's frontend när vi byggde om produkten från grunden. Slutresultatet var felfritt. Jag kan definitivt rekommendera Vilja Tech till vilket företag som helst i samma situation som Bytboo.",
    name: "Herman Treschow",
    jobposition: "CEO Bytboo AB",
  },
};
const prizbeePage = {
  seo: {
    title: "ViljeTech | Prizbee",
    description:
      "Upptäck hur Prizbees app håller barnen motiverade att lära sig. Vilje Tech utvecklade en lekfull, användarorienterad frontend. Lär dig mer här.",
    keywords:
      "Gamification, Agil utveckling, Internationellt team, Föräldra- och barnversioner, Användarorienterad design",
  },
  heroSection: {
    heroHeader: "Prizbe",
    client: "Client",
    clientName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit - July 2022",
    services: "Services",
    servicesDesc: "UX & UI design, App development, and Web development",
    links: "Links",
    linkText1: "Playstore - ",
    link1: "Lorem ipsum dolor",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Overview",
    overViewDesc:
      "Overview - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  prblmSection: {
    headerText1: "Problem / Background",
    headerText2: "Solution",
    headerText3: "What we’ve done",
    headerText1Desc:
      "Motivating children to learn can be challenging, especially in a world of digital distractions. Turn a challenge into an opportunity; that is what Prizbee did. Through their solutions, children get awarded for completing educational challenges with in-game tokens. Vilje Tech developed a playful and user-oriented front end to turn vision into reality",
    headerText2Desc:
      "The Prizbee team needed an agile, adaptive and communication-oriented team to develop their app. The requirement was to make a front end for an explicit MVP while working with a very international team of people from Ukraine and France. Our team provided strategic planning and focused on transparency and communication. Together our international team created an app with both a parent and child version that combined education, playfulness and integrations with well-known games.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App development",
      point1: {
        1: "Strategy and architecture",
        2: "UX & UI",
        3: "Tech Development",
      },
      point2: {
        1: " Maintenance ",
        2: "Back end",
        3: "Adminpanel",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    one: "01",
    headerText: "Understanding user needs",
    desc: "Understanding user needs",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  appDevSection: {
    smallText: "(App development)",
    one: "01",
    headerText: "App development",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  technologiesSection: {
    technologiesHeaderText: "Technologies used while developing -",
    technologies1: "Lorem ipsum",
    technologies2: "Lorem ipsum",
    technologies3: "Lorem ipsum",
  },
  resultsSection: {
    headerText: "Results",
    desc: "Our collaboration with Prizbe is still ongoing and we are looking forward to be apart their journey and launch.  ",
    cardDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere.”",
    name: "David Olivas",
    jobposition: "Software consultant, Company, Sweden",
  },
};
const privacyPage = {
  headerText: "Privacy Policy",
  headerContent: `We want to provide you with a safe and secure experience when visiting our website. You should never have to worry about your safety or privacy when using Vilje Tech solutions. To learn more about Vilje Tech's privacy policy, please see below.`,
  content1: `Data controller`,
  content2: `Vilje Tech AB (“Vilje Tech”, “we”, “us”, “our”), is a data
  controller of personal data (“Data”). At Vilje Tech, we take your
  privacy very seriously and ensure that all applicable data privacy
  regulations are observed. In this section, you can learn about how
  we process your Data when you use:`,
  content3: `- corresponding products and services offered by Vilje Tech
  (“Service”, “Services”, “Solutions”).`,
  content4: `This Policy does not apply to third-party applications, websites,
  products, services, or platforms that may be accessed through links
  that we provide to you. These third parties are owned and operated
  independently from us and have their own separate privacy and
  data-collection practices. Any personal data that you provide to
  these third parties will be governed by the third party’s own
  privacy policy. We cannot accept liability for the actions or
  policies of these independent third parties, and we are not
  responsible for the content or privacy practices of such sites.`,
  content5: `About Vilje Tech`,
  content6: `Vilje Tech is a development company that specializes in websites and
  applications. As a development company, Vilje Tech is committed to
  providing customized solutions for our clients' digital needs. The
  team of developers has extensive experience in a variety of
  industries, from e-commerce and storage to healthcare and education.
  At Vilje Tech we specialize in UX and UI design when working with
  clients to ensure the quality of user experience and engagement.`,
  content7: `Categories of personal data`,
  content8: ` Depending on your interaction with Vilje Tech, we may process
  different categories of your Data. When using our Site, the
  categories of Data that we may process include:`,
  content9: `- Name`,
  content10: `- Email address`,
  content11: `- Submitted video data`,
  content12: `- Device-specific data (e.g. hardware model, operating system, and
    crash data)`,
  content13: `- Telephone number`,
  content14: `- Feedback, support, and error submissions`,
  content15: `- Cookie and marketing preferences`,
  content16: `Additionally, when submitting feedback, support, or error forms, you
  may be asked to provide your name, contact information, feedback or
  error message, and the identification number of the product in
  question.`,
  content17: `This Data is collected when you register or use our Site, or
  Solutions, opt-in to cookies, opt-in to receiving marketing
  material, submit feedback or booking forms, from public records, and
  through direct correspondence between Vilje Tech and yourself.`,
  content18: `Purpose`,
  content19: `Vilje Tech processes your Data in order to be able to provide to you
  in completion our services in accordance with your wishes, to
  improve and expand our Solutions, for insight and research purposes,
  to communicate with you, to screen for potential risk or fraud, and,
  when in line with the preferences that you have indicated, to
  provide you with advertising or promotional material.`,
  content20: `As we develop insights and research, we ensure that data is
  aggregated in order to prevent any individual from being identified,
  in effect anonymising the data set. Our legal bases for processing
  are:`,
  content21: `- Based on your consent,`,
  content22: `- Necessary for the performance of contract,`,
  content23: `- Due to other legal obligations, or`,
  content24: `- Legitimate interest.`,
  content25: `Data retention`,
  content26: `Unless required by law, Data is retained for up to 24 months after
  your last interaction with Vilje Tech, after which it is erased or
  anonymised. Interaction with Vilje Tech includes, for example, using
  our Site, or Services, or opening marketing communications from us.`,
  content27: `You can at any time opt-out of receiving marketing communications or
  close your account by following on-screen instructions, or by
  contacting us hej@viljetech.com`,
  content28: `Employment applications`,
  content29: `For both published vacancies and open applications, our recruitment
  process may involve the processing of the following categories of
  Data of our applicants: name; postal address; email address;
  telephone number; government-issued identification; references and
  CVs. This Data is retained for up to 5 years after the application
  date, or for as long as we have a legitimate business need for it.
  This Data is collected from public records, third-party
  recommendations and referrals, and through direct correspondence
  between Vilje Tech and yourself.`,
  content30: `Third parties and Countries`,
  content31: `We may share your Data with affiliated companies with which we have
  partnered in order to provide to you a greater set of services, and
  with trusted third-party service providers as necessary for them to
  perform services on our behalf, such as:`,
  content32: `- Cloud data storage`,
  content33: `- Processing payments`,
  content34: `- Product development`,
  content35: `- Application deployment and publication`,
  content36: `- Cybersecurity`,
  content37: `- Market research`,
  content38: `- Serving advertisements and conducting surveys`,
  content39: `- Communicating with you, such as by way of email or survey delivery`,
  content40: `- Legal consultancy`,
  content41: `- Customer relationship management, including social media`,
  content42: `- Support and related services`,
  content43: `As often as is appropriate and practical, third parties are only
  provided with aggregated data (through which individuals cannot be
  identified) and are obligated not to disclose or use the information
  for any purpose other than those listed above.`,
  content44: `Vilje Tech uses servers located within the EU; however, if we
  transfer Data outside of the EU, we check that the third country
  meets the requirements of applicable data privacy legislation and
  make the necessary contractual arrangements to ensure third-party
  compliance. Vilje Tech does not sell your personal data.`,
  content45: `For a full list of our third parties, please send your request to
  hej@viljetech.com`,
  content46: `Cookies`,
  content47: `A cookie is a small text file that the website or application that
  you are visiting requests to save on your device. They are widely
  used in order to make websites and applications work, or work more
  efficiently, as well as to provide information to the owners of the
  site or app.`,
  content48: `Vilje Tech only uses essential cookies, those that allow our Site to
  work – as such, these cannot be disabled.`,
  content49: `Should Vilje Tech ever update its cookie collection for other
  processing purposes, we will first request your consent.`,
  content50: `PTS, the regulatory authority in the field, provides additional
  information about cookies on its website, pts.se`,
  content51: `For information on how to manage your browser’s cookie settings,
  follow these links:`,
  content52: `- Chrome`,
  content53: `- Internet Explorer`,
  content54: `- Firefox`,
  content55: `- Safari`,
  content56: `Cybersecurity`,
  content57: ` Vilje Tech protects your Data using technical and organisational
  security measures. In order to prevent unauthorised access and
  ensure data accuracy, we have implemented strict guidelines for the
  organisation regarding the processing of personal data, including
  the use of two-factor authentication (2FA), SSL encryption, and
  trusted third-party cloud data storage, with servers located within
  the EU.`,
  content58: `Your rights`,
  content59: `Under the EU General Data Protection Regulation (EU GDPR), you have
  the right to transparency in how your Data is processed. This means
  that you can at any time:`,
  content60: `- Request an extract of what Data we process about you,`,
  content61: `- Request that we correct any Data that is incorrect,`,
  content62: `- Request that we erase your Data,`,
  content63: `- Request that we limit how your Data is used,`,
  content64: `- Object to the processing of your Data that we deem legitimate,`,
  content65: `- Request to have your Data transferred from Vilje Tech’s system to
  another body, i.e. data portability, and`,
  content66: `- Revoke previously granted consent to any of our personal data
  processes.`,
  content67: `If you would like to request any of the above, please contact us
  hej@viljetech.com`,
  content68: `You are entitled to contact Sweden’s data protection authority,
  Integritetsskyddsmyndigheten (IMY), to file a complaint if you are
  dissatisfied with how we process your Data.`,
  content69: `More information about the EU GDPR can be found at imy.se
  andec.europa.eu`,
  content70: `Changes to Vilje Tech’s privacy policy`,
  content71: `We may periodically update this Policy in order to accurately
  reflect changes to operations, or for regulatory reasons. When this
  happens, we will inform you by reasonable correspondence - for
  example, by email or on our Site.`,
  content72: `Contact information`,
  content73: `If you have questions about how Vilje Tech processes your personal
  data, or the contents of this Policy, you can at any time
  hej@viljetech.com `,
};
export const SwedishStrings = {
  common,
  HomePage,
  FooterComponent,
  AboutPage,
  ProjectsPage,
  ServicesPage,
  ContactPage,
  imviPage,
  foundiPage,
  bytbooPage,
  prizbeePage,
  privacyPage,
};
