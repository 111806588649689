const common = {
  Home: "START",
  about: "ÜBER UNS",
  projects: "PROJEKTE",
  services: "DIENSTLEISTUNGEN",
  contacts: "KONTAKT",
  close: "SCHLIEßEN",
  english: "English",
  sweden: "Swedish",
  dutch: "German",
};
const HomePage = {
  firstView: {
    headerTitle: "Wir bauen erstklassige digitale Lösungen!",
    headerDesc:
      "Vilje Tech reinvents the existing, scales the new, and develops digital solutions that will shape the future.",
    letsTalk: "REDEN MIT UNS",
  },
  secondView: {
    mainDescription:
      "Als Ingenieure und Kreative verstehen wir die Herausforderungen bei der Herstellung digitaler Produkte, die sowohl die Bedürfnisse von Unternehmen als auch von Benutzern erfüllen.",
    one: "01",
    two: "02",
    three: "03",
    design: "Design",
    develop: "Entwickeln",
    deliver: "Liefern",
    leftDesc:
      "Wir sind eine Entwicklungsagentur mit Sitz in Malmö, Skåne, Schweden, die mobile Anwendungen, Websites und kundenspezifische digitale Lösungen entwickelt, um Ihr Unternehmen zu skalieren.",
    moreAbout: "MEHR ÜBER UNS",
  },
  thirdView: {
    imvi: "imvi",
    imviDesc:
      "Verbessern visionen und sich gleichzeitig auf die bauen einer wirkungsvollen Benutzererfahrung konzentrieren.",
    foundi: "Foundi",
    foundiDesc:
      "Entwickeln des neuen Standards für verlorene und gefundene Lösungen.",
    bytboo: "Bytboo",
    bytbooDesc:
      "Helfen Ihnen, den besten Vermittler für Ihre Region zu finden.",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. ",
    allProjects: "ALLE PROJEKTE",
  },
  fourthView: {
    appDevelopment: "App-Entwicklungen",
    webDevelopment: "Web Entwicklungen",
    uxUidesign: "UX- und UI-Design",
    ourServices: "UNSERE DIENSTLEISTUNGEN",
    appDevelopmentDesc:
      "Our app development services are the perfect fit for bold and creative companies looking to make a statement in the digital space",
    webDevelopmentDesc:
      "From e-commerce platforms to interactive web applications, our web development services are tailored to meet your specific needs and help your business thrive online.",
    uxUidesignDesc:
      "Our UI/UX design services prioritize usability, accessibility, and visual appeal to create intuitive and engaging interfaces that delight users and drive engagement.",
    itemDesc:
      "We provide cutting-edge digital solutions tailored to meet the unique needs of your business and help you thrive in today's competitive landscape.",
    clickHere: " Click to learn more",
  },
  fifthView: {
    testiMonial: "Testimonials",
    testiMonialDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere.",
    cardDesc:
      "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. ”",
    name: "Emanuel",
    jobPosition: "CTO, Gokaya, Sweden",
    cardDesc1:
      "Vilje Tech hat unsere komplexe Augenkoordinationstrainings-App entwickelt und die Entwicklung effizient und qualitativ vom MVP bis zu einer vollständigen mobilen Anwendung optimiert. Unsere einzigartige Lösung hatte mehrere speziell eingebaute Funktionen, die Vilje Tech entwickelt hat. Sie hatten eine klare und transparente Kommunikation und waren während des gesamten Projekts stark involviert; wir werden weiter zusammenarbeiten. Wir empfehlen Vilje Tech.",

    name1: "David Olivas",
    jobPosition1: "Software consultant, Company, Sweden",
    cardDesc2:
      "Vilja Tech war für den Aufbau des Frontends von Bytboo verantwortlich, als wir das Produkt von Grund auf neu erstellten. Ich bin sehr zufrieden mit ihrer Arbeit. Kontinuierliche Updates auf dem Weg, pragmatisches Denken, das Lösungen für alle Hindernisse präsentiert, mit denen wir konfrontiert waren. Das Endergebnis war makellos. Ich kann Vilja Tech auf jeden Fall jedem Unternehmen empfehlen, das sich in der gleichen Situation wie Bytboo befindet.",
    name2: "Herman Treschow,",
    jobPosition2: "CEO Bytboo AB",

    header: "Trusted by many leading brands around the world",
  },
};
const FooterComponent = {
  title: "Lass uns gemeinsam etwas aufbauen!",
  contactUs: "Contact us",
  copyright: "Copyright © Vilje Tech Labs - All Rights Reserved",
  address: "Address: ",
  openInMaps: "Open in maps",
  email: "Email:",
  emailAddress: "bharath@viljetech.com",
  call: "Call:",
  phoneNo: "+46(0)724430170",
};
const AboutPage = {
  topView: {
    we: "Wir",
    design: "Design",
    develop: "Entwickeln",
    deliver: "Liefern",
    one: "/01",
    two: "/02",
    three: "/03",
    desc: "Vilje bedeutet: Wo ein Wille ist, ist auch ein Weg. Dieses ist unseren Motto und  es ermöglicht uns, unseren Kunden den besten Service, häufige, transparente Kommunikation und ein Endprodukt zu bieten, das alle Erwartungen übertrifft.",
    rightDesc1:
      "Wir entwerfen eine Projektstruktur, die einzigartig für Ihre Idee ist. Initiiert durch gründliche Recherchen, um den Markt und die Benutzer zu verstehen, schaffen wir eine stabile und strategisch begründete Basis, um die digitale Lösung für Ihre Bedürfnisse aufzubauen.",
    rightDesc2:
      "Unser Team aus hochqualifizierten Entwicklern kann jede Lösung erstellen, von einfachen Websites bis hin zu komplexen Blockchain-Technologien. Indem wir unserem kundenspezifischen Entwicklungsprozess folgen, können wir ein anpassungsfähiges und fehlerfreies Produkt sicherstellen.",
    rightDesc3:
      "Wir liefern ausgereifte Projekte. Und unsere Dienstleistungen hören hier nicht auf. Wir warten und aktualisieren Ihre Website und Anwendung, damit sie reibungslos funktionieren und den Standards entsprechen.",
  },
  middleView: {
    desc: "Unsere Vision ist es, die Welt zu verändern, indem wir die Ideen von morgen entwickeln. Kreationen, die den Status quo verändern und uns befähigen, für eine bessere Zukunft zu denken und zu handeln.",
    ourWork: "SEHEN SIE, WAS WIR TUN",
  },
  valuesView: {
    valueText: "Ein starkes Team entsteht aus starken Werten.",
    weaim: "Wir sind progressiv",
    weaimDesc: "Befürwortung kontinuierlicher Verbesserung.",
    wesupport: "WWir glauben an Synergie",
    wesupportDesc: "Indem wir als Team zusammenarbeiten, erschaffen wir Magie.",
    wenever: "Wir feiern Vielfalt",
    weneverDesc: "In Ideen, Menschen, Wissen und Kulturen. ",
    weleave: "Wir agieren integer",
    weleaveDesc:
      "Wahrhaftigkeit und Respekt in allem, was wir tun und erschaffen.",
  },
  bottomView: {
    meetOuraTeam: "Treffen unser Team",
    teamDesc:
      "Talent kennt keine Grenzen. Wir reisen lange und weit, um die Besten der Besten zu finden. Aber wir müssen nicht weit gehen, um zu sehen, dass unser Team unseren Kunden hervorragenden Service, Lösungen und Standards bietet.",
    name: "David Olivas",
    jobPosition: "Software developer",
  },
};
const ProjectsPage = {
  one: "/01",
  two: "/02",
  three: "/03",
  four: "/04",
  desc: "Hier ein kleiner Einblick in einige unserer bisherigen Arbeiten.",
  allProjects: "ALLE PROJEKTE",
  appDev: "APP-ENTWICKLUNG",
  webDev: "WEB ENTWICKLUNG",
  uiUx: "UX & UI DESIGN",
  imvi: "imvi",
  Foundi: "Foundi",
  Bytboo: "Bytbo",
  Prizbee: "Prizbe",
};
const ServicesPage = {
  firstView: {
    title: "Dienstleistungen",
    one: "/01",
    two: "/02",
    three: "/03",
    desc: "Wir sind ein Skandinavische Full-Stack-Entwicklungsunternehmen, das Websites und mobile Anwendungen erstellt, die außergewöhnlich aussehen und funktionieren. Mit ganzheitlichen Dienstleistungen in der Entwicklung haben wir Start-ups und etablierte Unternehmen in eine neue Ära der Digitalisierung geführt.",
    appDev: "App-Entwicklung",
    appDevDesc:
      "Wir erstellen und überarbeiten mobile Anwendungen, um Benutzer zu inspirieren, die Anpassung zu erweitern und den Umsatz zu steigern. Vilje Tech arbeitet mit Ihrem Unternehmen zusammen, um benutzerorientierte mobile Apps zu entwerfen, zu entwickeln und bereitzustellen. Durch einen iterativen und gründlichen Ansatz, der Strategie, Forschung, UI/UX-Design sowie Front- und Backend-Entwicklung umfasst, schaffen wir etwas, das die Welt verändern wird.",
    webDev: "Web Entwicklung",
    webDevDesc:
      "Eine gute Website etabliert Ihr Unternehmen, kommuniziert Ihre Markenidentität und baut Kundenbeziehungen auf. Vilje Tech entwickelt großartige Websites, die all das und noch viel mehr bieten. Indem wir Ihre Kunden verstehen, liefern wir Websites, die Ihre Marke stärken, sich auf die Benutzererfahrung konzentrieren und gesetzesfrei funktionieren.",
    uiUx: "UX- & UI-Design",
    uiUxDesc:
      "User Experience (UX) und User Interface (UI) Design sind entscheidende Faktoren für jedes digitale Produkt. Unser Designprozess umfasst das Verständnis der Benutzeranforderungen durch Recherche, Wireframing, High-Fidelity-Prototyping und Iteration basierend auf Benutzer-/Business-Feedback. Wir streben danach, ganzheitliche Erfahrungen zu schaffen, die Geschäftsanforderungen erfüllen und Endbenutzerprobleme lösen.",
  },
  secondView: {
    desc: "Wir entwickeln intelligente digitale Lösungen, die Startups und etablierte Unternehmen in die Lage versetzen, weiterzumachen",
    seeOurWork: "SEHEN SIE, WAS WIR TUN",
  },
  thirdView: {
    whyChoose: "Was spricht für uns -",
    Transparent: "Transparent ",
    TransparentDesc:
      "Während des gesamten Projekts ist unsere Hauptpriorität eine klare und transparente Kommunikation zwischen uns und unseren Kunden.",
    Reliable: "Zuverlässig",
    ReliableDesc:
      "Wir verstehen Ihre Reise und versuchen immer, eine Projektreise zu erstellen, die zu Ihren einzigartigen Produkten passt.",
    Explore: "Erkunden",
    ExploreDesc:
      "Für jedes neue Projekt führen wir Kunden-, Marken- und Produktforschung durch, um unseren Kunden Lösungen zu bieten, die nachweislich an ihren einzigartigen Markt angepasst sind.",
    creative: "Kreativ",
    creativeDesc:
      "Wir sind natürliche Kreative, die unsere Kunden dazu inspirieren wollen, in Design und Innovation über den Status Quo hinauszugehen.",
  },
  fourthView: {
    clientLoveUs: "Kunden empfehlen uns",
    clientDesc:
      "Glauben Sie uns nicht, hören Sie hier mehr von unserem Kunden.",
    cardDesc1:
      "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. ”",
    name: "David Olivas",
    jobPosition: "Software consultant, Company, Sweden",
  },
};
const ContactPage = {
  title: "Contact",
  desc: "Haben Sie ein Projekt im Sinn, bitte kontaktieren Sie uns, um es zu verwirklichen.",
  name: "David Olivas",
  jobPosition: "Managing director, Vilje Tech Labs",
  email: "EMail",
  phone: "Phone",
  dividerText: "or drop us a line or two",
  yourName: "Your name*",
  yourEmail: "Your email address*",
  tellus: "Tell us about your project*",
  sendBtn: "Send message",
};
const imviPage = {
  heroSection: {
    heroHeader: "imvi Labs",
    desc: "Improving visions by using modern techniques",
    client: "Client",
    clientName: "imvi Labs ",
    services: "Services",
    servicesDesc: "UX & UI design, App development, and Web development",
    links: "Links",
    linkText1: "Playstore - ",
    link1: "Link to Android app",
    link2: "Link to IOS app",
    linktext2: "Appstore - ",
    overView: "Overview",
    overViewDesc:
      "MVI approached our team to create a dyslexia treatment solution that could be used by dyslexic individuals of all ages and abilities. The solution was to be built using React Native as the front-end framework and Firebase as the back-end platform.",
  },
  prblmSection: {
    headerText1: "Background",
    headerText2: "Solution",
    headerText3: "What we’ve done",
    headerText1Desc:
      "Unknown to most people, poor eye coordination significantly impacts energy, headaches, concentration, double vision and reading speed. To help improve the vision and reading skills of people, imvi Labs created a patented solution. They partnered up with Vilje Tech to turn theory into reality, an app that is straightforward but has a complex coded core. ",
    headerText2Desc:
      "Imvi Lab's wanted an app that motivated users to continue training. Vilje Tech offers a one-stop solution for developing the front and back end and designing the UX and UI. Therefore, we were a perfect choice.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App development",
      point1: {
        1: "Understanding user needs",
        2: "Wireframes",
        3: "High fidelity & Prototyping",
      },
      point2: {
        1: "Front end ",
        2: "Backend ",
        3: "Testing and Deployment",
        4: "Maintenance ",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    uiDesc:
      "The dyslexia treatment solution was designed with a user-friendly interface that was optimized for dyslexic individuals.",
    one: "01",
    headerText: "Understanding user needs",
    desc1:
      "Before starting the design process, the team conducted extensive research to gain insights into the needs and preferences of dyslexic individuals. They consulted with dyslexia experts and reviewed existing literature to understand the challenges faced by dyslexic individuals when reading and writing.",
    desc2:
      "Based on their research, the team created user personas to represent different types of dyslexic individuals and a user journey map that outlined the different steps that dyslexic individuals may take to learn to read and write effectively. This helped the team gain a deeper understanding of the users' needs and preferences.",
    // desc3: "Entertainment integrations",
    // desc4: "Eye coordination calibrations ",
    // desc5: "Nudging",
    // desc6: "Child and parent connection ",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc1:
      "During the ideation phase of the UX process, the team created wireframes to explore different design concepts for the dyslexia treatment solution. Wireframes are low-fidelity sketches or digital designs that show the basic layout and structure of a user interface without the visual design details.",
    desc2:
      "The wireframes for the dyslexia treatment solution included different design concepts for the main features of the solution, such as the dyslexia assessment tool, reading and writing exercises, and progress tracking. The wireframes allowed the team to quickly iterate and refine the design concepts based on user feedback.",
    desc3:
      "Once the wireframes were finalized, the team created high-fidelity mockups of the user interface, which included the visual design details and branding elements. The high-fidelity mockups were used to create the final design of the solution, which was implemented using React Native as the front-end framework. ",
    desc4:
      "Overall, the wireframing process was a crucial step in the UX design process for the dyslexia treatment solution. It allowed the team to quickly explore different design concepts, iterate based on user feedback, and refine the final design of the solution.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc1:
      "After the wireframes were finalized and tested with dyslexic individuals, the team moved on to creating high-fidelity screens for the solution. High-fidelity screens are detailed digital designs that include the visual design elements such as colors, typography, icons, and images. ",
    desc2:
      "The high-fidelity screens for the dyslexia treatment solution were created using design software such as Sketch or Figma. The screens included the final layout, typography, color scheme, and branding elements for the solution.",
    desc3:
      "The high-fidelity screens were used to create a prototype of the solution that closely resembled the final product. This prototype was tested with dyslexic individuals to ensure that the solution was user-friendly and effective in treating dyslexia.",
    desc4:
      "The high-fidelity screens were also used as a reference for the development team, who used React Native as the front-end framework to create the final product. The screens provided a clear visual representation of the design elements and functionality of the solution, making it easier for the development team to build the solution accurately.",
    desc5:
      "Overall, the high-fidelity screens were an important step in the UX design process for the dyslexia treatment solution. They provided a clear representation of the final product, helped to ensure the solution was user-friendly and effective, and made the development process smoother and more accurate.",
  },
  appDevSection: {
    smallText: "(App development)",
    one: "01",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Front end",
    desc1:
      "The front-end of the dyslexia treatment solution was developed using React Native, a popular cross-platform framework for building mobile applications. The team created custom components and screens that were optimized for dyslexic users, with a focus on usability and accessibility.",
    desc2:
      "The user interface was designed to be simple and intuitive, with clear instructions and feedback provided at each step. The team also implemented responsive design, ensuring that the solution could be used on a variety of devices and screen sizes.",
  },
  appDevSection2: {
    smallText: "(App development)",
    one: "02",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Backend",
    desc1:
      "The back-end of the dyslexia treatment solution was developed using Firebase, a cloud-based platform that provides a variety of services, including authentication, database, and cloud functions. The team used Firebase to store user data and provide real-time updates to the front-end.",
    desc2:
      "The back-end was responsible for implementing security and access control, ensuring that only authorized users could access and modify data. The team also implemented cloud functions, allowing them to run server-side code in response to certain events, such as user sign-ups or data updates.",
    desc3:
      "The database was a crucial component of the dyslexia treatment solution for IMVI, as it stored all user data and provided real-time updates to the front-end. The team used Firebase Realtime Database, a NoSQL database that allows for the synchronization of data between the front-end and back-end in real-time",
  },
  appDevSection3: {
    smallText: "(App development)",
    one: "03",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Testing and Deployment",
    desc1:
      "After the development was complete, the team conducted extensive testing to identify and fix any bugs or issues. They also conducted usability testing with dyslexic individuals to ensure that the solution was easy to use and effective.",
    desc2:
      "Once testing was complete, the solution was deployed to the app stores, where it was made available to dyslexic individuals worldwide. The team continued to monitor the solution and make updates and improvements as needed, based on user feedback.",
  },
  appDevSection4: {
    smallText: "(App development)",
    one: "04",
    appDesc:
      "Using React Native and Firebase, the team developed the front-end and back-end of the solution for IMVI. The front-end included a user-friendly interface that allowed dyslexic individuals to navigate the solution easily, while the back-end included a database that stored user data and provided real-time updates.",
    headerText: "Maintenance",
    desc1:
      "Maintenance was an important aspect of the dyslexia treatment solution developed for IMVI.",
    desc2:
      "The team ensured that the solution was easily maintainable by using modern and standardized technologies, documenting the code, and creating user guides for any future developers or users. They also implemented a system for monitoring and addressing any potential issues or bugs that may arise in the future. Regular maintenance checks and updates were conducted to ensure that the solution remained functional and effective for dyslexic users.",
  },
  technologiesSection: {
    technologiesHeaderText: "Technologies used while developing -",
    technologies1: "React Native",
    technologies2: "Firebase",
    technologies3: " Figma ",
  },
  resultsSection: {
    headerText: "Results",
    desc: "The result was an app that mixes fun and facts, and helps people both see and read better.",
    cardDesc:
      "The partnership between imvi Labs and Vilje Tech has continued as we work on developing the idea further and maintaining a great user experience. ",
    name: "Michael Malmqvist",
    jobposition: "CIO, IMVI Labs, Sweden",
  },
};
const foundiPage = {
  heroSection: {
    heroHeader: "Foundi",
    desc: "Creating the new standard for lost and found solutions.",
    client: "Client",
    clientName: "The team of Foundi",
    services: "Services",
    servicesDesc: "UX & UI design, App development, and Web development",
    links: "Links",
    linkText1: "Web Link - ",
    link1: "website Link",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Overview",
    overViewDesc:
      "Foundi is a QR code-based lost and found solution that prioritizes user privacy while providing a user-friendly experience. The idea is to create a unique QR code that can be attached to valuable items, such as luggage, keys, or electronic devices. If a user loses an item with a Foundi QR code, the finder can scan the code and quickly contact the owner without revealing any personal information. This ensures that the privacy of both the owner and finder is protected while the lost item is returned to its rightful owner. The project involves research, ideation, design, development, and maintenance to create a reliable and effective solution for lost and found items.",
  },
  prblmSection: {
    headerText1: "Problem / Background",
    headerText2: "Solution",
    headerText3: "What we’ve done",
    headerText1Desc:
      "Lost something recently? Unfortunate but common, but we tend to lose our things. Foundi's studies show that 96% of the Swedish population loses at least one valuable item a year. Stressful and annoying, but there is an easy solution. Together we developed a strong brand identity and explored the most unique and effective solution. The result was a QR code bases solution that connects the user to their possessions. ",
    headerText2Desc:
      "Foundi needed a team to help them turn their vision into reality from start to finish. Their idea inspired us at Vilje Tech. The project started with brand identity and creating the app's and website's architecture and wireframes. To maximise traction and optimise the user experience, our copywriters and designers worked together to turn Foundi into a unique experience. Our collaboration is still ongoing, and we will follow Foundi into the launch and look forward to seeing them grow. ",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App development",
      point1: {
        1: "Understanding user needs",
        2: "Wireframes",
        3: "High fidelity & Prototyping",
      },
      point2: {
        1: "Frontend ",
        2: "Backend",
        3: "Development",
        4: "Maintenance",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    uiuxDesc:
      "The design process for Foundi involved understanding user needs, creating low and high-fidelity wireframes, and interactive prototypes to refine the final design. The result is a user-friendly and accessible lost and found solution that prioritizes user privacy.",
    one: "01",
    headerText: "Understanding user needs",
    desc1:
      "The design process for Foundi began with understanding the needs of the target audience. Our team conducted research and analysis to gather insights into how users typically approach lost and found situations and how they would prefer to be contacted if they were to find a lost item. We defined a user persona to represent the typical user of Foundi and identified the features that would be most valuable to them.",
    desc2: "Branding that existes and captures attention from the user ",
    desc3:
      "For a new brand on the market it can be a challange to capture attention and stand out from other brands. With Foundi the client wanted a unique and colourful approach that would be eye catching along with a minimal and user friendly approach. ",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc1:
      "Once we had a solid understanding of user needs, we moved on to creating wireframes. Our team created low-fidelity wireframes that allowed us to quickly iterate and test different design ideas. These wireframes helped us to visualize the user journey and ensure that the solution met the needs of our user persona. We worked closely with our development team to ensure that the design was feasible and that the solution would work seamlessly across different devices.",
    desc2: "High security to keep both users inetegrity intact",
    desc3: "Creating an insentive to give back ",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc1:
      "After we had a solid foundation, we moved on to creating high-fidelity wireframes that added more detail and brought the design to life. We used design tools such as Sketch and Figma to create the wireframes, which allowed us to explore different color schemes, typography, and layout options. During this phase, we worked closely with our development team to ensure that the design was feasible and that the solution would work seamlessly across different devices.",
    desc2:
      "Finally, we created interactive prototypes that allowed us to simulate the user experience and gather feedback from users through user testing. These prototypes helped us to refine the design and ensure that the solution was user-friendly and accessible. ",
    desc3: "Registration process that is easy and smart - just like Foundi  ",
  },
  appDevSection: {
    smallText: "(App development)",
    appDevDesc:
      "Foundi is a lost and found solution that was developed with a focus on user privacy and experience. The app was built with React Native for the frontend and Node.js and MongoDB for the backend. The development process involved creating new features and improving existing ones to ensure that Foundi was a reliable and efficient solution for users. The frontend and backend teams worked closely together to ensure seamless integration, and the testing team made sure that the app met all requirements.",
    one: "01",
    headerText: "Front end ",
    desc: "Foundi's frontend was developed using React Native, a popular framework for creating mobile applications. The frontend development process focused on creating a user-friendly interface that was accessible for all users. The team designed and implemented components such as buttons, forms, and modals to ensure a seamless user experience. They also focused on ensuring that the app was responsive and user-friendly on all devices. The frontend team worked closely with the backend developers to integrate the APIs and ensure that the frontend and backend were working together seamlessly.",
  },
  appDevSection2: {
    smallText: "(App development)",
    appDevDesc: "",
    one: "02",
    headerText: " Backend",
    desc: "Foundi's backend was developed using Node.js and MongoDB. Node.js is a powerful framework for creating server-side applications, and it was used to develop the server-side logic and APIs for Foundi. The team used MongoDB as the database to store user information securely. The backend development process involved creating APIs for registering users, generating QR codes, and storing and retrieving lost item information from the database. The backend team worked closely with the frontend developers to ensure that the APIs were integrated correctly and the app was functioning as intended.",
  },
  appDevSection3: {
    smallText: "(App development)",
    appDevDesc: "",
    one: "03",
    headerText: "Development ",
    desc: "Foundi's development process involved setting up the development environment and project structure, as well as creating new features and improving existing ones. The development team worked on creating new features such as QR code generation, user registration, and lost item reporting. They also focused on improving the existing features to ensure that Foundi was a reliable and efficient solution for users. The development team worked closely with the testing team to ensure that the app met the requirements and was functioning as intended.",
  },
  appDevSection4: {
    smallText: "(App development)",
    appDevDesc: "",
    one: "04",
    headerText: "Maintenance ",
    desc: "Foundi's maintenance process involved ensuring that the app was always up-to-date with the latest security protocols and bug fixes. The maintenance team worked closely with the development team to address any issues that arose and ensure that the app remained secure and efficient. The team also focused on updating the app with new features and improvements to ensure that Foundi continued to meet the needs of users.",
  },
  technologiesSection: {
    technologiesHeaderText: "Technologies used while developing -",
    technologies1: "React native,",
    technologies2: " MongoDB",
    technologies3: "NodeJS",
    technologies4: "AWS ",
  },
  resultsSection: {
    headerText: "Ongoing",
    desc: "Our collaboration with Foundi is still ongoing and we are looking forwad to Foundis launch and will be growing with them along the way.  ",
    cardDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere.”",
    name: "Foundi Team",
    jobposition: "Helsingborg, Sweden",
  },
};
const bytbooPage = {
  heroSection: {
    heroHeader: "Bytboo",
    desc: "Helps you find the right realtor ",
    client: "Client",
    clientName: "Team behind Bytboo",
    services: "Services",
    servicesDesc: "UX & UI design, App development, and Web development",
    links: "Links",
    linkText1: "Web Link - ",
    link1: "website Link",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Overview",
    overViewDesc:
      "Bytboo is a real estate estimation project in Sweden that provides users with an easy and reliable way to estimate the value of their property. The project involved a variety of development processes, including frontend development, architecture, testing, and delivery.",
  },
  prblmSection: {
    headerText1: "Problem / Background",
    headerText2: "Solution",
    headerText3: "What we’ve done",
    headerText1Desc:
      "The idea of Bytboo emerged from the realisation that the real estate industry has nearly no transparency. After extensive research, it was clear that the choice of a realtor affects the price when selling a home. ",
    headerText2Desc:
      "The solution was creating a service where users compare realtors, explore sales statistics and get economic insights within the housing market. Bytboo contacted Vilje Tech to develop their vision. They wanted a design with complex animations with click events, data handling and dynamic images with different colours. Challenges that we solved. The result was a website that allows for a seamless experience for the user while having high standards for function and quality.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App development",
      point1: {
        1: "Understanding user needs",
        2: "Wireframes",
        3: "High fidelity & Prototyping",
      },
      point2: {
        1: "Frontend Development",
        2: "Architecture",
        3: "Testing",
        4: " Delivery",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    one: "01",
    headerText: "Understanding user needs",
    desc: "Understanding user needs",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  appDevSection: {
    smallText: "(App development)",
    appDevDesc:
      "The development process for Bytboo involved modern frontend technologies, microservices architecture, testing, and deployment. The frontend development focused on creating a user-friendly interface using React and TypeScript. The microservices architecture ensured scalability and maintainability of the system. The testing process ensured the security, reliability, and efficiency of the app, while the deployment process ensured the app was available to users. Overall, the development process for Bytboo was comprehensive and ensured a reliable and efficient real estate estimation app for users in Sweden.",
    one: "01",
    headerText: "Frontend Development",
    desc: "The frontend development of Bytboo focused on creating a user-friendly interface that allowed users to easily input their property information and receive an accurate estimate of their property's value. The team used modern frontend technologies such as React and TypeScript to build the app. They created a simple and intuitive design that was responsive and easy to use on both desktop and mobile devices. The team also implemented features such as auto-suggestion, input validation, and real-time updating to improve the user experience.",
  },
  appDevSection2: {
    smallText: "(App development)",
    one: "02",
    headerText: "Architecture",
    desc: "The architecture of Bytboo was designed with scalability and maintainability in mind. The team used microservices architecture to create a modular and scalable system. The system consisted of different microservices such as data storage, calculation engine, and user authentication, all of which were independent and could be scaled independently. The team also implemented a RESTful API architecture that allowed seamless communication between the frontend and backend, ensuring a reliable and efficient system.",
  },
  appDevSection3: {
    smallText: "(App development)",
    one: "03",
    headerText: "Testing",
    desc: "Different methodologies like unit testing, integration testing, end-to-end testing, and security testing were used to ensure the quality of the app.The app was deployed quickly and efficiently using techniques like continuous integration and continuous delivery. A monitoring system allowed for quick issue resolution.",
  },
  appDevSection4: {
    smallText: "(App development)",
    one: "04",
    headerText: "Delivery",
    desc: "The delivery process of Bytboo involved deploying the app to a production environment and ensuring that it was available to users. The team used different deployment techniques such as continuous integration and continuous delivery to ensure that the app was deployed quickly and efficiently. They also implemented a monitoring system that allowed them to monitor the app's performance and quickly resolve any issues that arose. The delivery process was essential as it ensured that the app was available to users and met their needs.In conclusion, the development process for Bytboo involved a variety of processes, including frontend development, architecture, testing, and delivery. The team used modern technologies and methodologies to create a reliable and efficient real estate estimation app that met the needs of users in Sweden. The project was delivered successfully and has become a trusted solution for real estate estimation in the country.",
  },
  technologiesSection: {
    technologiesHeaderText: "Technologies used while developing -",
    technologies1: "React Js",
    technologies2: "Figma",
    technologies3: "Lorem ipsum",
  },
  resultsSection: {
    headerText: "Results",
    desc: "We delivered a clean and engaging end product that packaged the Bytbo team's vision perfectly. We are proud to be apart of a more transparent real estate market.  ",
    cardDesc:
      "Vilja Tech was in charge of building Bytboo's frontend when we rebuild the product from scratch. The end result was spotless. I can definitely recommend Vilja Tech to any company in the same situation as Bytboo.",
    name: "Herman Treschow",
    jobposition: "CEO Bytboo AB",
  },
};
const prizbeePage = {
  heroSection: {
    heroHeader: "Prizbe",
    client: "Client",
    clientName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit - July 2022",
    services: "Services",
    servicesDesc: "UX & UI design, App development, and Web development",
    links: "Links",
    linkText1: "Playstore - ",
    link1: "Lorem ipsum dolor",
    link2: "Lorem ipsum dolor",
    linktext2: "Appstore - ",
    overView: "Overview",
    overViewDesc:
      "Overview - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  prblmSection: {
    headerText1: "Problem / Background",
    headerText2: "Solution",
    headerText3: "What we’ve done",
    headerText1Desc:
      "Motivating children to learn can be challenging, especially in a world of digital distractions. Turn a challenge into an opportunity; that is what Prizbee did. Through their solutions, children get awarded for completing educational challenges with in-game tokens. Vilje Tech developed a playful and user-oriented front end to turn vision into reality",
    headerText2Desc:
      "The Prizbee team needed an agile, adaptive and communication-oriented team to develop their app. The requirement was to make a front end for an explicit MVP while working with a very international team of people from Ukraine and France. Our team provided strategic planning and focused on transparency and communication. Together our international team created an app with both a parent and child version that combined education, playfulness and integrations with well-known games.",
    headerText3Desc: {
      mainText1: "/01 - UX/UI design",
      mainText2: "/02 - App development",
      point1: {
        1: "Strategy and architecture",
        2: "UX & UI",
        3: "Tech Development",
      },
      point2: {
        1: " Maintenance ",
        2: "Back end",
        3: "Adminpanel",
      },
    },
  },
  underStandingSection: {
    smallText: "(UX & UI design)",
    one: "01",
    headerText: "Understanding user needs",
    desc: "Understanding user needs",
  },
  wireFramesSection: {
    smallText: "(UX & UI design)",
    one: "02",
    headerText: "Wireframes",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  protoTypingSection: {
    smallText: "(UX & UI design)",
    one: "03",
    headerText: "High fidelity & Prototyping",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  appDevSection: {
    smallText: "(App development)",
    one: "01",
    headerText: "App development",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae leo quis justo sollicitudin consectetur vel vel dolor. Cras venenatis magna felis, ut lobortis tellus laoreet ac. Duis a metus vel neque convallis tincidunt. Mauris dictum vel ipsum in fermentum. Integer lectus orci, imperdiet id volutpat vel, fermentum vel nunc. Aliquam facilisis varius vestibulum.",
  },
  technologiesSection: {
    technologiesHeaderText: "Technologies used while developing -",
    technologies1: "Lorem ipsum",
    technologies2: "Lorem ipsum",
    technologies3: "Lorem ipsum",
  },
  resultsSection: {
    headerText: "Results",
    desc: "Our collaboration with Prizbe is still ongoing and we are looking forward to be apart their journey and launch.  ",
    cardDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis, lorem ac tempus posuere.”",
    name: "David Olivas",
    jobposition: "Software consultant, Company, Sweden",
  },
};
const privacyPage = {
  headerText: "Privacy Policy",
  headerContent: `We want to provide you with a safe and secure experience when visiting our website. You should never have to worry about your safety or privacy when using Vilje Tech solutions. To learn more about Vilje Tech's privacy policy, please see below.`,
  content1: `Data controller`,
  content2: `Vilje Tech AB (“Vilje Tech”, “we”, “us”, “our”), is a data
  controller of personal data (“Data”). At Vilje Tech, we take your
  privacy very seriously and ensure that all applicable data privacy
  regulations are observed. In this section, you can learn about how
  we process your Data when you use:`,
  content3: `- corresponding products and services offered by Vilje Tech
  (“Service”, “Services”, “Solutions”).`,
  content4: `This Policy does not apply to third-party applications, websites,
  products, services, or platforms that may be accessed through links
  that we provide to you. These third parties are owned and operated
  independently from us and have their own separate privacy and
  data-collection practices. Any personal data that you provide to
  these third parties will be governed by the third party’s own
  privacy policy. We cannot accept liability for the actions or
  policies of these independent third parties, and we are not
  responsible for the content or privacy practices of such sites.`,
  content5: `About Vilje Tech`,
  content6: `Vilje Tech is a development company that specializes in websites and
  applications. As a development company, Vilje Tech is committed to
  providing customized solutions for our clients' digital needs. The
  team of developers has extensive experience in a variety of
  industries, from e-commerce and storage to healthcare and education.
  At Vilje Tech we specialize in UX and UI design when working with
  clients to ensure the quality of user experience and engagement.`,
  content7: `Categories of personal data`,
  content8: ` Depending on your interaction with Vilje Tech, we may process
  different categories of your Data. When using our Site, the
  categories of Data that we may process include:`,
  content9: `- Name`,
  content10: `- Email address`,
  content11: `- Submitted video data`,
  content12: `- Device-specific data (e.g. hardware model, operating system, and
    crash data)`,
  content13: `- Telephone number`,
  content14: `- Feedback, support, and error submissions`,
  content15: `- Cookie and marketing preferences`,
  content16: `Additionally, when submitting feedback, support, or error forms, you
  may be asked to provide your name, contact information, feedback or
  error message, and the identification number of the product in
  question.`,
  content17: `This Data is collected when you register or use our Site, or
  Solutions, opt-in to cookies, opt-in to receiving marketing
  material, submit feedback or booking forms, from public records, and
  through direct correspondence between Vilje Tech and yourself.`,
  content18: `Purpose`,
  content19: `Vilje Tech processes your Data in order to be able to provide to you
  in completion our services in accordance with your wishes, to
  improve and expand our Solutions, for insight and research purposes,
  to communicate with you, to screen for potential risk or fraud, and,
  when in line with the preferences that you have indicated, to
  provide you with advertising or promotional material.`,
  content20: `As we develop insights and research, we ensure that data is
  aggregated in order to prevent any individual from being identified,
  in effect anonymising the data set. Our legal bases for processing
  are:`,
  content21: `- Based on your consent,`,
  content22: `- Necessary for the performance of contract,`,
  content23: `- Due to other legal obligations, or`,
  content24: `- Legitimate interest.`,
  content25: `Data retention`,
  content26: `Unless required by law, Data is retained for up to 24 months after
  your last interaction with Vilje Tech, after which it is erased or
  anonymised. Interaction with Vilje Tech includes, for example, using
  our Site, or Services, or opening marketing communications from us.`,
  content27: `You can at any time opt-out of receiving marketing communications or
  close your account by following on-screen instructions, or by
  contacting us hej@viljetech.com`,
  content28: `Employment applications`,
  content29: `For both published vacancies and open applications, our recruitment
  process may involve the processing of the following categories of
  Data of our applicants: name; postal address; email address;
  telephone number; government-issued identification; references and
  CVs. This Data is retained for up to 5 years after the application
  date, or for as long as we have a legitimate business need for it.
  This Data is collected from public records, third-party
  recommendations and referrals, and through direct correspondence
  between Vilje Tech and yourself.`,
  content30: `Third parties and Countries`,
  content31: `We may share your Data with affiliated companies with which we have
  partnered in order to provide to you a greater set of services, and
  with trusted third-party service providers as necessary for them to
  perform services on our behalf, such as:`,
  content32: `- Cloud data storage`,
  content33: `- Processing payments`,
  content34: `- Product development`,
  content35: `- Application deployment and publication`,
  content36: `- Cybersecurity`,
  content37: `- Market research`,
  content38: `- Serving advertisements and conducting surveys`,
  content39: `- Communicating with you, such as by way of email or survey delivery`,
  content40: `- Legal consultancy`,
  content41: `- Customer relationship management, including social media`,
  content42: `- Support and related services`,
  content43: `As often as is appropriate and practical, third parties are only
  provided with aggregated data (through which individuals cannot be
  identified) and are obligated not to disclose or use the information
  for any purpose other than those listed above.`,
  content44: `Vilje Tech uses servers located within the EU; however, if we
  transfer Data outside of the EU, we check that the third country
  meets the requirements of applicable data privacy legislation and
  make the necessary contractual arrangements to ensure third-party
  compliance. Vilje Tech does not sell your personal data.`,
  content45: `For a full list of our third parties, please send your request to
  hej@viljetech.com`,
  content46: `Cookies`,
  content47: `A cookie is a small text file that the website or application that
  you are visiting requests to save on your device. They are widely
  used in order to make websites and applications work, or work more
  efficiently, as well as to provide information to the owners of the
  site or app.`,
  content48: `Vilje Tech only uses essential cookies, those that allow our Site to
  work – as such, these cannot be disabled.`,
  content49: `Should Vilje Tech ever update its cookie collection for other
  processing purposes, we will first request your consent.`,
  content50: `PTS, the regulatory authority in the field, provides additional
  information about cookies on its website, pts.se`,
  content51: `For information on how to manage your browser’s cookie settings,
  follow these links:`,
  content52: `- Chrome`,
  content53: `- Internet Explorer`,
  content54: `- Firefox`,
  content55: `- Safari`,
  content56: `Cybersecurity`,
  content57: ` Vilje Tech protects your Data using technical and organisational
  security measures. In order to prevent unauthorised access and
  ensure data accuracy, we have implemented strict guidelines for the
  organisation regarding the processing of personal data, including
  the use of two-factor authentication (2FA), SSL encryption, and
  trusted third-party cloud data storage, with servers located within
  the EU.`,
  content58: `Your rights`,
  content59: `Under the EU General Data Protection Regulation (EU GDPR), you have
  the right to transparency in how your Data is processed. This means
  that you can at any time:`,
  content60: `- Request an extract of what Data we process about you,`,
  content61: `- Request that we correct any Data that is incorrect,`,
  content62: `- Request that we erase your Data,`,
  content63: `- Request that we limit how your Data is used,`,
  content64: `- Object to the processing of your Data that we deem legitimate,`,
  content65: `- Request to have your Data transferred from Vilje Tech’s system to
  another body, i.e. data portability, and`,
  content66: `- Revoke previously granted consent to any of our personal data
  processes.`,
  content67: `If you would like to request any of the above, please contact us
  hej@viljetech.com`,
  content68: `You are entitled to contact Sweden’s data protection authority,
  Integritetsskyddsmyndigheten (IMY), to file a complaint if you are
  dissatisfied with how we process your Data.`,
  content69: `More information about the EU GDPR can be found at imy.se
  andec.europa.eu`,
  content70: `Changes to Vilje Tech’s privacy policy`,
  content71: `We may periodically update this Policy in order to accurately
  reflect changes to operations, or for regulatory reasons. When this
  happens, we will inform you by reasonable correspondence - for
  example, by email or on our Site.`,
  content72: `Contact information`,
  content73: `If you have questions about how Vilje Tech processes your personal
  data, or the contents of this Policy, you can at any time
  hej@viljetech.com `,
};
export const GermanStrings = {
  common,
  HomePage,
  FooterComponent,
  AboutPage,
  ProjectsPage,
  ServicesPage,
  ContactPage,
  imviPage,
  foundiPage,
  bytbooPage,
  prizbeePage,
  privacyPage,
};
