import { getBottomNavigationUtilityClass } from "@mui/material";
import React, { useContext, createContext, useEffect, useState } from "react";

const UserDataContext = createContext({
  userData: null,
  setUserData: () => {
    return null;
  },
  removeUserData: () => {
    return null;
  },
});

export const UserDataProvider = (props) => {
  const [userData, setUserData] = useState(getBottomNavigationUtilityClass);

  const initialize = async () => {
    let data = localStorage.getItem("USER_DATA");
    if (data) {
      setUserData(JSON.parse(data));
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        setUserData: (data) => {
          localStorage.setItem("USER_DATA", JSON.stringify(data));
          setUserData(data);
        },
        removeUserData: () => {
          localStorage.removeItem("USER_DATA");
        },
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
