import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Footer, NavBar } from "../../components";
import commonStyles from "../../assets/css/common.module.css";
import styles from "./styles.module.css";
import bytbooImg from "../../assets/images/Bytboo-project.svg";
import foundiImg from "../../assets/images/Foundi-project.svg";
import qritImg from "../../assets/images/qrit.png";
import imviImg from "../../assets/images/Imvi-project-png.png";
import fashionImg from "../../assets/images/fashion.png";
import { useNavigate } from "react-router-dom";
import { useAppData } from "../../providers/AppDataProvider";
import { Helmet } from "react-helmet";
import { useWindowScrollPositions } from "../../hooks/useWindowScrollPositions";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ProjectsPage = () => {
  const { strings } = useAppData();
  const { scrollX, scrollY } = useWindowScrollPositions();
  const { width, height } = useWindowDimensions();
  const [navBarColor, setNavBarColor] = useState(false);
  const [navAboutCol, setNavAboutcol] = useState(true);
  const [navBarDisplay, setNavBarDisplay] = useState(true);
  const navigate = useNavigate();
  const [active, setActive] = useState("all");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  useEffect(() => {
    if (width > 980) {
      scrollDetect();
      setNavBarDisplay(true);
    } else {
      mobileScrollDetect();
    }
  }, [scrollY]);
  function mobileScrollDetect() {
    let ele1 = document.getElementById("projects");
    let previousScrollPosition = window.pageYOffset;
    window.onscroll = function (e) {
      let currentScrollPosition = window.pageYOffset;
      const scrollDifference = currentScrollPosition - previousScrollPosition;
      if (scrollDifference < -1.5) {
        setNavBarDisplay(true);
      } else if (
        scrollDifference > 1.5 &&
        ele1.getBoundingClientRect().top < 0
      ) {
        setNavBarDisplay(false);
      }
      previousScrollPosition = currentScrollPosition;
    };
  }
  function scrollDetect() {
    let ele1 = document.getElementById("projects");
    var oldScrollY = window.scrollY;
    window.onscroll = function (e) {
      if (oldScrollY < window.scrollY && ele1.getBoundingClientRect().top < 0) {
        setNavBarColor(true);
      } else if (window.pageYOffset <= 10) {
        setNavBarColor(false);
      }
      oldScrollY = window.scrollY;
    };
  }
  return (
    <div id="projects" className={styles.containerStyle}>
      <Helmet>
        <meta
          name="description"
          content={strings.ProjectsPage.seo.description}
        />
        <meta name="keywords" content={strings.ProjectsPage.seo.keywords} />
        <link rel="canonical" href="https://viljetech.com/projects" />
        <title>{strings.ProjectsPage.seo.title}</title>
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
      </Helmet>
      <div className={styles.projectsHeaderSectionStyle}>
        <NavBar
          navBarView={navBarDisplay}
          navAbout={navAboutCol}
          navColor={navBarColor}
          color={true}
          backgroundColor={width > 980 ? null : styles.backGroundStyle}
        />
        <div className={styles.projectsSectionStyle}>
          <div className={styles.phsInnerStyle}>
            <p className={styles.phsDescStyle}>{strings.ProjectsPage.desc}</p>
            <div className={styles.phsListStyle}>
              <a
                href="#"
                className={classNames(
                  active === "all"
                    ? styles.phsListItemActiveStyle
                    : commonStyles.anchorStyle,
                  styles.phsListItemStyle
                )}
                onClick={() => setActive("all")}
              >
                {strings.ProjectsPage.allProjects}
              </a>
              <a
                href="#"
                className={classNames(
                  active === "app"
                    ? styles.phsListItemActiveStyle
                    : commonStyles.anchorStyle,
                  styles.phsListItemStyle
                )}
                onClick={() => setActive("app")}
              >
                {strings.ProjectsPage.appDev}
              </a>
              <a
                href="#"
                className={classNames(
                  active === "web"
                    ? styles.phsListItemActiveStyle
                    : commonStyles.anchorStyle,
                  styles.phsListItemStyle
                )}
                onClick={() => setActive("web")}
              >
                {strings.ProjectsPage.webDev}
              </a>
              <a
                href="#"
                className={classNames(
                  active === "ui"
                    ? styles.phsListItemActiveStyle
                    : commonStyles.anchorStyle,
                  styles.phsListItemStyle
                )}
                onClick={() => setActive("ui")}
              >
                {strings.ProjectsPage.uiUx}
              </a>
            </div>
          </div>
          <div className={styles.plsInnerStyle}>
            <div
              className={styles.plsListItemStyle}
              onClick={() => navigate("/projects/imvi")}
            >
              <p className={styles.plsListItemNumberStyle}>
                {strings.ProjectsPage.one}
              </p>
              <h2 className={styles.plsListItemTitleStyle}>
                {strings.ProjectsPage.imvi}
              </h2>
              <img
                src={imviImg}
                className={styles.plsListItemImgStyle}
                alt="Image of Imvi VR glasses"
              />
            </div>
            <div
              className={styles.plsListItemStyle}
              onClick={() => navigate("/projects/foundi")}
            >
              <p className={styles.plsListItemNumberStyle}>
                {strings.ProjectsPage.two}
              </p>
              <h2 className={styles.plsListItemTitleStyle}>
                {strings.ProjectsPage.Foundi}
              </h2>
              <img
                src={foundiImg}
                className={styles.plsListItemImgStyle}
                alt="Phone for Foundi project"
              />
            </div>
            <div
              className={styles.plsListItemStyle}
              onClick={() => navigate("/projects/bytboo")}
            >
              <p className={styles.plsListItemNumberStyle}>
                {strings.ProjectsPage.three}
              </p>
              <h2 className={styles.plsListItemTitleStyle}>
                {strings.ProjectsPage.Bytboo}
              </h2>
              <img
                src={bytbooImg}
                className={styles.plsListItemImgStyle}
                alt="Building representing Bytboo project"
              />
            </div>
            {/* <div
              className={styles.plsListItemStyle}
              onClick={() => navigate("/projects/prizbee")}
            >
              <p className={styles.plsListItemNumberStyle}>
                {strings.ProjectsPage.four}
              </p>
              <h2 className={styles.plsListItemTitleStyle}>
                {strings.ProjectsPage.Prizbee}
              </h2>
              <img
                src={fashionImg}
                className={styles.plsListItemImgStyle}
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectsPage;
