import React from "react";
import { useRoutes } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  ContactPage,
  ProjectsPage,
  ServicesPage,
  CommingSoonPage,
  PrizbeePage,
  BytbooPage,
  FoundiPage,
  ImviPage,
  BlogList,
  BlogPost,
  Login,
  Layout,
  CreateBlog,
  Blog,
  ErrorPage,
  CreateUser,
  UserList,
  UserDetails,
  PrivacyPage,
  NewsPost,
} from "../pages";
import PrivateRoute from "./privateRoutes";

function AppRoutes() {
  return useRoutes(
    [
      {
        path: "*",
        element: <ErrorPage />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/projects",
        element: <ProjectsPage />,
      },
      {
        path: "/services",
        element: <ServicesPage />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPage />,
      },
      {
        path: "/projects/imvi",
        element: <ImviPage />,
      },
      {
        path: "/projects/foundi",
        element: <FoundiPage />,
      },
      {
        path: "/projects/bytboo",
        element: <BytbooPage />,
      },
      {
        path: "/projects/prizbee",
        element: <PrizbeePage />,
      },
      {
        path: "/blogposts",
        element: <BlogList />,
      },
      {
        path: "/blogposts/:title",
        element: <BlogPost />,
      },
      {
        path: "/newsposts/:title",
        element: <NewsPost />,
      },
      // // {
      // //   path: "*",
      // //   element: <CommingSoonPage />,
      // // },
      // {
      //   path: "/login",
      //   element: <Login />,
      // },
      // {
      //   path: "/superadminblog",
      //   element: (
      //     <PrivateRoute>
      //       <Layout />
      //     </PrivateRoute>
      //   ),
      //   children: [
      //     {
      //       path: "createblog",
      //       element: <CreateBlog />,
      //     },
      //     {
      //       path: "createuser",
      //       element: <CreateUser />,
      //     },
      //     {
      //       path: "users",
      //       element: <UserList />,
      //     },
      //     {
      //       path: "users/userdetails",
      //       element: <UserDetails />,
      //     },
      //     {
      //       path: "blogList",
      //       element: <Blog />,
      //     },
      //     {
      //       path: "blogList/editblog",
      //       element: <CreateBlog />,
      //     },
      //   ],
      // },
      // {
      //   path: "/adminblog",
      //   element: (
      //     <PrivateRoute>
      //       <Layout />
      //     </PrivateRoute>
      //   ),
      //   children: [
      //     {
      //       path: "createblog",
      //       element: <CreateBlog />,
      //     },
      //     {
      //       path: "blogList",
      //       element: <Blog />,
      //     },
      //     {
      //       path: "blogList/editblog",
      //       element: <CreateBlog />,
      //     },
      //   ],
      // },
    ]

    // <Routes>
    //   <Route path="/" element={<HomePage />} />
    //   <Route path="/home" element={<HomePage />} />
    //   <Route path="/about" element={<AboutPage />} />
    //   <Route path="/contact" element={<ContactPage />} />
    //   <Route path="/projects" element={<ProjectsPage />} />
    //   <Route path="/services" element={<ServicesPage />} />
    //   <Route path="/projects/imvi" element={<ImviPage />} />
    //   <Route path="/projects/foundi" element={<FoundiPage />} />
    //   <Route path="/projects/bytboo" element={<BytbooPage />} />
    //   <Route path="/projects/prizbee" element={<PrizbeePage />} />
    //   <Route path="/bloglist" element={<BlogList />} />
    //   <Route path="/blogpost" element={<BlogPost />} />
    //   <Route path="/login" element={<BlogLoginPage />} />
    //   {/* <Route path="*" element={<CommingSoonPage />} /> */}
    // </Routes>
  );
}

export default AppRoutes;
